import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner";
import InvestNav from "../../../components/invest/InvestNav";
import StockFormModal from "../../../components/invest/StockFormModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useToast from "../../../hooks/useToast";
import {
  initIsDividend,
  initIsHighEarning,
  initIsSafe,
  initModalShow,
  initStocks,
  resetStock,
} from "../../../store/investSlice";
import { IsValidValue } from "../../../util/common";
import "./invest.css";

const StockViewPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();

  const stocks = useSelector((state) => state.invest?.stocks);
  const countryCodes = useSelector((state) => state.investCode?.countryCodes);
  const investTypeCodes = useSelector(
    (state) => state.investCode?.investTypeCodes
  );
  const stock = useSelector((state) => state.invest?.stock);
  const isDividend = useSelector((state) => state.invest?.isDividend);
  const isSafe = useSelector((state) => state.invest?.isSafe);
  const isHighEarning = useSelector((state) => state.invest?.isHighEarning);
  const isMyInvest = useSelector((state) => state.invest?.isMyInvest);
  const domainNaver = useSelector((state) => state.investCode?.domainNaver);
  const domainTherich = useSelector((state) => state.investCode?.domainTherich);
  const domainNaverMobile = useSelector(
    (state) => state.investCode?.domainNaverMobile
  );
  const domainWallmineCom = useSelector(
    (state) => state.investCode?.domainWallmineCom
  );
  const domainInvestingCom = useSelector(
    (state) => state.investCode?.domainInvestingCom
  );
  const domainSeeking = useSelector((state) => state.investCode?.domainSeeking);
  const domainYahooFinance = useSelector(
    (state) => state.investCode?.domainYahooFinance
  );
  const domainEtfdbCom = useSelector(
    (state) => state.investCode?.domainEtfdbCom
  );
  const domainEtfCom = useSelector((state) => state.investCode?.domainEtfCom);
  const domainMarketWatch = useSelector(
    (state) => state.investCode?.domainMarketWatch
  );
  const domainTradingView = useSelector(
    (state) => state.investCode?.domainTradingView
  );
  const domainDevidend = useSelector(
    (state) => state.investCode?.domainDevidend
  );

  // 현재 페이지가 모바일인지 아닌지 구별하는 변수
  const isMobile =
    window.innerWidth <= 768 ||
    navigator.userAgent.indexOf("iPhone") !== -1 ||
    navigator.userAgent.indexOf("Android") !== -1;

  // 네이버 링크
  // [해외 ETF] https://m.stock.naver.com/worldstock/etf/SOXL.K/total
  // [해외 개별주] https://m.stock.naver.com/worldstock/stock/AAPL.O/total
  let naverUri = "";
  if (stock?.country?.indexOf("KR") !== -1) {
    // 국내주식
    naverUri = isMobile
      ? `${domainNaverMobile}/domestic/stock/${stock?.ticker}/total`
      : `${domainNaver}/item/main.naver?code=${stock?.ticker}`;
  } else {
    if (IsValidValue(stock?.naverMainUri)) {
      if (stock?.investType === "stock-etf")
        naverUri = `${domainNaverMobile}/worldstock/etf/${stock?.naverMainUri}/total`;
      else
        naverUri = `${domainNaverMobile}/worldstock/stock/${stock?.naverMainUri}/total`;
    }
  }

  // 네이버차트 링크
  // [해외 ETF] https://m.stock.naver.com/fchart/foreign/stock/SOXL.K
  // [해외 개별주] https://m.stock.naver.com/worldstock/stock/AAPL.O
  let naverChartUri = "";
  if (stock?.country?.indexOf("KR") !== -1) {
    // 국내주식
    naverChartUri = isMobile
      ? `${domainNaverMobile}/fchart/domestic/stock/${stock?.ticker}`
      : `${domainNaver}/item/fchart.naver?code=${stock?.ticker}`;
  } else {
    // 해외주식
    naverChartUri = `${domainNaverMobile}/fchart/foreign/stock/${stock?.naverMainUri}`;
  }
  const [, setFilteredStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteStockClick = async (e, _id, isViewPage) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/investStock/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res.data.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res.data.message || "stock 삭제 성공",
          });
          /** stocks 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initStocks()),
           * setFilteredStocks() */
          const data = stocks.filter((d) => d._id !== _id);
          dispatch(initStocks(data));
          setFilteredStocks([...data]);
          dispatch(resetStock());
          if (isViewPage) navigate("/manage/invest");
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "stock 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "stock 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="col-12 pb-3">
        {/** 네비게이션 */}
        <InvestNav />
      </div>
      <div className="col-12 pb-3 text-center">
        <div
          className="page-title"
          dangerouslySetInnerHTML={{
            __html: `[<strong>${stock?.ticker}</strong>] ${stock?.stockName}`,
          }}
        />
      </div>
      <div className="col-12 pb-3">
        {/** country */}
        <div>
          <span
            className={`me-1 badge rounded-pill text-bg-success p-2
              ${
                stock?.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : stock?.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : stock?.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
          >
            {
              countryCodes?.filter((d) => d.code === stock?.country)[0]
                ?.codeName
            }
          </span>
          <span
            className={`me-1 badge rounded-pill text-bg-success p-2
              ${
                stock?.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : stock?.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : stock?.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
          >
            {
              investTypeCodes?.filter((d) => d.code === stock?.investType)[0]
                ?.codeName
            }
          </span>
          {/** 기타 조건들 */}
          {/** 보유종목 */}
          <span
            className={`me-1 my-2 badge rounded-pill text-bg-success p-2 ${
              stock?.isMyInvest ? "" : "d-none"
            }`}
            onClick={() => {
              dispatch(initIsHighEarning(!isMyInvest));
            }}
          >
            보유종목
          </span>
          {/** 고성장 */}
          <span
            className={`me-1 my-2 badge rounded-pill text-bg-success p-2 ${
              stock?.isHighEarning ? "" : "d-none"
            }`}
            onClick={() => {
              dispatch(initIsHighEarning(!isHighEarning));
            }}
          >
            고성장
          </span>
          {/** 고배당 */}
          <span
            className={`me-1 my-2 badge rounded-pill text-bg-success p-2 ${
              stock?.isDividend ? "" : "d-none"
            }`}
            onClick={() => {
              dispatch(initIsDividend(!isDividend));
            }}
          >
            고배당
          </span>
          {/** 안전자산 */}
          <span
            className={`me-1 my-2 badge rounded-pill text-bg-success p-2 ${
              stock?.isSafe ? "" : "d-none"
            }`}
            onClick={() => {
              dispatch(initIsSafe(!isSafe));
            }}
          >
            안전자산
          </span>
        </div>
      </div>
      {/** 종목 리스트 */}
      <div className="mb-3">
        <div className="links">
          <div className="linkTitle">기본 링크</div>
          {/** 네이버(국내 자동, 해외 수동, 해외는 모바일버전만 가능) */}
          <Link
            className={`me-3 ${IsValidValue(naverUri) ? "" : "d-none"}`}
            to={naverUri}
            target="_blank"
          >
            <button className="btn">네이버</button>
          </Link>
          {/** 씨킹알파(해외주식 자동) */}
          <Link
            to={`${domainSeeking}/symbol/${stock?.ticker}`}
            target="_blank"
            className={`me-3 ${
              stock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
            }`}
          >
            <button className="btn">씨킹알파</button>
          </Link>
          {/** 더리치(자동) */}
          <Link
            className={`me-3`}
            to={`${domainTherich}/stock?ticker=${stock?.ticker}`}
            target="_blank"
          >
            <button className="btn">더리치</button>
          </Link>
          {/** 마켓와치: 국내/외 자동 */}
          <Link
            to={
              stock?.investType === "stock-etf"
                ? `${domainMarketWatch}/investing/fund/${stock?.ticker}`
                : `${domainMarketWatch}/investing/stock/${stock?.ticker}`
            }
            target="_blank"
            className={`me-3`}
          >
            <button className="btn">마켓와치</button>
          </Link>
          {/** 야후 파이넌스(해외주식 자동) */}
          <Link
            to={`${domainYahooFinance}/quote/${stock?.ticker}`}
            target="_blank"
            className={`me-3 ${
              stock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
            }`}
          >
            <button className="btn">야후파이넌스</button>
          </Link>
          {/** ETF DB(해외 ETF 자동) */}
          <Link
            to={`${domainEtfdbCom}/etf/${stock?.ticker}`}
            target="_blank"
            className={`me-3 ${
              stock?.country?.indexOf("KR") !== -1 ||
              stock?.investType !== "stock-etf"
                ? "d-none"
                : ""
            }`}
          >
            <button className="btn">ETFDB</button>
          </Link>
          {/** ETF COM(해외 ETF 자동) */}
          <Link
            to={`${domainEtfCom}/${stock?.ticker}`}
            target="_blank"
            className={`me-3 ${
              stock?.country?.indexOf("KR") !== -1 ||
              stock?.investType !== "stock-etf"
                ? "d-none"
                : ""
            }`}
          >
            <button className="btn">ETFCOM</button>
          </Link>
          {/** 월마인 */}
          <Link
            to={`${domainWallmineCom}${stock?.wallmineMainUri}`}
            target="_blank"
            className={`me-3 ${stock?.wallmineMainUri ? "" : "d-none"}`}
          >
            <button className="btn">월마인</button>
          </Link>
          {/** 인베스팅 */}
          <Link
            to={`${domainInvestingCom}${stock?.investingMainUri}`}
            target="_blank"
            className={`me-3 ${stock?.investingMainUri ? "" : "d-none"}`}
          >
            <button className="btn">인베스팅</button>
          </Link>
          {/** 트레이딩뷰 */}
          <Link
            to={`${domainTradingView}${stock?.tradingViewMainUri}`}
            target="_blank"
            className={`me-3 ${stock?.tradingViewMainUri ? "" : "d-none"}`}
          >
            <button className="btn">트레이딩뷰</button>
          </Link>
          {/** 디비덴드 */}
          <Link
            to={`${domainDevidend}${stock?.dividendMainUri}`}
            target="_blank"
            className={`me-3 ${stock?.dividendMainUri ? "" : "d-none"}`}
          >
            <button className="btn">디비덴드</button>
          </Link>
          {/** 구글 검색 */}
          <Link
            to={`https://www.google.com/search?q=${stock?.ticker}`}
            target="_blank"
            className={`me-2`}
          >
            <button className="btn btn-google-search">구글검색</button>
          </Link>
        </div>
        {/** 차트 링크 */}
        <div className="links">
          <div className="linkTitle">차트 링크</div>
          <div className="text-start">
            {/** 네이버 차트 */}
            <Link
              to={`${naverChartUri}`}
              target="_blank"
              className={`me-3 ${
                stock?.country.indexOf("KR") !== -1 || stock?.naverMainUri
                  ? ""
                  : "d-none"
              }`}
            >
              <button className="btn">네이버-차트</button>
            </Link>
            {/** 씨킹알파(해외주식 자동) */}
            <Link
              to={`${domainSeeking}/symbol/${stock?.ticker}/charting?compare=${stock?.ticker},IVV&interval=5Y&metric=totalReturn`}
              target="_blank"
              className={`me-3 ${
                stock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
              }`}
            >
              <button className="btn">씨킹알파-5년차트</button>
            </Link>
            {/** 월마인(해외주식 수동) */}
            <Link
              to={`https://wallmine.com${stock?.wallmineMainUri}/charts`}
              target="_blank"
              className={`me-3 ${
                stock?.country?.indexOf("KR") !== -1 ||
                !IsValidValue(stock?.wallmineMainUri)
                  ? "d-none"
                  : ""
              }`}
            >
              <button className="btn">월마인 차트</button>
            </Link>
            {/** 마켓와치: 국내/외 자동 */}
            <Link
              to={
                stock?.investType === "stock-etf"
                  ? `${domainMarketWatch}/investing/fund/${stock?.ticker}/charts`
                  : `${domainMarketWatch}/investing/stock/${stock?.ticker}/charts`
              }
              target="_blank"
              className={`me-3`}
            >
              <button className="btn">마켓와치-차트</button>
            </Link>
            {/** 구글 검색 */}
            <Link
              to={`https://www.google.com/search?q=${stock?.ticker} 차트`}
              target="_blank"
              className={`me-2`}
            >
              <button className="btn btn-google-search">구글검색(차트)</button>
            </Link>
          </div>
        </div>
        {/** 배당 링크 */}
        <div className="links">
          <div className="linkTitle">배당 링크</div>
          <div className="text-start">
            {/** 씨킹알파 배당링크(해외주식 자동) */}
            <Link
              to={`${domainSeeking}/symbol/${stock?.ticker}/dividends/scorecard`}
              target="_blank"
              className={`me-3 ${
                stock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
              }`}
            >
              <button className="btn">씨킹알파-배당정보</button>
            </Link>
            {/** 씨킹알파 배당기록 차트(해외주식 자동) */}
            <Link
              to={`${domainSeeking}/symbol/${stock?.ticker}/dividends/history`}
              target="_blank"
              className={`me-3 ${
                stock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
              }`}
            >
              <button className="btn">씨킹알파-배당기록차트</button>
            </Link>
            {/** 더리치(자동) */}
            <Link
              className={`me-3`}
              to={`${domainTherich}/stock?ticker=${stock?.ticker}`}
              target="_blank"
            >
              <button className="btn">더리치</button>
            </Link>
            {/** 구글 검색 */}
            <Link
              to={`https://www.google.com/search?q=${stock?.ticker} 배당`}
              target="_blank"
              className={`me-2`}
            >
              <button className="btn btn-google-search">구글검색(배당)</button>
            </Link>
          </div>
        </div>
        {/** 메모 */}
        <div className="links">
          <div className="linkTitle">메모</div>
          {/** 메모 */}
          <div
            className={`${stock?.isImportant ? "fw-bold" : ""} ${
              stock?.isLineThrough ? "text-decoration-line-through" : ""
            }`}
            style={{
              textAlign: "left",
            }}
            title={stock?.memo}
            dangerouslySetInnerHTML={{
              __html: stock?.memo?.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>
      <div className="col-12 pb-3">
        {/** 종목 수정 버튼 */}
        <button
          className="btn btn-outline-primary w-100"
          onClick={() => {
            dispatch(initModalShow(true));
          }}
        >
          종목 수정
        </button>
        {/** 종목 수정 버튼 */}
        <StockFormModal
          handleDeleteStockClick={handleDeleteStockClick}
          isViewPage={true}
        />
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StockViewPage;

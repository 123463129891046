import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { PassDateEasyText } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const StoryGroupModal = ({
  groupModalShow,
  setGroupModalShow,
  selGroupInfo,
  setSelGroupInfo,
  setSelGroup,
  groups,
  setGroups,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [show, setShow] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isGroupNameError, setIsGroupNameError] = useState(false);
  const [groupNameErrMsg, setGroupNameErrMsg] = useState("");
  const [memo, setMemo] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [isPublicErrMsg, setIsPublicErrMsg] = useState("");
  const [isPublicError, setIsPublicError] = useState(false);
  const { addToast } = useToast();
  const groupNameRef = useRef();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    setGroupName(selGroupInfo?.groupName);
    setMemo(selGroupInfo?.memo);
    setIsPublic(selGroupInfo?.isPublic);
  }, [selGroupInfo]);

  const resetStates = () => {
    // setSelGroup("");
    // setSelGroupInfo({});
    setShow(false);
    setGroupModalShow(false);
    setGroupName("");
    setMemo("");
    setIsPublic(true);
  };

  useEffect(() => {
    setShow(groupModalShow);
    setTimeout(() => {
      if (groupModalShow) groupNameRef?.current?.focus();
    }, 500);
  }, [groupModalShow]);

  const validate = () => {
    let isValid = true;
    setIsGroupNameError(false);
    setIsPublicError(false);
    setGroupNameErrMsg("");
    setIsPublicErrMsg("");
    if (!groupName) {
      setIsGroupNameError(true);
      setGroupNameErrMsg("그룹명을 입력하세요.");
      isValid = false;
    }
    if (isPublic === null) {
      setIsPublicError(true);
      setIsPublicErrMsg("공개여부를 선택하세요.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    const data = {
      _id: selGroupInfo?._id,
      groupName,
      memo,
      isPublic,
    };
    await setIsLoading(true);
    if (data._id) {
      // 기존 그룹 수정
      await axiosHook
        .put(`${process.env.REACT_APP_API_DOAMIN}/storyGroupEdit`, data)
        .then((res) => {
          if (res.data.ok) {
            addToast({
              type: "success",
              text: res.data.message || "그룹 수정에 성공하였습니다.",
            });
            resetStates();
            // groups 에서 기존의 selGroup 삭제하고 새로운 selGroup 추가
            const newGroups = groups.filter((group) => group._id !== data._id);
            newGroups.push(res.data?.result);
            setGroups(newGroups);
          } else {
            addToast({
              type: "danger",
              text: res.data.message || "그룹 생성에 실패하였습니다.",
            });
          }
        })
        .catch((err) => {
          console.error(e);
          addToast({
            type: "danger",
            text: err.message || "그룹 생성에 실패하였습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // 새 그룹 생성
      await axiosHook
        .post(`${process.env.REACT_APP_API_DOAMIN}/storyGroupAdd`, data)
        .then((res) => {
          if (res.data.ok) {
            addToast({
              type: "success",
              text: res.data.message || "그룹 생성에 성공하였습니다.",
            });
            resetStates();
            setSelGroup(res.data?.result?._id);
            setGroups([...groups, res.data?.result]);
          } else {
            addToast({
              type: "danger",
              text: res.data.message || "그룹 생성에 실패하였습니다.",
            });
          }
        })
        .catch((err) => {
          console.error(e);
          addToast({
            type: "danger",
            text: err.message || "그룹 생성에 실패하였습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteStoryGroup = async (e) => {
    e.preventDefault();
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosHook
      .delete(`${process.env.REACT_APP_API_DOAMIN}/storyGroupDelete`, {
        data: { _id: selGroupInfo?._id },
      })
      .then((res) => {
        if (res.data.ok) {
          addToast({
            type: "success",
            text: res.data.message || "그룹 삭제에 성공하였습니다.",
          });
          resetStates();
          // groups 에서 기존의 selGroup 삭제
          const newGroups = groups.filter(
            (group) => group._id !== selGroupInfo?._id
          );
          setGroups(newGroups);
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "그룹 삭제에 실패하였습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(e);
        addToast({
          type: "danger",
          text: err.message || "그룹 삭제에 실패하였습니다.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <Modal centered show={show} animation={false} onHide={resetStates}>
      <Modal.Header closeButton>
        <Modal.Title>그룹 정보</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td className="text-end">그룹명</td>
                  <td className="text-start">
                    <input
                      type="text"
                      ref={groupNameRef}
                      className={`form-control ${
                        isGroupNameError ? "border-danger" : ""
                      }`}
                      style={{ width: "100%" }}
                      placeholder="그룹명을 입력하세요.(필수)"
                      value={groupName || ""}
                      onChange={(e) => {
                        setGroupName(e.target.value);
                      }}
                    />
                    <div
                      className={`text-danger ${
                        isGroupNameError ? "" : "d-none"
                      }`}
                    >
                      {groupNameErrMsg}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-end">메모</td>
                  <td className="text-start">
                    <textarea
                      rows={1}
                      className="form-control"
                      style={{ width: "100%" }}
                      placeholder="메모를 입력하세요.(옵션)"
                      value={memo || ""}
                      onChange={(e) => {
                        setMemo(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-end">공개여부</td>
                  <td>
                    <div className={`form-check form-check-inline`}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        checked={isPublic === true}
                        onChange={() => {
                          setIsPublic(true);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        공개
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        checked={isPublic === false}
                        onChange={() => {
                          setIsPublic(false);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        비공개
                      </label>
                    </div>
                    <div
                      className={`text-danger ${isPublicError ? "" : "d-none"}`}
                    >
                      {isPublicErrMsg}
                    </div>
                  </td>
                </tr>
                <tr className={selGroupInfo?.createdAt ? "" : "d-none"}>
                  <td className="text-end">생성일</td>
                  <td className="text-start">
                    {selGroupInfo?.createdAt
                      ? new Date(selGroupInfo?.createdAt).toLocaleDateString() +
                        " (" +
                        PassDateEasyText(new Date(selGroupInfo?.createdAt)) +
                        ")"
                      : ""}
                  </td>
                </tr>
                <tr className={selGroupInfo?.updatedAt ? "" : "d-none"}>
                  <td className="text-end">수정일</td>
                  <td className="text-start">
                    {selGroupInfo?.updatedAt
                      ? new Date(selGroupInfo?.updatedAt).toLocaleDateString() +
                        " (" +
                        PassDateEasyText(new Date(selGroupInfo?.updatedAt)) +
                        ")"
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success w-100" onClick={handleSubmit}>
            저장
          </button>
          <button
            className={`btn btn-danger w-100 ${
              selGroupInfo?.createdAt ? "" : "d-none"
            }`}
            onClick={handleDeleteStoryGroup}
          >
            삭제
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default StoryGroupModal;

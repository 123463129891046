import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const ProjectListTable = () => {
  const axiosHook = useAxiosPrivate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  let addCount = 0;
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    // 관리자 여부도 조건에 넣기
    if (isLoggedIn) {
      setIsLoading(true);
      axiosHook
        .get(`${apiServer}/projectFull`)
        .then((res) => {
          setNumberOfProjects(res.data.length);
          setProjects(res.data);
        })
        .catch((e) => {
          console.error(e);
          setError("Something went wrong in database");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setNumberOfProjects(0);
      setProjects([]);
      setIsLoading(false);
      setError("Need to login");
    }
  }, [isLoggedIn, apiServer, axiosHook]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div style={{ lineHeight: "150%", fontSize: "100%" }}>
      <h2 className="h2">
        수행 프로젝트(문서형, <Link to="/manage/project/table">테이블형</Link>)
      </h2>
      {projects?.map((project) => {
        const idx = numberOfProjects - addCount;
        addCount++;
        return (
          <div key={project._id} className="mb-1">
            {/* [순번] 제목 */}
            <div>
              <div style={{ width: "80%", overflow: "hidden" }}>
                ======================================
              </div>
              <span className="pe-1">[{idx}]</span>
              <span>
                {project.work_company} - {project.name}
              </span>
              {/* 기간 */}
              <div>
                {`참여기간: ${new Date(
                  project.start_date
                ).toLocaleDateString()} ~ ${new Date(
                  project.end_date
                ).toLocaleDateString()}`}
              </div>
              <div style={{ width: "80%", overflow: "hidden" }}>
                ======================================
              </div>
            </div>
            {/* 웹사이트 */}
            {project.website && (
              <div>
                웹사이트:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={project.website}
                >
                  {project.website}
                </a>
              </div>
            )}
            {/* 설명 */}
            {project.desc && <div>{`설명: ${project.desc}`}</div>}
            {/* 소속사 / 고객사 */}
            <div>{`소속사 / 고객사: ${project.my_company} / ${project.work_company}`}</div>
            {/* 프로젝트 종류 */}
            <div>{`프로젝트 타입: ${project.project_type}`}</div>
            {/* OS / DB */}
            <div>{`OS / DB: ${project.os} / ${project.db}`}</div>
            {/* 개발언어 / 프레임워크 */}
            <div>{`개발언어: ${project.language}`}</div>
            {/* 사용기술 */}
            <div>{`사용기술: ${project.used_tech}`}</div>
            {/* 사용도구 */}
            <div>{`사용도구: ${project.used_tools}`}</div>
            {/* 참여인원 */}
            <div>{`참여인원: ${project.members}`}</div>
            {/* 작업파트 */}
            <div>{`작업파트: ${project.my_part}`}</div>
            {/* 직접 구현한 기술 상세 */}
            {project.my_tech && (
              <div
                dangerouslySetInnerHTML={{
                  __html: `직접 구현한 기술 상세: ` + project.my_tech,
                }}
              />
            )}
          </div>
        );
      })}
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

ProjectListTable.propTypes = {
  isManagePage: propTypes.bool,
};

ProjectListTable.defaultProps = {
  isManagePage: false,
};

export default ProjectListTable;

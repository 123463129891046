import { faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner";
import InvestNav from "../../../components/invest/InvestNav";
import StockFormModal from "../../../components/invest/StockFormModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useToast from "../../../hooks/useToast";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  initCountryCode,
  initDomainCodes,
  initDomainDevidend,
  initDomainEtfCheck,
  initDomainEtfCom,
  initDomainEtfdbCom,
  initDomainInvestingCom,
  initDomainKospdcom,
  initDomainMarketwatch,
  initDomainNaver,
  initDomainNaverMobile,
  initDomainSeeking,
  initDomainTherich,
  initDomainTradingView,
  initDomainWallmine,
  initDomainWallmineCom,
  initDomainYahooFinance,
  initInvestTypeCode,
  resetCountryCode,
  resetInvestTypeCode,
} from "../../../store/investCodeSlice";
import {
  initIsDividend,
  initIsHighEarning,
  initIsMyInvest,
  initIsSafe,
  initModalShow,
  initStock,
  initStocks,
  resetStock,
} from "../../../store/investSlice";
import { IsValidValue } from "../../../util/common";
import "./invest.css";

const InvestHomePage = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();

  const stocks = useSelector((state) => state.invest?.stocks);
  const countryCodes = useSelector((state) => state.investCode?.countryCodes);
  const investTypeCodes = useSelector(
    (state) => state.investCode?.investTypeCodes
  );
  const stock = useSelector((state) => state.invest?.stock);
  const countryCode = useSelector((state) => state.investCode?.countryCode);
  const isDividend = useSelector((state) => state.invest?.isDividend);
  const isSafe = useSelector((state) => state.invest?.isSafe);
  const isHighEarning = useSelector((state) => state.invest?.isHighEarning);
  const isMyInvest = useSelector((state) => state.invest?.isMyInvest);
  const investTypeCode = useSelector(
    (state) => state.investCode?.investTypeCode
  );
  const domainNaver = useSelector((state) => state.investCode?.domainNaver);
  const domainTherich = useSelector((state) => state.investCode?.domainTherich);
  const domainNaverMobile = useSelector(
    (state) => state.investCode?.domainNaverMobile
  );
  // const domainKospdcom = useSelector((state) => state.investCode?.domainKospdcom);
  const domainWallmineCom = useSelector(
    (state) => state.investCode?.domainWallmineCom
  );
  const domainInvestingCom = useSelector(
    (state) => state.investCode?.domainInvestingCom
  );
  const domainSeeking = useSelector((state) => state.investCode?.domainSeeking);
  const domainYahooFinance = useSelector(
    (state) => state.investCode?.domainYahooFinance
  );
  const domainEtfdbCom = useSelector(
    (state) => state.investCode?.domainEtfdbCom
  );
  const domainEtfCom = useSelector((state) => state.investCode?.domainEtfCom);
  const domainMarketWatch = useSelector(
    (state) => state.investCode?.domainMarketWatch
  );
  const domainTradingView = useSelector(
    (state) => state.investCode?.domainTradingView
  );
  const domainDevidend = useSelector(
    (state) => state.investCode?.domainDevidend
  );

  // 현재 페이지가 모바일인지 아닌지 구별하는 변수
  const isMobile =
    window.innerWidth <= 768 ||
    navigator.userAgent.indexOf("iPhone") !== -1 ||
    navigator.userAgent.indexOf("Android") !== -1;

  const [filteredStocks, setFilteredStocks] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // 주소창 타이틀 투자 | 두니넷
    document.title = `투자 | 두니넷`;
  }, []);

  /** 모든 투자리스트 가져오기 */
  useEffect(() => {
    const fetchStocks = async () => {
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/1000/investStocks`)
        .then((res) => {
          const data = res?.data;
          dispatch(initStocks(data));
          let filteredData = [...data];
          setFilteredStocks(filteredData);
        })
        .catch((err) => {
          console.error(err);
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchStocks();
  }, [axiosToken, apiServer, dispatch]);

  /** 종목 검색
   * 종목, 지역, 상세지역이 변경되면 회사 리스트를 필터해서 가져온다.
   * searchText가 변경될 때마다 종목 리스트를 검색해서 filteredStocks에 담는다.
   */
  useEffect(() => {
    const searchStocks = async () => {
      let filteredData = await [...stocks];
      // 타입
      if (IsValidValue(investTypeCode))
        filteredData = filteredData?.filter(
          (d) => d.investType === investTypeCode
        );
      // 국가
      if (IsValidValue(countryCode))
        filteredData = filteredData?.filter((d) => d.country === countryCode);
      // 보유종목
      if (isMyInvest)
        filteredData = filteredData?.filter((d) => d.isMyInvest === isMyInvest);
      // 고성장
      if (isHighEarning)
        filteredData = filteredData?.filter(
          (d) => d.isHighEarning === isHighEarning
        );
      // 고배당
      if (isDividend)
        filteredData = filteredData?.filter((d) => d.isDividend === isDividend);
      // 안전자산
      if (isSafe)
        filteredData = filteredData?.filter((d) => d.isSafe === isSafe);

      if (IsValidValue(searchText)) {
        filteredData = filteredData?.filter(
          (d) =>
            // 대소문자 구분 안함.
            // stockName, ticker 중 하나만 일치해도 반환
            d.stockName.toLowerCase().includes(searchText.toLowerCase()) ||
            d.ticker.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      setFilteredStocks(filteredData);
    };
    searchStocks();
  }, [
    stocks,
    investTypeCode,
    countryCode,
    isMyInvest,
    isHighEarning,
    isDividend,
    isSafe,
    searchText,
  ]);

  /** 웹사이트 코드 가져오기 */
  useEffect(() => {
    const fetchCodes = async () => {
      // 가져올 자식 코드의 부모 코드들을 배열 형식으로 전달
      setIsLoading(true);
      await axiosToken
        .get(`${apiServer}/manage/iv-website/childCodes`)
        .then((res) => {
          const data = res?.data || [];
          dispatch(initDomainCodes(data));
          if (data?.length === 0) return;
          // 도메인 할당
          dispatch(
            initDomainDevidend(
              data?.filter((d) => d.code === "dividend")[0]?.memo
            )
          );
          dispatch(
            initDomainWallmineCom(
              data?.filter((d) => d.code === "wallmine")[0]?.memo
            )
          );
          dispatch(
            initDomainInvestingCom(
              data?.filter((d) => d.code === "investing-com")[0]?.memo
            )
          );
          dispatch(
            initDomainSeeking(
              data?.filter((d) => d.code === "seekingalpha")[0]?.memo
            )
          );
          dispatch(
            initDomainWallmine(
              data?.filter((d) => d.code === "wallmine")[0]?.memo
            )
          );
          dispatch(
            initDomainTradingView(
              data?.filter((d) => d.code === "tradingview")[0]?.memo
            )
          );
          dispatch(
            initDomainNaver(
              data?.filter((d) => d.code === "naver-finance")[0]?.memo
            )
          );
          dispatch(
            initDomainTherich(
              data?.filter((d) => d.code === "therich")[0]?.memo
            )
          );
          dispatch(
            initDomainNaverMobile(
              data?.filter((d) => d.code === "naver-mobile")[0]?.memo
            )
          );
          dispatch(
            initDomainKospdcom(
              data?.filter((d) => d.code === "kospdcom")[0]?.memo
            )
          );
          dispatch(
            initDomainMarketwatch(
              data?.filter((d) => d.code === "marketwatch")[0]?.memo
            )
          );
          dispatch(
            initDomainYahooFinance(
              data?.filter((d) => d.code === "yahoo-finance")[0]?.memo
            )
          );
          dispatch(
            initDomainEtfCheck(
              data?.filter((d) => d.code === "etfcheck")[0]?.memo
            )
          );
          dispatch(
            initDomainEtfCom(data?.filter((d) => d.code === "etf-com")[0]?.memo)
          );
          dispatch(
            initDomainEtfdbCom(
              data?.filter((d) => d.code === "etfdb-com")[0]?.memo
            )
          );
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteStockClick = async (e, _id) => {
    e.preventDefault();
    if (!IsValidValue(_id)) {
      addToast({
        type: "danger",
        text: "선택된 객체가 올바르지 않습니다.",
      });
      return;
    }
    if (!window.confirm("삭제하시겠습니까?")) return;
    setIsLoading(true);
    await axiosToken
      .delete(`${apiServer}/investStock/delete`, {
        data: { _id },
      })
      .then((res) => {
        if (res?.data?.ok) {
          dispatch(initModalShow(false));
          addToast({
            type: "success",
            text: res?.data?.message || "stock 삭제 성공",
          });
          /** stocks 에서 삭제된 아이템 없이 재할당 하기
           * dispatch(initStocks()),
           * setFilteredStocks() */
          const data = stocks?.filter((d) => d._id !== _id);
          dispatch(initStocks(data));
          setFilteredStocks([...data]);
          dispatch(resetStock());
        } else {
          addToast({
            type: "danger",
            text: res?.data?.message || "stock 삭제 실패",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: e.message || "stock 삭제 실패",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (error) return <div>{error}</div>;

  return (
    <>
      <div className="col-12 pb-3">
        {/** 네비게이션 */}
        <InvestNav />
      </div>
      <div className="col-12 pb-3">
        {/** 전체 조건 초기화 */}
        <div className="border-bottom pb-2">
          <button
            className={`me-2 my-2 btn btn-outline-secondary w-100`}
            onClick={() => {
              dispatch(resetCountryCode());
              dispatch(resetInvestTypeCode());
              dispatch(initIsHighEarning(false));
              dispatch(initIsDividend(false));
              dispatch(initIsSafe(false));
              dispatch(initIsMyInvest(false));
              setSearchText("");
            }}
          >
            전체 조건 초기화
          </button>
        </div>
        {/** 검색 폼 */}
        <div className="border-bottom col-12 mt-3 pb-3">
          <form>
            <input
              className="form-control form-control-lg text-uppercase"
              type="search"
              placeholder="티커, 코드넘버, 종목명 검색"
              aria-label="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </form>
        </div>
        {/** countries */}
        <div className="border-bottom py-2 select-btn">
          <button
            className={`me-2 my-2 btn btn-outline-danger`}
            onClick={() => {
              dispatch(resetCountryCode());
            }}
          >
            전체 국가
          </button>
          {countryCodes?.map((item, index) => {
            return (
              <button
                className={`me-2 my-2 btn ${
                  countryCode === item.code
                    ? "btn-success"
                    : "btn-outline-success"
                }
              ${
                item.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : item.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : item.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
                key={index}
                onClick={() => {
                  dispatch(initCountryCode(item.code));
                }}
              >
                {item.codeName}
              </button>
            );
          })}
        </div>
        {/** invest types */}
        <div className="border-bottom py-2 select-btn">
          <button
            className={`me-2 my-2 btn btn-outline-danger`}
            onClick={() => {
              dispatch(resetInvestTypeCode());
            }}
          >
            전체 타입
          </button>
          {investTypeCodes?.map((item, index) => {
            return (
              <button
                className={`me-2 my-2 btn ${
                  investTypeCode === item.code
                    ? "btn-success"
                    : "btn-outline-success"
                }
              ${
                item.useStatus === "USN"
                  ? "text-decoration-line-through"
                  : item.useStatus === "USD"
                  ? "text-decoration-line-through fst-italic text-muted"
                  : item.useStatus === "USP"
                  ? "text-success"
                  : ""
              }`}
                key={index}
                onClick={() => {
                  dispatch(initInvestTypeCode(item.code));
                }}
              >
                {item.codeName}
              </button>
            );
          })}
        </div>
        {/** 기타 조건들 */}
        <div className="border-bottom py-2 select-btn">
          {/** 보유종목 */}
          <button
            className={`me-2 my-2 btn ${
              isMyInvest === true ? "btn-success" : "btn-outline-success"
            }`}
            onClick={() => {
              dispatch(initIsMyInvest(!isMyInvest));
            }}
          >
            보유종목
          </button>
          {/** 고성장 */}
          <button
            className={`me-2 my-2 btn ${
              isHighEarning === true ? "btn-success" : "btn-outline-success"
            }`}
            onClick={() => {
              dispatch(initIsHighEarning(!isHighEarning));
            }}
          >
            고성장
          </button>
          {/** 고배당 */}
          <button
            className={`me-2 my-2 btn ${
              isDividend === true ? "btn-success" : "btn-outline-success"
            }`}
            onClick={() => {
              dispatch(initIsDividend(!isDividend));
            }}
          >
            고배당
          </button>
          {/** 안전자산 */}
          <button
            className={`me-2 my-2 btn ${
              isSafe === true ? "btn-success" : "btn-outline-success"
            }`}
            onClick={() => {
              dispatch(initIsSafe(!isSafe));
            }}
          >
            안전자산
          </button>
        </div>
      </div>
      <div className="col-12 pb-3">
        {/** 종목 등록 버튼 */}
        <button
          className="btn btn-outline-primary w-100"
          onClick={() => {
            dispatch(initStock({}));
            dispatch(initModalShow(true));
          }}
        >
          종목 등록
        </button>
        {/** 종목 추가 버튼 */}
        <StockFormModal handleDeleteStockClick={handleDeleteStockClick} />
      </div>
      {/** 종목 리스트 */}
      <div className="table-responsive">
        <table
          className="table table-sm table-hover align-middle text-center"
          id="stockTable"
        >
          <thead>
            <tr>
              <th style={{ width: "45px", minWidth: "45px" }}>#</th>
              <th style={{ width: "250px", minWidth: "150px" }}>
                [티커] 종목명&nbsp;
                <FontAwesomeIcon icon={faArrowDownAZ} />
              </th>
              <th style={{ minWidth: "200px", width: "auto" }}>링크 정보</th>
              <th style={{ minWidth: "100px", width: "auto" }}>메모</th>
              <th style={{ width: "60px" }}>수정</th>
              <th style={{ width: "60px" }}>삭제</th>
            </tr>
          </thead>
          <tbody>
            {filteredStocks?.map((item, index) => {
              // 네이버 링크
              // [해외 ETF] https://m.stock.naver.com/worldstock/etf/SOXL.K/total
              // [해외 개별주] https://m.stock.naver.com/worldstock/stock/AAPL.O/total
              let naverUri = "";
              if (item?.country?.indexOf("KR") !== -1) {
                // 국내주식
                naverUri = isMobile
                  ? `${domainNaverMobile}/domestic/stock/${item?.ticker}/total`
                  : `${domainNaver}/item/main.naver?code=${item?.ticker}`;
              } else {
                // 해외주식
                if (IsValidValue(item?.naverMainUri)) {
                  if (item?.investType === "stock-etf")
                    naverUri = `${domainNaverMobile}/worldstock/etf/${item?.naverMainUri}/total`;
                  else
                    naverUri = `${domainNaverMobile}/worldstock/stock/${item?.naverMainUri}/total`;
                }
              }
              let stockName = item?.stockName;
              stockName =
                item?.country?.indexOf("KR") === -1
                  ? `<span style="font-size:0.75rem">${stockName}</span>`
                  : `<span style="font-size:0.85rem">${stockName}</span>`;
              let title = IsValidValue(searchText)
                ? `[<strong>${item?.ticker.replace(
                    new RegExp(searchText, "gi"),
                    (match) => `<mark>${match}</mark>`
                  )}</strong>] ${stockName.replace(
                    new RegExp(searchText, "gi"),
                    (match) => `<mark>${match}</mark>`
                  )}`
                : `[<strong>${item?.ticker}</strong>] ${stockName}`;
              if (width < 420) {
                if (item?.country?.indexOf("KR") !== -1)
                  title = title.replace(
                    title.substring(0, title.indexOf("]") + 1),
                    ""
                  );
              }
              return (
                <tr
                  key={index}
                  className={`${item?._id === stock?._id ? "bg-selected" : ""}`}
                  onClick={() => {
                    dispatch(initStock(item));
                  }}
                >
                  <td
                    style={{
                      maxWidth: "1px",
                      textDecoration: "none",
                      color: "black",
                      fontSize: "0.85rem",
                    }}
                  >
                    {filteredStocks?.length - index}
                  </td>
                  <td
                    className={`${item?.isImportant ? "fw-bold" : ""} ${
                      item?.isLineThrough ? "text-decoration-line-through" : ""
                    }`}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                    title={`[${item?.ticker}] ${stockName}`}
                  >
                    <Link
                      style={{
                        color: item?.stockColor,
                      }}
                      to={`/manage/stock/${item?.ticker}`}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </td>
                  <td style={{ maxWidth: "1px", textAlign: "left" }}>
                    {/** 네이버(국내 자동, 해외 수동, 해외는 모바일버전만 가능) */}
                    <Link
                      className={`me-2 small ${
                        IsValidValue(naverUri) ? "" : "d-none"
                      }`}
                      to={naverUri}
                      target="_blank"
                    >
                      네이버
                    </Link>
                    {/** 씨킹알파(해외주식 자동) */}
                    <Link
                      to={`${domainSeeking}/symbol/${item?.ticker}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.country?.indexOf("KR") !== -1 ? "d-none" : ""
                      }`}
                    >
                      씨킹알파
                    </Link>
                    {/** 월마인(수동) */}
                    <Link
                      to={`${domainWallmineCom}${item?.wallmineMainUri}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.country?.indexOf("KR") !== -1 ||
                        !IsValidValue(item?.wallmineMainUri)
                          ? "d-none"
                          : ""
                      }`}
                    >
                      월마인
                    </Link>
                    {/** 더리치(자동) */}
                    <Link
                      className={`me-2 small`}
                      to={`${domainTherich}/stock?ticker=${item?.ticker}`}
                      target="_blank"
                    >
                      더리치
                    </Link>
                    {/** 마켓와치: 국내/외 자동 */}
                    <Link
                      to={
                        item?.investType === "stock-etf"
                          ? `${domainMarketWatch}/investing/fund/${item?.ticker}`
                          : `${domainMarketWatch}/investing/stock/${item?.ticker}`
                      }
                      target="_blank"
                      className={`small me-2`}
                    >
                      마켓와치
                    </Link>
                    {/** 야후 파이넌스(해외주식 자동) */}
                    <Link
                      to={`${domainYahooFinance}/quote/${item?.ticker}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.country?.indexOf("KR") !== -1 ? "d-none" : ""
                      }`}
                    >
                      야후파이넌스
                    </Link>
                    {/** ETF DB(해외 ETF 자동) */}
                    <Link
                      to={`${domainEtfdbCom}/etf/${item?.ticker}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.country?.indexOf("KR") !== -1 ||
                        item?.investType !== "stock-etf"
                          ? "d-none"
                          : ""
                      }`}
                    >
                      ETFDB
                    </Link>
                    {/** ETF COM(해외 ETF 자동) */}
                    <Link
                      to={`${domainEtfCom}/${item?.ticker}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.country?.indexOf("KR") !== -1 ||
                        item?.investType !== "stock-etf"
                          ? "d-none"
                          : ""
                      }`}
                    >
                      ETFCOM
                    </Link>
                    {/** 인베스팅 */}
                    <Link
                      to={`${domainInvestingCom}${item?.investingMainUri}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.investingMainUri ? "" : "d-none"
                      }`}
                    >
                      인베스팅
                    </Link>
                    {/** 트레이딩뷰 */}
                    <Link
                      to={`${domainTradingView}${item?.tradingViewMainUri}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.tradingViewMainUri ? "" : "d-none"
                      }`}
                    >
                      트레이딩뷰
                    </Link>
                    {/** 디비덴드 */}
                    <Link
                      to={`${domainDevidend}${item?.dividendMainUri}`}
                      target="_blank"
                      className={`small me-2 ${
                        item?.dividendMainUri ? "" : "d-none"
                      }`}
                    >
                      디비덴드
                    </Link>
                    {/** 구글 검색 */}
                    <Link
                      to={`https://www.google.com/search?q=${item?.ticker}`}
                      target="_blank"
                      className={`small me-2`}
                    >
                      구글검색
                    </Link>
                  </td>
                  {/** 메모 */}
                  <td
                    className={`${item?.isImportant ? "fw-bold" : ""} ${
                      item?.isLineThrough ? "text-decoration-line-through" : ""
                    }`}
                    style={{
                      maxWidth: "1px",
                      textAlign: "left",
                    }}
                    title={item?.memo}
                  >
                    <Link
                      className="small"
                      style={{
                        color: item?.stockColor,
                      }}
                      to={`/manage/stock/${item?.ticker}`}
                    >
                      {item?.memo}
                    </Link>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() => {
                        dispatch(resetStock());
                        dispatch(initStock(item));
                        dispatch(initModalShow(true));
                      }}
                    >
                      수정
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={(e) => {
                        handleDeleteStockClick(e, item?._id);
                      }}
                    >
                      삭제
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ height: "100px" }}></div>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default InvestHomePage;

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const OriginalBoards = ({ storyBoards, setStoryBoards }) => {
  const axiosHook = useAxiosPrivate();
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [boards, setBoards] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  const searchBoards = useCallback(
    (e) => {
      e.preventDefault();
      const params = {
        page: 1,
        searchText,
        limit: 100,
        isManagePage: true,
      };
      setIsLoading(true);
      axiosHook
        .get(`${apiServer}/boards`, { params })
        .then((res) => {
          setBoards(res.data.pageBoards);
        })
        .catch((err) => {
          console.error(err);
          setError(err.message || "Something went wrong in database");
          addToast({
            type: "danger",
            text: err.message || "게시물을 가져오는데 실패했습니다.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiServer, searchText]
  );

  useEffect(() => {
    document.querySelectorAll("ol#ol-boards > li")?.forEach((li) => {
      if (li.dataset.boardno?.toString() === selectedBoard?.toString()) {
        li.classList.add("active-bg-text");
      } else {
        li.classList.remove("active-bg-text");
      }
    });
  }, [selectedBoard]);

  const addBoardToStory = useCallback(
    (e) => {
      e.preventDefault();
      if (selectedBoard) {
        const addedStoryItem = boards?.find(
          (board) => board.boardNo === selectedBoard
        );
        setStoryBoards([...(storyBoards || []), addedStoryItem || []]);
      }
    },
    [boards, selectedBoard, storyBoards, setStoryBoards]
  );

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div className="sticky-top">
        <h3 className="text-center">원본 게시물</h3>
        <hr />
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="검색어를 입력하세요."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                searchBoards(e);
              }
            }}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={searchBoards}
          >
            검색
          </button>
        </div>
      </div>
      <div className="overflow-auto" style={{ height: "300px" }}>
        <ol id="ol-boards" style={{ paddingLeft: 0 }}>
          {boards?.map((board, index) => (
            <li
              key={index}
              data-boardno={board.boardNo}
              className="lh-lg"
              onClick={(e) => {
                setSelectedBoard(board.boardNo);
                // li 영역 클릭했을 때 라디오버튼 체크
                if (e.target.firstChild) e.target.firstChild.checked = true;
              }}
            >
              <input
                type="radio"
                name="board"
                className="me-2"
                id={`li-board-${board.boardNo}`}
              />
              <label
                htmlFor={`li-board-${board.boardNo}`}
                className={`me-2 cursor-pointer ${
                  board.isLineThroughTitle ? "text-decoration-line-through" : ""
                } ${board.isStrongTitle ? "fw-bold" : ""}`}
                style={{
                  color: board.titleColor ?? "",
                }}
                onClick={() => {
                  setSelectedBoard(board.boardNo);
                  window.open(`/manage/board?boardId=${board._id}`, "_blank");
                }}
                // 검색어가 없을 때는 그냥 제목만 보여주고
                // 검색어가 있을 때는 검색어를 하이라이트해서 보여준다.
                dangerouslySetInnerHTML={{
                  __html: searchText
                    ? board.title.replace(
                        new RegExp(searchText, "gi"),
                        (match) => `<mark>${match}</mark>`
                      )
                    : board.title,
                }}
              />
            </li>
          ))}
        </ol>
      </div>
      <div className="border-top">
        <button
          className="btn btn-outline-success mt-3 w-100"
          onClick={addBoardToStory}
        >
          스토리에 추가
          <i className="bi bi-arrow-right"></i>&nbsp;
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />{" "}
    </>
  );
};

export default OriginalBoards;

import Footer from "../../components/Footer";
import ProjectHeader from "../../components/project/ProjectHeader";
import ProjectList from "../../components/project/ProjectList";
import "./project.css";

const HomePage = () => {
  return (
    <>
      <ProjectHeader />

      <ProjectList />

      <div className="wrapFooter">
        <Footer />
      </div>
    </>
  );
};

export default HomePage;

import { faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MsgBox from "../MsgBox";

const StoryGroupTable = ({
  groups,
  selGroup,
  setSelGroup,
  setGroupModalShow,
}) => {
  const [error, setError] = useState("");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      const groupLinks = document.querySelectorAll(
        `#tblStoryGroup > tbody > tr > th > span`
      );
      groupLinks.forEach((link) => {
        link.style.backgroundColor = "transparent";
      });
      // 선택된 selGroup 의 위치로 스크롤 이동
      const selGroupLink = document.querySelector(
        `#tblStoryGroup > tbody > tr[data-id="${selGroup}"] > th > span`
      );
      if (selGroupLink) {
        selGroupLink.style.backgroundColor = "yellow";
        selGroupLink.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 100);
  }, [selGroup]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <table className="table table-responsive align-middle" id="tblStoryGroup">
        <thead className="table-primary sticky-top">
          <tr>
            <th scope="col" style={{ minWidth: "100px" }}>
              그룹명&nbsp;
              <FontAwesomeIcon icon={faArrowDownAZ} />
            </th>
            <th
              scope="col"
              style={{ minWidth: "50px" }}
            >
              메모
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              공개
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              편집
            </th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {groups?.map((group) => (
            <tr key={group?._id} data-id={group?._id}>
              <th scope="row">
                <span
                  className="cursor-pointer text-primary"
                  onClick={() => {
                    setSelGroup(group?._id);
                  }}
                >
                  <u>{group?.groupName}</u>
                </span>
              </th>
              <td style={{ maxWidth: "1px" }}>
                {/* 특이한 케이스: 지정하지 않으면 글자가 잘리지 않음. */}
                <small>{group?.memo}</small>
              </td>
              <td
                className={`text-center ${
                  group?.isPublic ? "text-primary" : "text-danger"
                }`}
              >
                {group?.isPublic ? "Y" : "N"}
              </td>
              <td className="text-center">
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    setSelGroup(group?._id);
                    setGroupModalShow(true);
                  }}
                >
                  편집
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StoryGroupTable;

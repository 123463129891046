import React from "react";

export const MsgBox = ({ msg, bg }) => {
  return (
    // 눈에 띄는 에러 메시지를 표시하기 위해 전역으로 사용하는 컴포넌트
    <div
      className={
        msg ? `container my-3 d-block` : `container-fluid my-3 d-none`
      }
    >
      <div className="row">
        <div
          className={`border col-12 p-4 alert text-center ${
            bg ? `alert-${bg}` : ""
          }`}
        >
          {msg}
        </div>
      </div>
    </div>
  );
};

export default MsgBox;

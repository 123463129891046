import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner";
import MsgBox from "../../../components/MsgBox";
import StoryGroupModal from "../../../components/story/StoryGroupModal";
import StoryGroupTable from "../../../components/story/StoryGroupTable";
import StoryTable from "../../../components/story/StoryTable";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useToast from "../../../hooks/useToast";

const StoryHomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [groupModalShow, setGroupModalShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selGroup, setSelGroup] = useState("");
  const [selGroupInfo, setSelGroupInfo] = useState(null);
  const navigate = useNavigate();
  const { addToast } = useToast();

  useEffect(() => {
    if (!selGroup) {
      setSelGroup("");
      setSelGroupInfo(null);
      return;
    }
    // group 배열에서 selGroup 을 찾아서 selGroupInfo 에 저장
    const selGroupInfo = groups?.find((group) => group._id === selGroup);
    setSelGroupInfo(selGroupInfo);
  }, [groups, selGroup]);

  useEffect(() => {
    setIsLoading(true);
    axiosHook
      .get(`${process.env.REACT_APP_API_DOAMIN}/storyGroups`)
      .then((res) => {
        setGroups(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook]);

  const handleAddStory = (e) => {
    e.preventDefault();
    if (!selGroup) {
      addToast({
        type: "danger",
        text: "스토리를 추가할 그룹을 선택해주세요.",
      });
      return;
    }
    navigate(`/manage/storyAdd/${selGroup}`);
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div className="overflow-auto" 
      // style={{ height: "72vh" }}
      >
        <div>
          <small>
            * 그룹이 비공개 상태이면 해당 그룹의 모든 스토리는 설정과 관계없이
            비공개 상태를 유지합니다.
          </small>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <h3 className="mt-3">스토리 그룹 목록</h3>
          <div>
            <button
              className="btn btn-sm btn-outline-primary mb-1"
              data-bs-toggle="modal"
              data-bs-target="#groupModal"
              onClick={() => {
                setSelGroup("");
                setSelGroupInfo(null);
                setGroupModalShow(true);
              }}
            >
              그룹 추가
            </button>
          </div>
        </div>
        <div 
        className="pb-3 overflow-auto"
        // style={{ height: "37%", overflowY: "auto" }}
        >
          <StoryGroupTable
            groups={groups}
            selGroup={selGroup}
            setSelGroup={setSelGroup}
            setGroupModalShow={setGroupModalShow}
          />
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <h3 className="mt-4">
            {selGroupInfo?.groupName ? `"${selGroupInfo?.groupName}"` : ""}{" "}
            스토리 목록
          </h3>
          <div>
            <button
              className="btn btn-sm btn-outline-primary mb-1"
              data-bs-toggle="modal"
              data-bs-target="#storyModal"
              onClick={handleAddStory}
            >
              스토리 추가
            </button>
          </div>
        </div>
        <div className="pb-5 overflow-auto" 
        // style={{ height: "43%", overflowY: "auto" }}
        >
          <StoryTable selGroupInfo={selGroupInfo} />
        </div>
      </div>
      <StoryGroupModal
        groupModalShow={groupModalShow}
        setGroupModalShow={setGroupModalShow}
        selGroupInfo={selGroupInfo}
        setSelGroupInfo={setSelGroupInfo}
        setSelGroup={setSelGroup}
        groups={groups}
        setGroups={setGroups}
      />
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StoryHomePage;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import MsgBox from "../MsgBox";

const InvestNav = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container">
        <ul
          style={{
            flexDirection: "row",
            overflowX: "auto",
          }}
          className="navbar-nav mx-auto"
        >
        {isLoggedIn ? (
          <li className="me-3">
            <NavLink
              className={({ isActive }) =>
                "nav-link" + (isActive ? " active" : "")
              }
              to="/manage/marketIndicator"
            >
              시장지표
            </NavLink>
          </li>
        ) : null}
          {isLoggedIn ? (
            <li className="me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/invest"
              >
                내 투자
              </NavLink>
            </li>
          ) : null}
        </ul>
      </div>
    </nav>
  );
};

export default InvestNav;

import { useDispatch } from "react-redux";
import { axiosPrivate } from "../api/axios";
import { IsValidValue, JsonLocalStorage, TaskCatchError } from "../util/common";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  try {
    const refresh = async () => {
      const response = await axiosPrivate.get("/user/refreshToken");
      const newToken = response?.data?.result;
      const newAccessToken = newToken?.newAccessToken;
      const userId = newToken?.userId;
      const userRoles = newToken?.userRoles;
      if (
        !IsValidValue(newAccessToken) ||
        !IsValidValue(userId) ||
        !IsValidValue(userRoles)
      ) {
        return { newAccessToken: "notCreatedToken" };
      } else {
        axiosPrivate.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken}`;
        await JsonLocalStorage.setItem("dnnAt", newAccessToken);
        await dispatch({
          type: "auth/login",
          payload: {
            accessToken: newAccessToken,
            userId,
            userRoles,
          },
        });
        return { newAccessToken };
      }
    };
    return refresh;
  } catch (err) {
    TaskCatchError(err);
    alert("토큰 갱신 실패: useRefreshToken.js catch error");
    return null;
  }
};

export default useRefreshToken;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  domainCodes: [],
  countryCodes: [],
  investTypeCodes: [],
  countryCode: "",
  investTypeCode: "",
  domainNaver: "",
  domainTherich: "",
  domainZum: "",
  domainSeeking: "",
  domainWallmine: "",
  domainNaverMobile: "",
  domainInvestingCom: "",
  domainTradingView: "",
  domainDevidend: "",
  domainMarketWatch: "",
  domainEtfCheck: "",
  domainEtfCom: "",
  domainEtfdbCom: "",
  domainYahooFinance: "",
};

const investCodeSlice = createSlice({
  name: "investCode",
  initialState,
  reducers: {
    initDomainCodes: (state, action) => {
      state.domainCodes = action.payload;
    },
    initCountryCodes: (state, action) => {
      state.countryCodes = action.payload;
    },
    initCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    initInvestTypeCodes: (state, action) => {
      state.investTypeCodes = action.payload;
    },
    resetInvestTypeCode: (state) => {
      state.investTypeCode = "";
    },
    resetCountryCode: (state) => {
      state.countryCode = "";
    },
    initInvestTypeCode: (state, action) => {
      state.investTypeCode = action.payload;
    },
    initDomainNaver: (state, action) => {
      state.domainNaver = action.payload;
    },
    initDomainTherich: (state, action) => {
      state.domainTherich = action.payload;
    },
    initDomainZum: (state, action) => {
      state.domainZum = action.payload;
    },
    initDomainKospdcom: (state, action) => {
      state.domainKospdcom = action.payload;
    },
    initDomainNaverMobile: (state, action) => {
      state.domainNaverMobile = action.payload;
    },
    initDomainWallmineCom: (state, action) => {
      state.domainWallmineCom = action.payload;
    },
    initDomainInvestingCom: (state, action) => {
      state.domainInvestingCom = action.payload;
    },
    initDomainSeeking: (state, action) => {
      state.domainSeeking = action.payload;
    },
    initDomainWallmine: (state, action) => {
      state.domainWallmine = action.payload;
    },
    initDomainTradingView: (state, action) => {
      state.domainTradingView = action.payload;
    },
    initDomainDevidend: (state, action) => {
      state.domainDevidend = action.payload;
    },
    initDomainMarketwatch: (state, action) => {
      state.domainMarketWatch = action.payload;
    },
    initDomainEtfCheck: (state, action) => {
      state.domainEtfCheck = action.payload;
    },
    initDomainEtfCom: (state, action) => {
      state.domainEtfCom = action.payload;
    },
    initDomainEtfdbCom: (state, action) => {
      state.domainEtfdbCom = action.payload;
    },
    initDomainYahooFinance: (state, action) => {
      state.domainYahooFinance = action.payload;
    },
  },
});

export const {
  initDomainCodes,
  initCountryCodes,
  initCountryCode,
  initInvestTypeCodes,
  initInvestTypeCode,
  initDomainNaver,
  initDomainTherich,
  initDomainZum,
  initDomainKospdcom,
  initDomainNaverMobile,
  initDomainInvestingCom,
  initDomainWallmineCom,
  initDomainSeeking,
  initDomainWallmine,
  initDomainTradingView,
  initDomainDevidend,  
  initDomainMarketwatch,
  initDomainEtfCheck,
  initDomainEtfCom,
  initDomainEtfdbCom,
  initDomainYahooFinance,
  resetInvestTypeCode,
  resetCountryCode,
} = investCodeSlice.actions;

export default investCodeSlice.reducer;

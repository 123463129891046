import { useNavigate } from "react-router-dom";

const ProjectHeader = () => {
  const navigate = useNavigate();

  return (
    <header id="mainHeader">
      <div className="container">
        <div id="dvGreeting">
          <h1>
            <a rel="noopener noreferrer" href="/">
              Portfolio
            </a>
          </h1>
          <p>
            <button
              className="btn btn-success"
              onClick={() => {
                navigate("/board");
              }}
            >
              블로그 가기
            </button>
          </p>
          <img
            src="https://dooneenetstorage.blob.core.windows.net/images/me1.jpg"
            alt="me1"
            width="300"
          />
          <p id="pName">
            프로그래머 <span>두니</span>
          </p>
          <p id="pEmail">
            이메일 : <span>d2607@네이버닷컴</span>
          </p>
        </div>
        <div id="dvCareer">
          <div className="dvCareerTitle">
            <h2>보유기술</h2>
            <ul className="pl-0">
              <li>
                풀스택 개발 가능
                <ul>
                  <li style={{ whiteSpace: "break-spaces" }}>
                    언어 및 프레임워크 : React.js, TypeScript, Node.js,
                    Express.js, .Net(C#)...
                  </li>
                  <li style={{ whiteSpace: "break-spaces" }}>
                    DB Modeling : 데이터베이스 모델링 설계 가능
                  </li>
                  <li style={{ whiteSpace: "break-spaces" }}>
                    DB 운영 및 관리 : MongoDB, MariaDB, MS SQL, MySql,
                    PostgreSQL...
                  </li>
                  <li style={{ whiteSpace: "break-spaces" }}>
                    Etc : Git, BitBucket, Bootstrap, ...
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="dvCareerTitle">
            <h2>프로젝트 경력 (KOSA 증빙가능)</h2>
            <ul>
              <li>React</li>
              <li>Express.js</li>
              <li>Node.js</li>
              <li>Vue</li>
              <li>Angular</li>
              <li>Blazor</li>
              <li>ASP.NET MVC Core</li>
              <li>ASP.NET MVC</li>
              <li>ASP.NET</li>
              <li>ASP</li>
              <li>PHP</li>
            </ul>
          </div>
          <div className="dvCareerTitle">
            <h2>교육 이수사항</h2>
            <ul>
              <li>전자상거래관리사 자격증반(1년)</li>
              <li>실무 웹디자인 종일반 4개월 수료</li>
              <li>C# 웹어플리케이션 종일반 6개월 수료</li>
              <li>컴퓨터공학 학사 졸업</li>
              <li>HTML5 과정 수료</li>
              <li>웹퍼블리셔 과외</li>
              <li>웹퍼블리셔 초급/중급 과정 수료</li>
              <li>반응형 웹표준 실무 수료</li>
              <li>기타 온/오프 강좌 및 세미나 다수</li>
            </ul>
          </div>
          <div className="dvCareerTitle">
            <h2>교육/프로젝트 문의</h2>
            <ul>
              <li>4인이하 소규모 과외</li>
              <li>웹/앱 개발</li>
              <li>위의 이메일로 문의주세요!</li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default ProjectHeader;

import { useEffect, useState } from "react";
import axios from "../../api/axios";
import useWindowSize from "../../hooks/useWindowSize";
import { AddComma, GetDateFormat } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import ViewAssetModal from "./ViewAssetModal";

const HistoryTable = ({ walletOpenId, year, name, setAssets, assets }) => {
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [walletId, setWalletId] = useState("");
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    const getAssets = async () => {
      // await setAssets([]);
      const uri = `${apiServer}/wallet/${walletOpenId}`;
      const params = {
        year,
        name,
      };
      setIsLoading(true);
      await axios
        .get(uri, { params })
        .then((res) => {
          setAssets(res.data);
        })
        .catch((e) => {
          console.error(e);
          setError(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiServer, name, walletOpenId, year]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div className="row justify-content-center px-2 table-responsive">
      <table className="table table-striped table-hover mb-0">
        <caption>
          * 클릭하면 상세내역을 볼 수 있습니다.
          <br />* 최근 3년간의 내역만 조회 가능합니다.
          <br />* 투명한 내역을 위해 수정/삭제/순서변경은 불가합니다.
          <br />* 불가피하게 변경이 필요한 경우 새로운 내역으로 가감하는
          방식으로 합니다.
        </caption>
        <thead className="bg-light">
          <tr>
            <th scope="col">{width > 420 ? `일련번호 (날짜)` : `날짜`}</th>
            <th scope="col">이름</th>
            <th scope="col">납부금액</th>
            <th scope="col">잔액</th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
          {assets?.map((asset, index) => {
            return (
              <tr
                key={index}
                onClick={() => {
                  setWalletId(asset._id);
                  setShow(true);
                }}
              >
                <td>
                  {width > 420 ? `${asset.assetNo} (${GetDateFormat(asset.assetDate, 1)})` : GetDateFormat(asset.assetDate, 3)}
                </td>
                <td
                  className={`${
                    asset.name === "공동지출" ? "text-danger" : ""
                  }`}
                >
                  {asset.name}
                </td>
                <td
                  className={`${
                    asset.amount < 0 ? "text-danger" : "text-primary"
                  }`}
                >
                  {AddComma(asset.amount)}
                </td>
                <td className={`${index === 0 ? "bg-warning fw-bold" : ""}`}>
                  {AddComma(asset.totalAmount)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ViewAssetModal
        show={show}
        setShow={setShow}
        walletId={walletId}
        setWalletId={setWalletId}
      />
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default HistoryTable;

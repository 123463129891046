/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

const GotoScrollIcon = (e) => {
  function scrollToTop() {
    if (
      document.body.scrollTop !== 0 ||
      document.documentElement.scrollTop !== 0
    ) {
      window.scrollTo(0, 0);
    }
  }
  function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: 0,
          top: "50%",
          zIndex: 1030,
        }}
      >
        <a
          onClick={scrollToTop}
          className="text-decoration-none link-secondary text-end p-4 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-arrow-up-circle"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
            ></path>
          </svg>
        </a>
        <div className="py-2"></div>
        <a
          onClick={scrollToBottom}
          className="text-decoration-none link-secondary text-end p-4 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-arrow-down-circle"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
            />
          </svg>
        </a>
      </div>
    </>
  );
};

export default GotoScrollIcon;

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../api/axios";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import useToast from "../../hooks/useToast";

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPassword] = useState("");
  const [loginIdErrMsg, setLoginIdErrMsg] = useState("");
  const [loginPasswordErrMsg, setLoginPasswordErrMsg] = useState("");
  const apiDomain = process.env.REACT_APP_API_DOAMIN;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const prevPath = searchParams.get("from");
  const refSubmitbtn = useRef(null);

  const validate = () => {
    setLoginIdErrMsg("");
    setLoginPasswordErrMsg("");
    let isValid = true;
    if (loginId === "") {
      setLoginIdErrMsg("아이디를 입력하세요.");
      isValid = false;
    }
    if (loginPass === "") {
      setLoginPasswordErrMsg("비밀번호를 입력하세요.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    const data = {
      loginId,
      loginPass,
      isPersist: true,
      provider: "local",
    };
    const uri = `${apiDomain}/login`;
    await setIsLoading(true);
    await refSubmitbtn.current.disabled;
    await axios
      .post(uri, data)
      .then((res) => {
        if (res?.data?.ok) {
          localStorage.setItem("dnnAt", res?.data?.result);
          dispatch({
            type: "auth/login",
            payload: {
              accessToken: res?.data?.result,
              userId: res?.data?.userId,
              userRoles: res?.data?.userRoles,
            },
          });
          addToast({
            type: "success",
            text: "로그인 되었습니다.",
          });
          navigate(prevPath || "/manage/invest");
        } else {
          setLoginId("");
          setLoginPassword("");
          addToast({
            type: "danger",
            text: "아이디 또는 비밀번호가 일치하지 않습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addToast({
          type: "danger",
          text: err.message || "로그인에 실패했습니다.",
        });
      })
      .finally(() => {
        refSubmitbtn.current.disabled = false;
        setIsLoading(false);
      });
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="col-10 col-sm-6 col-md-5 col-lg-4 p-5 shadow-sm border rounded-5 border-primary">
        <form>
          <ul className={`text-danger`}>
            <li className={`${loginIdErrMsg ? "" : "d-none"}`}>
              {loginIdErrMsg}
            </li>
            <li className={`${loginPasswordErrMsg ? "" : "d-none"}`}>
              {loginPasswordErrMsg}
            </li>
          </ul>
          <div className="mb-4">
            <input
              type="text"
              className={`form-control bg-info bg-opacity-10 border border-primary ${
                loginIdErrMsg ? "border-danger" : ""
              }`}
              placeholder="아이디를 입력하세요."
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className={`form-control bg-info bg-opacity-10 border border-primary ${
                loginPasswordErrMsg ? "border-danger" : ""
              }`}
              placeholder="비밀번호를 입력하세요."
              value={loginPass}
              onChange={(e) => setLoginPassword(e.target.value)}
            />
          </div>
          {/* <p className="small">
            <a className="text-primary" href="forget-password.html">
              Forgot password?
            </a>
          </p> */}
          <div className="d-grid">
            <button
              className="btn btn-primary"
              type="submit"
              ref={refSubmitbtn}
              onClick={handleSubmit}
            >
              로그인
            </button>
          </div>
        </form>
        {/* <div className="mt-3">
          <p className="mb-0  text-center">
            Don't have an account?{" "}
            <a href="signup.html" className="text-primary fw-bold">
              Sign Up
            </a>
          </p>
        </div> */}
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default LoginPage;

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { CheckUrl } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import { MsgLabel } from "../MsgLabel";

const BoardLinkForm = ({
  bLink,
  boardNo,
  boardLinks,
  setBoardLinks,
  linkId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [isEdited, setIsEdited] = useState(false);
  const [uri, setUri] = useState("");
  const [originalUri, setOriginalUri] = useState("");
  const [title, setTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [isPublish, setIsPublish] = useState(false);
  const [originalIsPublish, setOriginalIsPublish] = useState(false);
  const [isTitleError, setIsTitleError] = useState(false);
  const [isUriError, setIsUriError] = useState(false);
  const [msg, setMsg] = useState("");

  const uriRef = useRef(null),
    titleRef = useRef(null);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const { addToast } = useToast();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (!bLink) return;
    setUri(bLink.link || "");
    setTitle(bLink.title || "");
    setOriginalUri(bLink.link || "");
    setOriginalTitle(bLink.title || "");
    setIsPublish(bLink.isPublish || false);
    setOriginalIsPublish(bLink.isPublish || false);
  }, [bLink]);

  useEffect(() => {
    if (
      uri !== originalUri ||
      title !== originalTitle ||
      isPublish !== originalIsPublish
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [uri, originalUri, title, originalTitle, isPublish, originalIsPublish]);

  const validate = () => {
    let isTitleError = false;
    let isUriError = false;
    setMsg("");
    if (!title) {
      isTitleError = true;
      titleRef.current.focus();
    }
    if (!uri) {
      isUriError = true;
      uriRef.current.focus();
    }
    if (!CheckUrl(uri)) {
      isUriError = true;
      setMsg("올바른 주소를 입력하세요.");
      uriRef.current.focus();
    }
    setIsTitleError(isTitleError);
    setIsUriError(isUriError);
    return !isTitleError && !isUriError;
  };

  const handleSaveLink = (e, linkId) => {
    e.preventDefault();
    if (!validate()) return;
    const data = {
      boardNo,
      title,
      link: uri,
      isPublish,
    };
    let apiUri;
    if (linkId) {
      data._id = linkId;
      apiUri = `${apiServer}/boardLink/edit`;
      axiosHook
        .put(apiUri, data)
        .then((res) => {
          if (res.data.ok) {
            addToast({
              text: res.data.message || "링크가 수정되었습니다.",
              type: "success",
            });
          } else {
            addToast({
              text: res.data.message || "링크 수정에 실패하였습니다.",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          addToast({
            text: err.message || "링크수정에 실패하였습니다.",
            type: "danger",
          });
          console.error(err);
        });
    } else {
      apiUri = `${apiServer}/boardLink/add`;
      axiosHook
        .post(apiUri, data)
        .then((res) => {
          if (res.data.ok) {
            setBoardLinks([...boardLinks, res.data.result]);
            setUri("");
            setTitle("");
            setOriginalUri("");
            setOriginalTitle("");
            setIsPublish(false);
            setOriginalIsPublish(false);
            document.querySelectorAll("input").forEach((input) => {
              input.blur();
            });
            addToast({
              text: res.data.message || "링크가 등록되었습니다.",
              type: "success",
            });
          } else {
            addToast({
              text: res.data.message || "링크 등록에 실패하였습니다.",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          addToast({
            text: err.message || "링크등록에 실패하였습니다.",
            type: "danger",
          });
          console.error(err);
        });
    }
  };

  const handleDeleteLink = (e, linkId) => {
    e.preventDefault();
    if (!linkId) {
      addToast({
        text: "링크 아이디가 선택되지 않았습니다.",
        type: "danger",
      });
      return;
    }
    if (!window.confirm("링크를 삭제하시겠습니까?")) return;
    const apiUri = `${apiServer}/boardLink/delete`;
    const data = {
      _id: linkId,
    };
    setIsLoading(true);
    axiosHook
      .delete(apiUri, { data })
      .then((res) => {
        if (res.data.ok) {
          const newBoardLinks = boardLinks.filter(
            (link) => link._id !== linkId
          );
          setBoardLinks(newBoardLinks);
          addToast({
            text: res.data.message || "링크가 삭제되었습니다.",
            type: "success",
          });
        } else {
          addToast({
            text: res.data.message || "링크 삭제에 실패하였습니다.",
            type: "danger",
          });
        }
      })
      .catch((err) => {
        addToast({
          text: err.message || "링크삭제에 실패하였습니다.",
          type: "danger",
        });
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getWebTitle = (e) => {
    e.preventDefault();
    if (!e.target.value) return;
    if (!CheckUrl(e.target.value)) return;
    axiosHook
      .get(`${apiServer}/website-title/${encodeURIComponent(e.target.value)}`)
      .then((res) => {
        setTitle(res.data || "");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <form>
        <MsgLabel msg={msg} />
        <div>
          <a rel="noopener noreferrer" href={uri} target="_blank">
            {title}
          </a>
        </div>
        <div className="mb-3 input-group">
          <input
            ref={uriRef}
            type="text"
            className={`form-control me-1 ${
              isUriError ? "border-danger border-2" : ""
            }`}
            placeholder="주소를 입력하세요."
            value={uri || ""}
            onChange={(e) => {
              setUri(e.target.value);
            }}
            onBlur={getWebTitle}
          />
          <input
            ref={titleRef}
            type="text"
            className={`form-control me-1 ${
              isTitleError ? "border-danger border-2" : ""
            }`}
            placeholder="주소명을 입력하세요."
            value={title || ""}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <div className="input-group-text me-1 bg-transparent">
            <label className="form-check-label input-group-text bg-transparent border-0">
              <input
                type="checkbox"
                className="form-check-input mt-0"
                checked={isPublish || false}
                onChange={(e) => {
                  setIsPublish(e.target.checked);
                }}
              />
              &nbsp; 공개
            </label>
          </div>
          {bLink ? (
            <>
              <button
                className="btn btn-outline-primary me-1"
                disabled={!isEdited}
                onClick={(e) => handleSaveLink(e, linkId)}
              >
                저장
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={(e) => handleDeleteLink(e, linkId)}
              >
                삭제
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-outline-primary"
                /** width 작게 */
                onClick={handleSaveLink}
              >
                추가
              </button>
            </>
          )}
        </div>
      </form>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default BoardLinkForm;

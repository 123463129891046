import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        Page Not Found
        <br /><br />
        <Link to="/">Go to home</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;

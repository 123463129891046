import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stocks: [],
  stock: "",
  isHighEarning: false,
  isMyInvest: false,
  isDividend: false,
  isSafe: false,
};

const investSlice = createSlice({
  name: "invest",
  initialState,
  reducers: {
    /** stocks */
    initStocks: (state, action) => {
      state.stocks = action.payload;
    },
    addStocks: (state, action) => {
      // 앞쪽에 추가
      state.stocks = [action.payload, ...state.stocks];
    },
    editStocks: (state, action) => {
      state.stocks = state.stocks.map((item) => {
        if (item._id === action.payload?._id) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    resetStocks: (state) => {
      state.stocks = [];
    },
    /** domainCode */
    initDomainCode: (state, action) => {
      state.domainCode = action.payload;
    },
    resetDomainCode: (state) => {
      state.domainCode = "";
    },
    /** stock */
    initStock: (state, action) => {
      state.stock = action.payload;
    },
    resetStock: (state) => {
      state.stock = "";
    },
    /** Modal */
    initModalShow: (state, action) => {
      state.modalShow = action.payload;
    },
    /** isDividend */
    initIsDividend: (state, action) => {
      state.isDividend = action.payload;
    },
    /** isSafe */
    initIsSafe: (state, action) => {
      state.isSafe = action.payload;
    },
    /** isHighEarning */
    initIsHighEarning: (state, action) => {
      state.isHighEarning = action.payload;
    },
    /** isMyInvest */
    initIsMyInvest: (state, action) => {
      state.isMyInvest = action.payload;
    },
  },
});

export const {
  initStocks,
  addStocks,
  editStocks,
  resetStocks,
  initDomainCode,
  resetDomainCode,
  initStock,
  resetStock,
  initModalShow,
  initIsDividend,
  initIsSafe,
  initIsHighEarning,
  initIsMyInvest,
} = investSlice.actions;

export default investSlice.reducer;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import MsgBox from "../MsgBox";

const ManageNavBar = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  //const dispatch = useDispatch();
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      // setError("Need to login");
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <nav className="navbar navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          두니
        </Link>
        <ul
          style={{
            flexDirection: "row",
            overflowX: "auto",
          }}
          className="navbar-nav"
        >
          {isLoggedIn ? (
            <li className="me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/code"
              >
                Code
              </NavLink>
            </li>
          ) : null}
          {isLoggedIn ? (
            <li className="me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/project/table"
              >
                Portfolio
              </NavLink>
            </li>
          ) : null}
          {/* {isLoggedIn ? (
            <li className="nav-item me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/blog"
              >
                Blog
              </NavLink>
            </li>
          ) : null} */}
          {isLoggedIn ? (
            <li className="nav-item me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/board"
              >
                Board
              </NavLink>
            </li>
          ) : null}
          {isLoggedIn ? (
            <li className="nav-item me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/story"
              >
                Story
              </NavLink>
            </li>
          ) : null}
          {isLoggedIn ? (
            <li className="nav-item me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/manage/marketIndicator"
              >
                Invest
              </NavLink>
            </li>
          ) : null}
          {isLoggedIn ? (
            <li className="nav-item me-3">
              <NavLink
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " active" : "")
                }
                to="/wallet/G3WwbDsYdy8w"
                // target={"_blank"}
              >
                Wallet
              </NavLink>
            </li>
          ) : null}
          <li className="nav-item ps-4">
            <button
              className="nav-link"
              onClick={() => {
                if (isLoggedIn) {
                  navigate("/logout");
                } else {
                  navigate("/login");
                }
              }}
            >
              {isLoggedIn ? "Logout" : "Login"}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default ManageNavBar;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import useToast from "../../hooks/useToast";

export default function LogoutPage() {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("processing...");
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    const logout = async () => {
      try {
        await localStorage.setItem("dnnAt", "");
        await axios.get(`${apiServer}/logout`);
        await dispatch({
          type: "auth/logout",
        });
        await addToast({
          type: "success",
          text: "로그아웃 되었습니다.",
        });
        // navigate("/login");
        // navigate 사용하면 (replace: true 로 설정해도)
        // 로그아웃 후 다른 아이디로 로그인 하면 기존의 정보가 그대로 남아있음!
        setTimeout(() => {
          window.location.href = "/login";
        }, 100);
      } catch (err) {
        console.log(err);
        if (err.code === "ERR_NETWORK")
          setMsg(`${err.message}.<br />잠시 후 다시 시도해주세요.`);
        else
          setMsg(
            `로그아웃에 실패했습니다.<br />잠시 후 <a rel="noopener noreferrer" href="/logout"><strong>다시 시도</strong></a> 해주세요.`
          );

        await addToast({
          type: "error",
          text: "로그아웃에 실패했습니다.",
        });

        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    };
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container vh-100 d-flex justify-content-center align-items-center">
      <div dangerouslySetInnerHTML={{ __html: msg }} />
    </section>
  );
}

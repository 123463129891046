/** 'at' localStorage를 조회해서 토큰 리뉴
 * 유효한 토큰이거나 유효하지만 만료(419) 에러일 경우 토큰 리뉴
 * 리플래시 토큰으로 자동로그인 해서 인증정보(auth)도 갱신해준다.
 */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import { IsValidValue } from "../util/common";

const TokenRenew = () => {
  const refresh = useRefreshToken();
  const navigate = useNavigate();

  // const location = useLocation();
  /** pathname: 이전 페이지의 경로
   * search: 이전 페이지의 쿼리스트링
   * from: 이전 페이지의 경로와 쿼리스트링
   * [참고만] navigate(from, { replace: false }) : 파이어폭스에서 무한루프 현상 발생.
   */
  // const pathname = location.state?.from?.pathname || "/",
  //   search = location.state?.from?.search || "",
  //   from = pathname + search;

  // from query string
  const from = new URLSearchParams(useLocation().search).get("from");

  useEffect(() => {
    if (localStorage.getItem("dnnAt")) {
      const renewToken = async () => {
        try {
          const rf = await refresh();
          if (
            !IsValidValue(rf?.newAccessToken) ||
            rf?.newAccessToken === "notCreatedToken"
          ) {
            alert(
              "[임시 메시지] 토큰 갱신에 실패했습니다.\n인증 관련 정보를 모두 삭제합니다."
            );
            // { replace: false } 옵션은 임시로 콘솔 확인용으로 상용에서는 true로 변경
            //navigate("/logout", { replace: false });
          } else {
            alert(
              "[임시 메시지] 토큰 갱신에 성공했습니다.\n이전 페이지로 이동합니다."
            );
            // [참고만] 파이어폭스에서 무한루프 현상 발생.
            navigate(from, { replace: false });
          }
        } catch (err) {
          console.error(err);
          alert(
            "[임시 메시지, catch error] 토큰 갱신에 실패했습니다.\n인증 관련 정보를 모두 삭제합니다."
          );
          // { replace: false } 옵션은 임시로 콘솔 확인용으로 상용에서는 true로 변경
          // navigate("/logout", { replace: false });
        }
      };
      renewToken();
    } else {
      alert("[임시 메시지] 인증 정보가 없습니다.\n로그인 페이지로 이동합니다.");
      // navigate("/login", { replace: false });
    }
  }, [from, navigate, refresh]);

  return <></>;
};

export default TokenRenew;

import { Link } from "react-router-dom";

const PublicNavBar = () => {
  return (
    <nav className="navbar navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">
          두니
        </Link>
        <ul
          id="ulBoardGroups"
          className="navbar-nav navbar-nav-scroll one-line-sentence"
          style={{ flexDirection: "row", overflowX: "auto" }}
        >
          <li className="me-4">
            <Link className="nav-link" to="/board">
              블로그
            </Link>
          </li>
          <li className="me-4">
            <Link className="nav-link" to="/story">
              스토리
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default PublicNavBar;

/**
 * wallet 만들기/수정하기/삭제하기 페이지는 별도로 없고
 * DB에서 직접 입력/수정/삭제 한다.
 * 만들어진 wallet 은 본 페이지에서 입력/삭제만 할 수 있다.
 */
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddModal from "../../components/wallet/AddModal";
import Anniversary from "../../components/wallet/Anniversary";
import BankInfo from "../../components/wallet/BankInfo";
import HistoryTable from "../../components/wallet/HistoryTable";
import MemberButtons from "../../components/wallet/MemberButtons";
import Notice from "../../components/wallet/Notice";
import TopControls from "../../components/wallet/TopControls";

const WalletViewPage = () => {
  const { walletOpenId } = useParams();
  const [assets, setAssets] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [year, setYear] = useState("");
  const [name, setName] = useState("");
  const [names, setNames] = useState([]);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = new useNavigate();

  useEffect(() => {
    window.document.title = "가족계 입출금 내역";
  }, []);

  useEffect(() => {
    try {
      /**
       * 유효한 walletAuthToken 세션스토리지가 없거나
       * 유효한 walletAuthToken 세션스토리지가 있지만 토큰이 유효하지 않을 경우
       */
      let token = window.sessionStorage.getItem("walletAuthToken");
      if (!token) return navigate("/walletAuth");

      let decodedToken = jwt_decode(token);
      let currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("Token expired.");
        navigate("/walletAuth");
      } else {
        console.log("Valid token");
      }
    } catch (err) {
      console.error(err);
      navigate("/walletAuth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 멤버 이름 목록
    setNames(["공동지출", "김나은", "김사야", "김은진", "김종해", "김종훈"]);
  }, []);

  return (
    <div className={`container text-center p-3 pe-4`}>
      <h1 className="mb-3">가족계 입출금 내역</h1>
      {/**
       * 로그인 하지 않으면 아래 버튼이 보이지 않는다.
       * 지갑관리자 권한도 체크해야 한다!
       */}
      <div className={`my-3 ${isLoggedIn ? "" : "d-none"}`}>
        <button
          className="btn btn-success col-12"
          onClick={() => {
            setModalShow(true);
          }}
        >
          신규 등록
        </button>
      </div>
      <div>
        <AddModal
          showParam={modalShow}
          setShowParam={setModalShow}
          names={names}
          name={name}
          setName={setName}
          setAssets={setAssets}
          assets={assets}
          walletOpenId={walletOpenId}
        />
      </div>
      <div>
        <TopControls
          year={year}
          setYear={setYear}
          name={name}
          setName={setName}
          names={names}
        />
      </div>
      <div>
        <MemberButtons
          year={year}
          names={names}
          setName={setName}
          name={name}
          walletOpenId={walletOpenId}
        />
      </div>
      <hr className="my-4" />
      <div>
        <HistoryTable
          walletOpenId={walletOpenId}
          year={year}
          name={name}
          setAssets={setAssets}
          assets={assets}
        />
      </div>
      <hr className="my-4" />
      <div>
        <BankInfo />
      </div>
      <hr className="my-4" />
      <div>
        <Notice />
      </div>
      <hr className="my-4" />
      <div>
        <Anniversary />
      </div>
    </div>
  );
};

export default WalletViewPage;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";
import { IsValidValue } from "../util/common";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const MAX_RETRY_COUNT = 3;

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          /** 현재 프로젝트:
           * 정상적으로 만료된 액세스 토큰(code: 419)이 있어야 리플래시 토큰을 발급해주는 구조
           * 새로고침 시 auth가 초기화 되서 토큰 자체가 사라짐
           * 해결방안: localStorage에 액세스 토큰 저장해서 사용
           * localStorage에 저장된 액세스 토큰이 없으면 로그인 페이지로 이동
           */
          const accessToken = localStorage.getItem("dnnAt");
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.code === "ERR_NETWORK") {
          //alert("네트워크 오류", error?.message || "네트워크 오류");
          window.location.href = "/logout";
          //return;
        }
        // 401 = Unauthorized (인증X)
        // 403 = Forbidden (권한없음)
        // 419 = TokenExpiredError (인증만료)
        if (error?.response?.status === 419 && !prevRequest?.sent) {
          const rf = await refresh();
          const newAccessToken = rf?.newAccessToken;
          if (
            !IsValidValue(newAccessToken) ||
            newAccessToken === "notCreatedToken"
          ) {
            prevRequest.retryCount = prevRequest.retryCount ?? 0;
            const shouldRetry = prevRequest.retryCount < MAX_RETRY_COUNT;
            if (shouldRetry) {
              prevRequest.retryCount += 1;
              return await axiosPrivate.request(prevRequest);
            } else {
              // alert("토큰 갱신 실패");
              window.location.href = "/logout";
              //return;
            }
          } else {
            prevRequest.sent = true;
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            return axiosPrivate(prevRequest);
          }
        } else {
          //alert("유효한 토큰 부재로 로그아웃 합니다.");
          window.location.href = "/logout";
          //return;
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refresh, navigate]);

  return axiosPrivate;
};

export default useAxiosPrivate;

/**
 * TEST
 */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InvestNav from "../../../components/invest/InvestNav";

const MarketIndicatorPage = () => {
  const domainCodes = useSelector((state) => state.investCode?.domainCodes);
  const domainNaver = useSelector((state) => state.investCode?.domainNaver);
  const domainInvestingCom = useSelector(
    (state) => state.investCode?.domainInvestingCom
  );
  const domainKospdcom = useSelector(
    (state) => state.investCode?.domainKospdcom
  );
  const domainEtfCom = useSelector((state) => state.investCode.domainEtfCom);
  const domainEtfCheck = useSelector(
    (state) => state.investCode.domainEtfCheck
  );

  return (
    <>
      <div className="col-12 pb-3">
        {/** 네비게이션 */}
        <InvestNav />
      </div>
      {/** 비트코인 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">비트코인</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://charts.bitbo.io/long-term-power-law/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-warning text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  장기 전력 법칙(bitbo.io)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://charts.bitbo.io/cycle-low-multiple/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-warning text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  실시간 전체 사이클 레이어 차트(bitbo.io)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://charts.bitbo.io/cycle-repeat/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  실시간 최근4년 사이클 레이어 차트(bitbo.io)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://charts.bitbo.io/rainbow/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  레인보우 차트(bitbo.io)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://charts.bitbo.io/halving-progress/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  반감기 차트(bitbo.io)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://charts.bgeometrics.com/m2_global.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-warning text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  M2 통화공급량과 비트코인 차트(bgeometrics.com)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 랭킹 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">랭킹(수익률, 배당)</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`${domainEtfCheck}/mobile/rank/yield`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-warning text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  ETF 수익률 랭킹(etfcheck)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainEtfCheck}/mobile/rank/cash`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  ETF 배당 랭킹(etfcheck)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 시가총액 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">시가총액</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://companiesmarketcap.com/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-success text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  전세계 개별주 시총순위(companiesmarketcap.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://8marketcap.com/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-success text-black-50">
                  전세계 모든 자산 시총순위(8marketcap.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainEtfCheck}/mobile/global/rank/marketCap`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-success text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  미국 ETF 시총순위(etfCheck)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainEtfCom}/mobile/global/rank/marketCap`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-success text-black-50">
                  글로벌 ETF 시총순위(etf.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainNaver}/sise/sise_market_sum.naver`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-success text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  국내 개별주 시총순위(네이버)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainNaver}/sise/etf.naver`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-success text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  국내 ETf 시총순위(네이버)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://tossinvest.com/screener/us`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-success text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  토스증권 해외 시총순위(tossinvest.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://kr.tradingview.com/screener/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-success text-black-50">
                  트레이딩뷰 미국 시총순위(tradingview.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://m.stock.naver.com/worldstock/home/USA/marketValue/NASDAQ`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-success text-black-50">
                  네이버증권 해외 시총순위(m.stock.naver.com)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 증시맵 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">증시맵</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://finviz.com/map.ashx?t=sec`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-primary text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  S&P500(finviz.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://finviz.com/map.ashx?t=etf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-primary text-black-50">
                  미국ETF(finviz.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://wallmine.com/screener?e%5B%5D=NYSE&e%5B%5D=NASDAQ&e%5B%5D=NYSEMKT&r=m`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-primary text-black-50">
                  Free Stock Screener(wallmine.com)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainKospdcom}/#/maps/1day`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-primary text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  KRX 300 map(kospd.com)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 투자 캘린더 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">투자 캘린더</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`${domainInvestingCom}/economic-calendar`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-info text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  경제 캘린더(인베스팅닷컴)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`${domainInvestingCom}/earnings-calendar`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-info text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  실적발표 캘린더(인베스팅닷컴)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 환율 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">환율</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`${domainNaver}/marketindex/exchangeDetail.naver?marketindexCd=FX_USDKRW`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-danger">미국 달러(네이버)</div>
              </Link>
              <Link
                className="me-2"
                to={`${domainNaver}/marketindex/exchangeDetail.naver?marketindexCd=FX_JPYKRW`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-danger">일본 엔(네이버)</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 포트폴리오 관리 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">포트폴리오 관리</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://m.stock.naver.com/?holdingStock=currentPrice`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className="btn btn-outline-warning text-black-50"
                  style={{ fontWeight: "bold" }}
                >
                  네이버페이증권 MY
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://www.therich.io/my/portfolios`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  더리치 포트폴리오
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://www.therich.io/my/favorite-item-categories`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  더리치 관심종목
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://kr.investing.com/portfolio`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  포트폴리오(인베스팅닷컴)
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 차트 비교 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">차트 비교</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://seekingalpha.com/symbol/BITX/charting?compare=BITX%2CSP500%2CTQQQ&from=2023-06-27&interval=&metric=totalReturn&ticker_id=758294`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  BITX (2023/6/27 이후) 차트 비교 - 동작안함.
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 차트 비교 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">기타</div>
            <div className="card-body">
              <Link
                className="me-2"
                to={`https://fred.stlouisfed.org/graph/?g=NLGA`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  미국 (10년-2년)장단기 금리차(FRED 홈페이지)
                </div>
              </Link>
              <Link
                className="me-2"
                to={`https://kr.investing.com/rates-bonds/u.s.-10-year-bond-yield`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn btn-outline-warning text-black-50">
                  미국 10년물 국채 금리 채권수익률
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/** 참고 웹사이트 */}
      <div className="row mb-3 select-btn">
        <div className="col">
          <div className="card">
            <div className="card-header">참고 웹사이트</div>
            <div className="card-body">
              {domainCodes.map((item, idx) => (
                <Link
                  key={idx}
                  className={`me-2 ${item.useStatus === "USY" ? "" : "d-none"}`}
                  to={`${item.memo?.trim()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="btn btn-outline-info text-black-50">
                    {item.codeName}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketIndicatorPage;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import MsgBox from "../MsgBox";

const BoardGroups = ({
  isManagePage,
  group,
  category,
  setIsLoading,
  setBoardName,
}) => {
  const axiosHook = useAxiosPrivate();
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState("");
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const { addToast } = useToast();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    // 관리자 페이지인데 로그인이 안되어 있으면 에러
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    const getGroups = () => {
      setIsLoading(true);
      const uri = isManagePage
        ? `${apiServer}/manage/dBoard/childCodes`
        : `${apiServer}/dBoard/childCodes`;
      axiosHook
        .get(uri)
        .then((res) => {
          setGroups(res.data || []);
        })
        .catch((e) => {
          console.error(e);
          setError("something went wrong in db");
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (category || !groups) {
      return;
    }
    if (!group) {
      setBoardName(isManagePage ? "관리자용 전체 목록" : "전체 목록");
      return;
    }
    groups?.forEach((g) => {
      if (g.code === group) {
        setBoardName(g.codeName);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, group, category, isManagePage]);

  useEffect(() => {
    setTimeout(() => {
      // active 클래스가 있는 링크로 스크롤 이동
      const activeLink = document.querySelector(
        "#ulBoardGroups .nav-link.active"
      );
      if (activeLink) {
        activeLink.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 1000);
  }, [group]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <ul
        id="ulBoardGroups"
        style={{
          flexDirection: "row",
          overflowX: "auto",
        }}
        className="navbar-nav navbar-nav-scroll one-line-sentence"
      >
        {groups?.map((group) => (
          <li className="me-4" key={group.code}>
            <NavLink
              className={({ isActive }) =>
                "nav-link " + (isActive ? "active fw-bold" : "")
              }
              to={
                isManagePage
                  ? `/manage/board/${group.code}`
                  : `/board/${group.code}`
              }
            >
              <span
                className={
                  group.useStatus === "USN" || group.useStatus === "USD"
                    ? "text-decoration-line-through"
                    : ""
                }
              >
                {group.codeName}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

export default BoardGroups;

import {
  faChevronDown,
  faChevronUp,
  faCircleChevronDown,
  faCircleChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { MoveTo } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const StoryItemsManage = ({ _id, storyBoards, setStoryBoards }) => {
  const axiosHook = useAxiosPrivate();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [selectedStoryItem, setSelectedStoryItem] = useState(null);
  const [selectedLi, setSelectedLi] = useState(null);
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  // 이동 버튼을 누를 때마다 새로 버튼이 눌렸다는 것을 알기 위한 변수
  const [moveButtonClicked, setMoveButtonClicked] = useState(false);

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);
  
  // 버튼이 눌릴 때마다 selectedLi를 중앙으로 이동시키기 위한 useEffect
  useEffect(() => {
    if (moveButtonClicked) {
      // #ol-story-items 의 li 중에서 selectedLi를 찾아서 맨위로 스크롤
      const ol = document.getElementById("ol-story-items");
      const liList = ol.querySelectorAll("li");
      liList?.forEach((li) => {
        if (li?.dataset.boardno.toString() === selectedStoryItem?.toString()) {
          li?.scrollIntoView({ behavior: "smooth", block: "nearest" }); // center 가 안먹힘.
        }
      });
      setMoveButtonClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveButtonClicked]);

  useEffect(() => {
    document.querySelectorAll("ol#ol-story-items > li")?.forEach((li) => {
      if (li.dataset.boardno?.toString() === selectedStoryItem?.toString()) {
        li.classList.add("active-bg-text");
      } else {
        li.classList.remove("active-bg-text");
      }
    });
  }, [selectedStoryItem]);

  const handleSaveStoryList = async (e) => {
    e.preventDefault();
    const boardNos = [];
    const ol = document.getElementById("ol-story-items");
    const liList = ol.querySelectorAll("li");
    await liList.forEach((li) => {
      boardNos.push(parseInt(li.dataset["boardno"]));
    });
    const data = {
      _id,
      boardNos,
    };
    await setIsLoading(true);
    await axiosHook
      .put(`${apiServer}/storyListEdit`, data)
      .then((res) => {
        if (res.data.ok) {
          addToast({
            type: "success",
            text: res.data.message || "스토리 리스트가 수정되었습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "스토리 수정에 실패했습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.message || "Something went wrong in database");
        addToast({
          type: "danger",
          text: err.message || "스토리 수정에 실패했습니다.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteStoryItem = async (e) => {
    e.preventDefault();
    if (!selectedStoryItem || !selectedLi) {
      addToast({
        type: "danger",
        text: "삭제할 스토리 아이템을 선택하세요.",
      });
      return;
    }
    setStoryBoards(
      storyBoards.filter((story) => story.boardNo !== selectedStoryItem)
    );
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div className="sticky-top">
        <h3 className="text-center">스토리 아이템</h3>
        <hr />
      </div>
      <div className="overflow-auto" style={{ height: "221px" }}>
        <ol id="ol-story-items" style={{ paddingLeft: 0 }}>
          {storyBoards?.map((story, index) => (
            <li
              key={index}
              data-boardno={story.boardNo}
              className="lh-lg"
              onClick={(e) => {
                setSelectedStoryItem(story.boardNo);
                setSelectedLi(e.target.closest("li"));
                // li 영역 클릭했을 때 라디오버튼 체크
                if (e.target.firstChild) e.target.firstChild.checked = true;
              }}
            >
              <input
                type="radio"
                name="story"
                className="me-2"
                id={`li-story-${story.boardNo}`}
                value={story.boardNo || ""}
                onClick={(e) => {
                  setSelectedStoryItem(story.boardNo);
                  setSelectedLi(e.target.closest("li"));
                }}
              />
              <label
                htmlFor={`li-story-${story.boardNo}`}
                className={`me-2 cursor-pointer ${
                  story.isLineThroughTitle ? "text-decoration-line-through" : ""
                } ${story.isStrongTitle ? "fw-bold" : ""}`}
                style={{
                  color: story.titleColor ?? "",
                }}
                onClick={(e) => {
                  setSelectedStoryItem(story.boardNo);
                  setSelectedLi(e.target.closest("li"));
                  // window.open(`/manage/board/view/${story._id}`, "_blank");
                  window.open(`/manage/board?boardId=${story._id}`, "_blank");
                }}
              >
                {story.title}
              </label>
            </li>
          ))}
        </ol>
      </div>
      <div className="row mt-3 border-top pt-3">
        <div className="btn-group col-12">
          <button
            type="button"
            className="col btn btn-outline-secondary"
            onClick={(e) => {
              MoveTo(
                e,
                selectedStoryItem,
                selectedLi,
                addToast,
                "ol-story-items",
                "top"
              );
              setMoveButtonClicked(true);
            }}
          >
            <i className="bi bi-chevron-double-up"></i>
            <FontAwesomeIcon icon={faCircleChevronUp} />
          </button>
          <button
            type="button"
            className="col btn btn-outline-secondary"
            onClick={(e) => {
              MoveTo(
                e,
                selectedStoryItem,
                selectedLi,
                addToast,
                "ol-story-items",
                "up"
              );
              setMoveButtonClicked(true);
            }}
          >
            <i className="bi bi-chevron-up"></i>
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
          <button
            type="button"
            className="col btn btn-outline-secondary"
            onClick={(e) => {
              MoveTo(
                e,
                selectedStoryItem,
                selectedLi,
                addToast,
                "ol-story-items",
                "down"
              );
              setMoveButtonClicked(true);
            }}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button
            type="button"
            className="col btn btn-outline-secondary"
            onClick={(e) => {
              MoveTo(
                e,
                selectedStoryItem,
                selectedLi,
                addToast,
                "ol-story-items",
                "bottom"
              );
              setMoveButtonClicked(true);
            }}
          >
            <FontAwesomeIcon icon={faCircleChevronDown} />
          </button>
        </div>
      </div>
      <div className="row border-top mt-3">
        <button
          className="btn btn-outline-primary mt-3"
          onClick={handleSaveStoryList}
        >
          저장
        </button>
        <button
          className="btn btn-outline-danger mt-2"
          onClick={handleDeleteStoryItem}
        >
          선택 삭제
        </button>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StoryItemsManage;

const JsonLocalStorage = {
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
  removeAll: () => {
    localStorage.clear();
  },
};
exports.JsonLocalStorage = JsonLocalStorage;

const GetStorageValue = (key) => {
  return localStorage.getItem(key);
};
exports.GetStorageValue = GetStorageValue;

const GetProtocol = () => {
  return window.location.protocol;
};
exports.GetProtocol = GetProtocol;

const GetDomain = () => {
  return window.location.hostname;
};
exports.GetDomain = GetDomain;

const GetPort = () => {
  return window.location.port;
};
exports.GetPort = GetPort;

const GetShortPort = () => {
  if (GetPort === 80 || GetPort === 443) return "";
  else return `:${window.location.port}`;
};
exports.GetShortPort = GetShortPort;

const IsValidDate = (dateStr) => {
  if (Object.prototype.toString.call(dateStr) === "[object Date]") {
    if (!isNaN(dateStr.getTime())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
exports.IsValidDate = IsValidDate;

// ############################################################
// up: const
// down: direct exports
// ############################################################

exports.UserRoles = {
  /** 가입한 샘플 사용자 */
  SampleUser: 1000,
  /** 가입한 일반 사용자 */
  JoinedUser: 2000,
  /** 최고 관리자 */
  SuperAdmin: 9000,
};

exports.IsValidTokenValue = (key) => {
  const value = GetStorageValue(key);
  return value && value.length > 30 ? true : false;
};

exports.GetBasicUrl = () => {
  return `${GetProtocol()}//${GetDomain()}${GetShortPort()}`;
};

exports.IsEnterkey = (e) => {
  if (e.key.toLowerCase() === "enter") return true;
  return false;
};

exports.AddMinute = (dt, min) => {
  if (IsValidDate(dt)) return dt.setMinutes(dt.getMinutes() + min);
  else return null;
};

// 지정한 ms 만큼 지연한다.
exports.Delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

exports.ConsoleLog = (name, value) => {
  // console.log("process.env.NODE_ENV => ", process.env.NODE_ENV);
  // 보통 local 은 deveopment, server 는 production 으로 출력됨.
  //if (process.env.NODE_ENV !== "development") return;
  if (value) {
    // 백틱 속에 value 를 넣으면 object object 로 출력된다.
    return console.log(`\n[${name}] => \n`, value);
  } else {
    return console.log(name);
  }
};

exports.ConsoleError = (name, err) => {
  return console.error(`\n[${name} ERROR] => \n`, err);
};

/** 콘솔에 에러 출력, 추후에 변경 가능 */
exports.TaskCatchError = (err) => {
  const error = {
    name: err.name,
    message: err.message,
    code: err.code,
    // 라인
    line: err.line,
    // 에러가 발생한 줄
    lineNumber: err.lineNumber,
    // 컬럼
    column: err.column,
    // 에러가 발생한 컬럼
    columnNumber: err.columnNumber,
    // 에러가 발생한 파일명
    fileName: err.fileName,
    // 에러가 발생한 함수명
    functionName: err.functionName,
    // 에러가 발생한 스택
    stack: err.stack,
    // 에러가 발생한 객체
    errorObject: err.errorObject,
    // 에러가 발생한 객체의 프로퍼티
    propertyName: err.propertyName,
    // 에러가 발생한 객체의 프로퍼티 값
    propertyValue: err.propertyValue,
  };
  this.ConsoleError("TaskCatch => ", error);
};

/** [강제 새로고침]
 * 주소를 직접 쳐서 진입할 경우 auth 인증이 초기화된다.
 * 주소를 직접 쳐서 진입할 경우 referrer가 없다.
 * 이를 인용해 새로고침 해서 인증을 유지(갱신) 한다.
 * 필요한 페이지 에서만 사용 */
exports.NoReferrerReload = () => {
  // 주소를 바로 치고 들어와서 referrer 가 없을 경우
  // localStorage at가 있으면 tokenrenew 페이지로 이동한다.
  if (!document.referrer && localStorage.getItem("dnnAt")) {
    window.location.replace("/tokenRenew");
  }
};

/** 인증이 없어야 하는 페이지에서 사용.
 * /logout 페이지로 강제이동 해서 인증정보를 모두 삭제한다.
 * */
exports.CheckAuthRemove = () => {
  if (localStorage.getItem("dnnAt")) {
    window.location.href = "/logout";
  }
};

/** 특수문자 입력 못하게 하는 함수 */
exports.CheckSpecialChar = (e) => {
  const regex = /[~!@#$%^&*()_+|<>?:{}]/;
  const key = e.key;
  if (regex.test(key)) {
    e.preventDefault();
    return false;
  }
};

/** PWA 새창에서 독립적으로 열기 */
exports.OpenNewWindow = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

/** 특정 엘리먼트로 스크롤 */
exports.ScrollTo = (el) => {
  // msgRef로 scroll 이동
  document.querySelector(el).scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "nearest",
  });
};

/** 선택한 엘리먼트를 화면 가로 중앙으로 스크롤 하는 함수 */
exports.ScrollToWCenter = (e) => {
  // e.preventDefault(); // 이 기능을 사용하면 a 태그의 href 기능이 동작하지 않는다.
  const el = e.target;
  // el을 화면 중앙으로
  el.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
};

/** textarea 높이 자동 조절
 * 10/25 글 깔끔하게 정리하는 옵션 추가
 */
exports.HandleAutoHeight = (e) => {
  e.target.style.height = e.target.scrollHeight + "px";
  e.target.style.maxHeight = "200px";
  e.target.style.overflowY = "scroll";
  // 두 공백 한 공백으로 replace
  e.target.value = e.target.value
    // .replace(/(^ *)|( *$)/g, "")
    .replace(/  +/g, " ");
  // 두줄 한줄로 replace
  e.target.value = e.target.value.replace(/\n\n\n/g, "\n\n");
  // 줄바꿈 + 공백 => 한줄로 replace
  e.target.value = e.target.value.replace(/\n  +/g, " ");
  // 새줄의 시작은 공백 없애기
  e.target.value = e.target.value.replace(/\n /g, "\n");
};

/** url 정규식 체크 */
exports.CheckUrl = (url) => {
  const regex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
  if (regex.test(url)) return true;
  else return false;
};

/**
 * &lt;, &#40; 등의 html entity 를 디코딩 한다.
 * textarea 에서 유용하게 사용할 수 있다.
 */
exports.decodeHTMLEntities = (str) => {
  if (str !== undefined && str !== null && str !== "") {
    str = String(str);

    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    var element = document.createElement("div");
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = "";
  }

  return str;
};

// 숫자로 받아온 요일을 한글요일로 변환해서 반환
exports.GetDayText = (day) => {
  switch (day) {
    case 0:
      return "일";
    case 1:
      return "월";
    case 2:
      return "화";
    case 3:
      return "수";
    case 4:
      return "목";
    case 5:
      return "금";
    case 6:
      return "토";
    default:
      return "";
  }
};

exports.GetDateText = (timestamp, type) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  switch (type) {
    case 1:
      // YYYY-MM-DD
      return `${date.getFullYear()}-${date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      }`;
    case 2:
      // YYYY.MM.DD
      return `${date.getFullYear()}.${date.getMonth() + 1}.${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      }`;
    case 3:
      // MM-DD
      return `${date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      }`;
    case 4:
      // YYYY-MM-DD HH:MM:SS
      return `${date.getFullYear()}-${date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      } ${
        date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
      }:${
        date.getMinutes() < 10
          ? `0${date.getMinutes()}`
          : `${date.getMinutes()}`
      }:${
        date.getSeconds() < 10
          ? `0${date.getSeconds()}`
          : `${date.getSeconds()}`
      }`;
    case 5:
      // YYYY-MM-DD HH:MM
      return `${date.getFullYear()}-${date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      } ${
        date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
      }:${
        date.getMinutes() < 10
          ? `0${date.getMinutes()}`
          : `${date.getMinutes()}`
      }`;
    case 6:
      // YYYY-MM-DD (day) HH:MM
      return `${date.getFullYear()}-${date.getMonth() + 1}-${
        date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      } ${this.GetDayText(date.getDay()) + "요일"} ${
        date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
      }:${
        date.getMinutes() < 10
          ? `0${date.getMinutes()}`
          : `${date.getMinutes()}`
      }`;
    default:
      // ex: 2023. 8. 30. 오전 8:50:10
      return date.toLocaleString();
  }
};

exports.PassDateEasyText = (date) => {
  const milliSeconds = new Date() - date;
  const seconds = milliSeconds / 1000;
  if (seconds < 60) return `방금 전`;
  const minutes = seconds / 60;
  if (minutes < 60) return `${Math.floor(minutes)}분 전`;
  const hours = minutes / 60;
  if (hours < 24) return `${Math.floor(hours)}시간 전`;
  const days = hours / 24;
  if (days < 7) return `${Math.floor(days)}일 전`;
  const weeks = days / 7;
  if (weeks < 5) return `${Math.floor(weeks)}주 전`;
  const months = days / 30;
  if (months < 12) return `${Math.floor(months)}개월 전`;
  const years = days / 365;
  return `${Math.floor(years)}년 전`;
};

// 날짜를 입력받아서 원하는 형식으로 리턴한다.
exports.GetDateFormat = (date, format) => {
  if (!date) return null;
  const dt = new Date(date);
  const year = dt.getFullYear();
  const month = dt.getMonth() + 1;
  const day = dt.getDate();
  // const hour = dt.getHours();
  // const min = dt.getMinutes();
  // const sec = dt.getSeconds();
  if (format === 1) {
    // YYYY-MM-DD
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      day < 10 ? `0${day}` : `${day}`
    }`;
  } else if (format === 2) {
    // YYYY.MM.DD
    return `${year}.${month < 10 ? `0${month}` : `${month}`}.${
      day < 10 ? `0${day}` : `${day}`
    }`;
  } else if (format === 3) {
    // MM-DD
    return `${month < 10 ? `0${month}` : `${month}`}-${
      day < 10 ? `0${day}` : `${day}`
    }`;
  }
};

exports.MoveTo = (e, selectedCode, selectedLi, addToast, wrapId, direction) => {
  e.preventDefault();
  if (!selectedCode || !selectedLi) {
    addToast({
      type: "danger",
      text: "먼저 요소를 선택하세요.",
    });
    return;
  }
  const ul = document.getElementById(wrapId);
  if (direction === "top") {
    ul.insertBefore(selectedLi, ul.firstChild);
  } else if (direction === "up") {
    const wrapperParent = selectedLi.parentNode;
    const wrapperPervious = selectedLi.previousElementSibling;
    if (wrapperPervious)
      wrapperParent.insertBefore(selectedLi, wrapperPervious);
  } else if (direction === "down") {
    const wrapperParent = selectedLi.parentNode;
    const wrapperNext = selectedLi.nextElementSibling;
    if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedLi);
  } else if (direction === "bottom") {
    ul.insertBefore(selectedLi, null);
  }
};

// 세자리마다 콤마(,) 추가
exports.AddComma = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 만 나이 계산
exports.GetStandardAge = (date) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

// 오늘을 기준으로 일수 계산
exports.GetDaysDiff = (date) => {
  if (!date) return null;
  const dDay = new Date(date.toLocaleString());
  const today = new Date();
  const diff = dDay.getTime() - today.getTime();
  // 정수로 리턴
  // const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  // 디데이 기준으로 1을 더한다??
  // Math.floor : 소수점 이하를 버림
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};

// 오브젝트를 독립적으로 복사한다.
exports.DeepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * 공백, null, undefined, "NaN", "null", "undefined"을 체크한다.
 */
exports.IsValidValue = (value) => {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value?.toString() === "NaN" ||
    value?.toString() === "null" ||
    value?.toString() === "undefined"
  )
    return false;
  else return true;
};

exports.GoBoardPage = (
  navigate,
  isManagePage,
  group,
  category,
  searchText,
  page
) => {
  if (isManagePage) {
    if (group && category) {
      navigate(
        `/manage/board/${group}/${category}?searchText=${encodeURIComponent(
          searchText || ""
        )}&page=${page || ""}`
      );
    } else if (group) {
      navigate(
        `/manage/board/${group}?searchText=${encodeURIComponent(
          searchText || ""
        )}&page=${page || ""}`
      );
    } else {
      navigate(
        `/manage/board?searchText=${encodeURIComponent(
          searchText || ""
        )}&page=${page || ""}`
      );
    }
  } else {
    if (group && category) {
      navigate(
        `/board/${group}/${category}?searchText=${encodeURIComponent(
          searchText || ""
        )}&page=${page || ""}`
      );
    } else if (group) {
      navigate(
        `/board/${group}?searchText=${encodeURIComponent(
          searchText || ""
        )}&page=${page || ""}`
      );
    } else {
      navigate(
        `/board?searchText=${encodeURIComponent(searchText || "")}&page=${
          page || ""
        }`
      );
    }
  }
};

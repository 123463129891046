import { Link } from "react-router-dom";
import BlogList from "../../../components/blog/BlogList";

const AdminBlogPage = () => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2 className="h2">관리자용 전체 블로그</h2>
        <div>
          <Link to="/manage/blog/create" className="btn btn-success btn-md align-middle">
            새 블로그 작성
          </Link>
        </div>
      </div>
      <BlogList isManagePage={true} />
    </div>
  );
};

export default AdminBlogPage;

import hljs from "highlight.js";
import "highlight.js/styles/tokyo-night-light.css";
import moment from "moment";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useWindowSize from "../../hooks/useWindowSize";
import { addToast } from "../../store/toastSlice";
import { IsValidValue, PassDateEasyText } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import LoadingSpinner from "../LoadingSpinner";
import MsgBox from "../MsgBox";
import BoardLink from "./BoardLink";
import BoardSentence from "./BoardSentence";

const BoardShowComponent = ({
  isManagePage,
  isStoryPage,
  firstContent,
  isBoardDelete,
  setIsBoardDelete,
  boardId,
  setBoardId,
}) => {
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  // const boardId = searchParams.get("boardId");
  const searchText = searchParams.get("searchText");
  const page = searchParams.get("page");
  // const group = searchParams.get("group");
  // const category = searchParams.get("category");
  const { group, category } = useParams();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [board, setBoard] = useState(null);
  const [boardLinks, setBoardLinks] = useState([]);
  const [boardSentences, setboardSentences] = useState([]);
  const [codeInfos, setCodeInfos] = useState([{}]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isLineThroughTitle, setIsLineThroughTitle] = useState(null);
  const [isStrongTitle, setIsStrongTitle] = useState(null);
  const [titleColor, setTitleColor] = useState("");

  useEffect(() => {
    if (isBoardDelete) {
      setBoard(null);
      setBoardId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBoardDelete]);

  useEffect(() => {
    setError("");
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    hljs.highlightAll();
  });

  useEffect(() => {
    const loadBoard = async () => {
      // 관리자 페이지인데 로그인이 안되어 있을 경우
      if (isManagePage && !isLoggedIn) {
        setError("관리자 인증을 위한 로그인이 필요합니다.");
        return;
      }
      // [추후] 로그온 계정의 권한이 없을 경우도 체크해야 함!
      setBoard(null);
      setBoardLinks([]);
      setboardSentences([]);
      setCodeInfos([]);
      const id = boardId || firstContent;
      if (!id) return;
      let uri;
      if (isManagePage) {
        uri = `${apiServer}/board/${id}`;
      } else if (isStoryPage) {
        uri = `${apiServer}/storyBoardDetail/${id}`;
      } else {
        uri = `${apiServer}/publishBoard/${id}`;
      }
      await setIsLoading(true);
      await axiosHook
        .get(uri)
        .then((res) => {
          const data = res.data;
          if (data?.board) {
            setError("");
            setBoard(data.board || null);
            setBoardLinks(data.boardLinks);
            setboardSentences(data.boardSentences);
            setCodeInfos(data.codeInfos);
            setIsBoardDelete(false);
            // setBoardId(data.board._id);
            setIsLineThroughTitle(
              data.board.isLineThroughTitle === true ||
                data.board.isLineThroughTitle === "Y"
                ? true
                : false
            );
            setIsStrongTitle(
              data.board.isStrongTitle || data.board.isStrongTitle === "Y"
                ? true
                : false
            );
            setTitleColor(data.board.titleColor);
            if (data?.board?.title)
              window.document.title = data?.board?.title + " | 두니넷";
          } else {
            if (!isBoardDelete) {
              setError("게시글이 존재하지 않거나 비공개 상태입니다.");
              setIsBoardDelete(true);
            }
          }
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    loadBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardId, isManagePage, isStoryPage, firstContent, apiServer]);

  const gotoList = () => {
    let uri = isManagePage ? `/manage/board` : `/board`;
    if (group) {
      uri += `/${group}`;
      if (category) {
        uri += `/${category}`;
      }
    }
    const searchQuery = `searchText=${encodeURIComponent(
      searchText || ""
    )}&page=${page || 1}`;
    window.location.href = `${uri}?${searchQuery}`;
  };

  const handleBoardDelete = () => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    setIsLoading(true);
    axiosHook
      .delete(`${apiServer}/board/delete`, {
        data: { _id: boardId, boardNo: board?.boardNo },
      })
      .then((res) => {
        if (res.data.ok) {
          gotoList();
        } else {
          addToast({
            text: "삭제에 실패하였습니다.",
            type: "danger",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        addToast({
          text: "삭제에 실패하였습니다.",
          type: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  const showUri = `/board/${board?.gCode}/${board?.cCode}?boardId=${board?._id}`;

  return (
    <div className={`border shadow p-4 mb-4 rounded ${board ? "" : "d-none"}`}>
      <div
        className={`row mb-2 ${
          isStoryPage || !codeInfos || !codeInfos?.length ? "d-none" : ""
        }`}
      >
        <div className="col-auto">
          <Link
            to={
              isManagePage
                ? `/manage/board/${codeInfos[0]?.code}`
                : `/board/${codeInfos[0]?.code}`
            }
          >
            {codeInfos[0]?.codeName}
          </Link>
        </div>
        <div className="col-auto mx-0  px-0">
          <span
            className={`${IsValidValue(codeInfos[1]?.code) ? "" : "d-none"}`}
          >
            &gt;
          </span>
        </div>
        <div className="col-auto">
          <Link
            to={
              isManagePage
                ? `/manage/board/${codeInfos[0]?.code}/${codeInfos[1]?.code}`
                : `/board/${codeInfos[0]?.code}/${codeInfos[1]?.code}`
            }
          >
            {codeInfos[1]?.codeName}
          </Link>
        </div>
      </div>
      <div className={`py-2 ${width > 768 ? "d-flex" : ""}`}>
        <h2
          className={`${width > 768 ? "h2 flex-grow-1" : ""} ${
            isLineThroughTitle ? "text-decoration-line-through" : ""
          } ${isStrongTitle ? "fw-bold" : ""}`}
          style={{ color: titleColor }}
        >
          {board?.title}
        </h2>
        <div className="d-flex justify-content-end">
          <div className={isStoryPage ? "d-none" : ""}>
            <button className="btn btn-secondary" onClick={gotoList}>
              목록
            </button>
          </div>
          &nbsp;
          {/** 추후에 roles 관리자 권한 유무로 변경해야 함
           * 완료시까지는 로그인 여부로만 체크 및 회원가입 차단!!
           */}
          {isLoggedIn && (
            <>
              <div className="me-1">
                <Link
                  to={`/manage/board/edit/${
                    board?._id
                  }?group=${group}&category=${category}&searchText=${encodeURIComponent(
                    `${searchText || ""}`
                  )}&page=${page || ""}`}
                  className="btn btn-primary w-max-content"
                >
                  수정
                </Link>
              </div>
              <div>
                <button className="btn btn-danger" onClick={handleBoardDelete}>
                  삭제
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <small className={`text-muted`}>
        {board?.publishedAt
          ? `발행일시: ${moment(board?.publishedAt).format(
              "lll"
            )} (${PassDateEasyText(new Date(board?.publishedAt))})`
          : `최초작성: ${moment(board?.createdAt).format(
              "lll"
            )} (${PassDateEasyText(new Date(board?.createdAt))})`}
        <br />
        {`최종수정: ${moment(board?.updatedAt).format(
          "lll"
        )} (${PassDateEasyText(new Date(board?.updatedAt))})`}
      </small>
      {/* <p className={`${board?.isBlogPublish ? "" : "d-none"}`}> */}
      <p>
        <small className="text-muted">
          주소:&nbsp;
          <Link to={showUri} target="_blank" style={{ wordBreak: "break-all" }}>
            {window.location.origin}
            {showUri}
          </Link>
        </small>
      </p>
      <div
        className={`border rounded shadow p-3 my-4 ${
          board?.content ? "" : "d-none"
        }`}
      >
        <pre dangerouslySetInnerHTML={{ __html: board?.content }}></pre>
      </div>
      <div className={boardSentences?.length ? `` : `d-none`}>
        <hr />
        {boardSentences?.map((file) => {
          return (
            <BoardSentence
              key={file._id}
              _id={file._id}
              fileFolder={file.fileFolder}
              fileName={file.fileName}
              fileExplain={file.fileExplain}
              fileStorageType={file.fileStorageType}
              fileTitle={file.fileTitle}
              folderOrgName={file.folderOrgName}
              isManagePage={isManagePage}
            />
          );
        })}
      </div>
      <div className={boardLinks?.length ? `` : `d-none`}>
        <hr />
        <h3>참고 링크</h3>
        <ol>
          {boardLinks?.map((link) => (
            <BoardLink key={link._id} title={link.title} link={link.link} />
          ))}
        </ol>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default BoardShowComponent;

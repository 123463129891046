import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner.js";
import MsgBox from "../../../components/MsgBox.js";
import BoardCategories from "../../../components/board/BoardCategories.js";
import BoardGroups from "../../../components/board/BoardGroups.js";
import BoardList from "../../../components/board/BoardList.js";
import BoardSearchForm from "../../../components/board/BoardSearchForm.js";
import BoardShowComponent from "../../../components/board/BoardShowComponent.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate.js";
import useToast from "../../../hooks/useToast.js";
import useWindowSize from "../../../hooks/useWindowSize.js";

const ManageBoardListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [boardName, setBoardName] = useState("관리자용 전체 목록");
  const { group, category } = useParams();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [currentPage, setCurrentPage] = useState(1);
  // 페이징은 스터디 방식과 다르기에 추후에 필요할 때 커스텀 하기
  // 검색도 함께 연동되므로 추후에 필요할 때 커스텀 하기
  const limit = 5;
  const [numberOfBoards, setNumberOfBoards] = useState(0);
  const [boards, setBoards] = useState([]);
  const { addToast } = useToast();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(() =>
    searchParams.get("searchText")
  );
  const [boardId, setBoardId] = useState(() => searchParams.get("boardId"));
  const { width } = useWindowSize();
  const [isBoardDelete, setIsBoardDelete] = useState(null);

  const getBoards = useCallback(
    (page) => {
      let uri = ``;
      if (group && category) {
        uri = `${apiServer}/boards/${group}/${category}`;
      } else if (group) {
        uri = `${apiServer}/boards/${group}`;
      } else {
        uri = `${apiServer}/boards`;
      }
      const params = {
        page,
        searchText,
        limit,
        isManagePage: true,
      };
      setIsLoading(true);
      axiosHook
        .get(uri, { params })
        .then((res) => {
          setNumberOfBoards(res.data.totalCount);
          // boardId 를 제외한 res.data.pageBoards 를 setBoards 한다.
          // boardId 는 따로 setBoard 로 처리한다.
          const pageBoards = res.data.pageBoards || [];
          setBoards(pageBoards.filter((b) => b.id !== boardId));
          // boardId 초기화
          if (boardId) {
            setIsBoardDelete(false);
            const board = pageBoards.find((b) => b.id === boardId);
            setBoardName(board?.title || "관리자용 전체 목록");
          }
        })
        .catch(() => {
          setError("Something went wrong in database");
          addToast({
            text: "Something went wrong",
            type: "danger",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, // , addToast: 무한루프?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, apiServer, group, category]
  );

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <div
        className={`d-flex mb-2 ${
          width > 768 ? "justify-content-between" : "row"
        }`}
      >
        <h2 className="h2">{boardName}</h2>
        <div className="d-flex justify-content-end"
          style={{ margin: "inherit" }}
        >
          <Link
            to={`/manage/board/create?group=${group || ""}&category=${
              category || ""
            }`}
            className="btn btn-success btn-md"
          >
            새 게시물 등록
          </Link>
        </div>
      </div>
      <BoardSearchForm
        searchText={searchText}
        setSearchText={setSearchText}
        setCurrentPage={setCurrentPage}
        getBoards={getBoards}
      />
      <h4>그룹</h4>
      <nav className="navbar navbar-light bg-light">
        <div className="container">
          <BoardGroups
            isManagePage={true}
            group={group}
            category={category}
            setIsLoading={setIsLoading}
            setBoardName={setBoardName}
          />
        </div>
      </nav>
      <div className={group ? "mt-3" : "mt-3 d-none"}>
        <h4>카테고리</h4>
        <nav className="navbar navbar-light bg-light">
          <div className="container">
            <BoardCategories
              isManagePage={true}
              fDirection="row"
              group={group}
              category={category}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setBoardName={setBoardName}
            />
          </div>
        </nav>
      </div>
      <hr />
      <BoardShowComponent
        isManagePage={true}
        isBoardDelete={isBoardDelete}
        setIsBoardDelete={setIsBoardDelete}
        setBoardId={setBoardId}
        boardId={boardId}
      />
      <BoardList
        boardId={boardId}
        setBoardId={setBoardId}
        isManagePage={true}
        group={group}
        category={category}
        getBoards={getBoards}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        limit={limit}
        numberOfBoards={numberOfBoards}
        boards={boards}
        setBoards={setBoards}
        searchText={searchText}
        setIsBoardDelete={setIsBoardDelete}
      />
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default ManageBoardListPage;

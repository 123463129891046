import moment from "moment";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "../../api/axios";
import { AddComma, PassDateEasyText } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const ViewAssetModal = ({ show, setShow, walletId, setWalletId }) => {
  const [isLoading] = useState(false);
  const [error] = useState("");
  const [modalTitle] = useState("상세 내역");
  const [assetNo, setAssetNo] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [name, setName] = useState("");
  const [assetDate, setAssetDate] = useState("");
  const [memo, setMemo] = useState("");
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    setAssetNo("");
    setName("");
    setAssetDate("");
    setAmount("");
    setMemo("");
    setTotalAmount("");
    if (!walletId) return;
    axios
      .get(`${apiServer}/asset/${walletId}`)
      .then((res) => {
        setAssetNo(res.data.assetNo);
        setName(res.data.name);
        setAssetDate(res.data.assetDate);
        setAmount(res.data.amount);
        setTotalAmount(res.data.totalAmount);
        setMemo(res.data.memo);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [apiServer, walletId]);

  const resetStates = () => {
    setShow(false);
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <Modal centered show={show} animation={false} onHide={resetStates}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="text-end">일련번호</td>
                    <td>{assetNo}</td>
                  </tr>
                  <tr>
                    <td className="text-end">이름</td>
                    <td>{name}</td>
                  </tr>
                  <tr>
                    <td className="text-end">일시</td>
                    <td>
                      {`${moment(assetDate).format("YYYY-MM-DD dddd")}
                  (${PassDateEasyText(new Date(assetDate))})`}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end">금액</td>
                    <td
                      className={`${
                        amount < 0 ? "text-danger" : "text-primary"
                      }`}
                    >
                      {AddComma(amount)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end">잔액</td>
                    <td>{AddComma(totalAmount)}</td>
                  </tr>
                  <tr>
                    <td className="text-end">메모</td>
                    <td style={{ textWrap: "pretty" }}>{memo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <button className="btn btn-outline-danger col-12" type="button">
            삭제
          </button>
        </Modal.Footer> */}
        </form>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default ViewAssetModal;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Footer from "./components/Footer";
import GotoScrollIcon from "./components/GotoScrollIcon";
import Toast from "./components/Toast";
import ManageNavBar from "./components/menu/ManageNavBar";
import PublicNavBar from "./components/menu/PublicNavBar";
import useRefreshToken from "./hooks/useRefreshToken";
import useToast from "./hooks/useToast";
import NotFoundPage from "./pages/NotFoundPage";
import TokenRenewPage from "./pages/TokenRenewPage";
import GoogleAuthPage from "./pages/auth/GoogleAuthPage";
import LoginPage from "./pages/auth/LoginPage";
import LogoutPage from "./pages/auth/LogoutPage";
import StoryAuthPage from "./pages/auth/StoryAuthPage";
import WalletAuthPage from "./pages/auth/WalletAuthPage";
import ListPage from "./pages/blog/ListPage";
import BoardListPage from "./pages/board/BoardListPage";
import BoardShowPage from "./pages/board/BoardShowPage";
import AdminBlogPage from "./pages/manage/blog/AdminListPage";
import CreatePage from "./pages/manage/blog/CreatePage";
import EditPage from "./pages/manage/blog/EditPage";
import ShowPage from "./pages/manage/blog/ShowPage";
import BoardCreatePage from "./pages/manage/board/BoardCreatePage";
import BoardEditPage from "./pages/manage/board/BoardEditPage";
import ManageBoardListPage from "./pages/manage/board/ManageBoardListPage";
import CodeManagePage from "./pages/manage/code/CodeManagePage";
import InvestHomePage from "./pages/manage/invest/InvestHomePage";
import InvestIndicatorPage from "./pages/manage/invest/MarketIndicatorPage";
import StockViewPage from "./pages/manage/invest/StockViewPage";
import ProjectListDocPage from "./pages/manage/project/ProjectListDocPage";
import ProjectListTablePage from "./pages/manage/project/ProjectListTablePage";
import StoryFormPage from "./pages/manage/story/StoryFormPage";
import StoryHomePage from "./pages/manage/story/StoryHomePage";
import HomePage from "./pages/project/HomePage";
import StoryListPage from "./pages/story/StoryListPage";
import StoryViewPage from "./pages/story/StoryViewPage";
import WalletViewPage from "./pages/wallet/WalletViewPage";
import { IsValidValue } from "./util/common";

const BlankLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const BoardLayout = () => {
  return (
    <>
      <div>
        <PublicNavBar />
      </div>
      <div className="container">
        <div className="row">
          <div className="col p-3 m-3 border overflow-scroll">
            <Outlet />
          </div>
        </div>
        <Footer />
        <GotoScrollIcon />
      </div>
    </>
  );
};

const ManageLayout = () => {
  const accessToken = localStorage.getItem("dnnAt");
  // let newAccessToken = "";
  const refresh = useRefreshToken();
  const [loading, setLoading] = useState(true);

  const renewAccessToken = async () => {
    // await setLoading(true);
    try {
      await refresh();
      // const rf = await refresh();
      // newAccessToken = rf?.newAccessToken;
      // // if (
      //   !IsValidValue(newAccessToken) ||
      //   newAccessToken === "notCreatedToken"
      // ) {
      //   window.location.href = "/logout";
      //   return;
      // }
    } catch (err) {
      console.error(err);
      window.location.href = "/logout"; 
      // return;
    } finally {
      await setLoading(false);
    }
  };

  useEffect(() => {
    if (IsValidValue(accessToken)) {
      renewAccessToken();
    } 
    else { // 없으면 화면이 멈춤.
      window.location.href = "/logout";
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <></>;

  if (!IsValidValue(accessToken)) {
      window.location.href = "/logout";
      return;
  } 

  return (
    <>
      <div>
        <ManageNavBar />
      </div>
      <div className="container">
        <div className="row">
          <div className="col p-3 m-3 border overflow-scroll">
            <Outlet />
          </div>
        </div>
        <Footer />
        <GotoScrollIcon />
      </div>
    </>
  );
};

function App() {
  const toasts = useSelector((state) => state.toast.toasts);
  const { deleteToast } = useToast();

  return (
    <>
      <Toast toasts={toasts} deleteToast={deleteToast} />
      <Router>
        <Routes>
          <Route path="/" element={<BlankLayout />}>
            {/***************** 공백 페이지 */}
            <Route index element={<HomePage />} />
            <Route path="story/:storyOpenId" element={<StoryViewPage />} />
            <Route path="wallet/:walletOpenId" element={<WalletViewPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="googleAuth" element={<GoogleAuthPage />} />
            <Route path="walletAuth" element={<WalletAuthPage />} />
            <Route path="storyAuth/:storyOpenId" element={<StoryAuthPage />} />
            <Route path="tokenRenew" element={<TokenRenewPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Route>
          <Route path="/" element={<BoardLayout />}>
            {/***************** 공개 페이지 */}
            <Route path="blog" element={<ListPage />} />
            <Route path="blog/view/:_id" element={<ShowPage />} />
            <Route path="board" element={<BoardListPage />} />
            <Route path="story" element={<StoryListPage />} />
            <Route path="board/:group" element={<BoardListPage />} />
            <Route path="board/:group/:category" element={<BoardListPage />} />
            <Route path="board/view/:boardId" element={<BoardShowPage />} />
          </Route>
          <Route path="/manage/" element={<ManageLayout />}>
            {/***************** 관리자 페이지 */}
            <Route path="code" element={<CodeManagePage />} />
            <Route path="blog" element={<AdminBlogPage />} />
            <Route path="board" element={<ManageBoardListPage />} />
            <Route path="story" element={<StoryHomePage />} />
            <Route path="invest" element={<InvestHomePage />} />
            <Route path="marketIndicator" element={<InvestIndicatorPage />} />
            <Route path="blog/create" element={<CreatePage />} />
            <Route path="board/create" element={<BoardCreatePage />} />
            <Route path="storyAdd" element={<StoryFormPage />} />
            <Route path="project/doc" element={<ProjectListDocPage />} />
            <Route path="project/table" element={<ProjectListTablePage />} />
            <Route path="storyAdd/:groupIdParam" element={<StoryFormPage />} />
            <Route path="blog/edit/:_id" element={<EditPage />} />
            <Route path="board/edit/:_id" element={<BoardEditPage />} />
            <Route path="storyEdit/:_id" element={<StoryFormPage />} />
            <Route
              path="blog/view/:_id"
              element={<ShowPage isManagePage={true} />}
            />
            <Route path="board/:group" element={<ManageBoardListPage />} />
            <Route
              path="board/:group/:category"
              element={<ManageBoardListPage />}
            />
            <Route path="stock/:ticker" element={<StockViewPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;

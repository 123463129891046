import BlogForm from "../../../components/blog/BlogForm";

const EditPage = () => {
  return (
    <div>
      <BlogForm editing={true} />
    </div>
  );
};

export default EditPage;

import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { MsgBox } from "../MsgBox";
import LoadingPopSpinner from '../LoadingPopSpinner';

const BlogForm = ({ editing }) => {
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const navigate = useNavigate();
  const { _id } = useParams();
  const [title, setTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [body, setBody] = useState("");
  const [originalBody, setOriginalBody] = useState("");
  const [publish, setPublish] = useState(false);
  const [originalPublish, setOriginalPublish] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (editing) {
      axiosHook
        .get(`${apiServer}/post/${_id}`)
        .then((res) => {
          setTitle(res.data.title);
          setOriginalTitle(res.data.title);
          setBody(res.data.body);
          setOriginalBody(res.data.body);
          setPublish(res.data.publish);
          setOriginalPublish(res.data.publish);
          setIsLoading(false);
        })
        .catch(() => {
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    // addToast dependency 추가하면 초기화 됨
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, _id, apiServer]);

  const isEdited = () => {
    return (
      title !== originalTitle ||
      body !== originalBody ||
      publish !== originalPublish
    );
  };

  const goBack = () => {
    if (editing) {
      navigate(`/manage/blog/view/${_id}`);
    } else {
      navigate("/manage/blog");
    }
  };

  const validateForm = () => {
    let validated = true;

    if (title === "") {
      setTitleError(true);
      validated = false;
    }

    if (body === "") {
      setBodyError(true);
      validated = false;
    }

    return validated;
  };

  const onSubmit = () => {
    setTitleError(false);
    setBodyError(false);
    if (validateForm()) {
      if (editing) {
        axiosHook
          .put(`${apiServer}/post/edit`, {
            _id,
            title,
            body,
            publish,
          })
          .then((res) => {
            addToast({
              type: "success",
              text: "Successfully updated!",
            });
            navigate(`/blog/view/${_id}`);
          })
          .catch((e) => {
            addToast({
              type: "danger",
              text: "We could not update blog",
            });
          });
      } else {
        axiosHook
          .post(`${apiServer}/post/add`, {
            title,
            body,
            publish,
          })
          .then(() => {
            addToast({
              type: "success",
              text: "Successfully created!",
            });
            navigate("/manage/blog");
          })
          .catch((e) => {
            addToast({
              type: "danger",
              text: "We could not create blog",
            });
          });
      }
    }
  };

  const onChangePublish = (e) => {
    setPublish(e.target.checked);
  };

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <h2 className="h2">{editing ? "Edit" : "Create"} a blog post</h2>
      <div className="mb-3">
        <label className="form-label">Title</label>
        <input
          className={`form-control ${titleError ? "border-danger" : ""}`}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        />
        {titleError && <div className="text-danger">Title is required.</div>}
      </div>
      <div className="mb-3">
        <label className="form-label">Body</label>
        <textarea
          className={`form-control ${bodyError ? "border-danger" : ""}`}
          value={body}
          onChange={(event) => {
            setBody(event.target.value);
          }}
          rows="10"
        />
        {bodyError && <div className="text-danger">Body is required.</div>}
      </div>
      <div className="form-check mb-3">
        <input
          id="publish"
          className="form-check-input"
          type="checkbox"
          checked={publish}
          onChange={onChangePublish}
        />
        <label htmlFor="publish" className="form-check-label">
          Publish
        </label>
      </div>

      <button
        className="btn btn-primary"
        onClick={onSubmit}
        disabled={editing && !isEdited()}
      >
        {editing ? "Save" : "Post"}
      </button>
      <button className="btn btn-danger ms-2" onClick={goBack}>
        Cancel
      </button>
    </div>
  );
};

BlogForm.propTypes = {
  editing: propTypes.bool,
};

BlogForm.defaultProps = {
  editing: false,
};

export default BlogForm;

import { useState } from "react";
import BoardForm from "../../../components/board/BoardForm";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useToast from "../../../hooks/useToast";

const BoardEditPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const axiosHook = useAxiosPrivate();
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const [selectSentence, setSelectSentence] = useState(null);

  const moveTo = async (e) => {
    e.preventDefault();
    const selectedEl = e.target.closest(".dummy-sentence-class");
    setSelectSentence(selectedEl);
    if (!selectedEl) {
      addToast({
        type: "danger",
        text: "먼저 이동할 문장을 클릭하세요.",
      });
      return;
    }
    const rootEl = e.target.closest(".dummy-sentence-root");
    const direction = e.target.closest("button").getAttribute("data-direction");
    if (direction === "top") {
      rootEl?.insertBefore(selectedEl, rootEl?.firstChild);
    } else if (direction === "up") {
      const wrapperParent = selectedEl.parentNode;
      const wrapperPervious = selectedEl.previousElementSibling;
      if (wrapperPervious)
        wrapperParent.insertBefore(selectedEl, wrapperPervious);
    } else if (direction === "down") {
      const wrapperParent = selectedEl.parentNode;
      const wrapperNext = selectedEl.nextElementSibling;
      if (wrapperNext) wrapperParent.insertBefore(wrapperNext, selectedEl);
    } else if (direction === "bottom") {
      rootEl?.insertBefore(selectedEl, null);
    }

    // 변경 된 순번 저장
    const sentences = [];
    const dvSentences = rootEl.querySelectorAll("div.dummy-sentence-class");
    let idx = 0;
    await dvSentences.forEach((div) => {
      sentences.push({
        _id: div.dataset["sentence"],
        sortNo: idx,
      });
      parseInt(idx++);
    });
    const url = `${apiServer}/sentence/edit/sort`;
    await setIsLoading(true);
    await axiosHook
      .put(url, {
        sentences,
      })
      .then((res) => {
        if (res.data === "ok") {
          addToast({
            type: "success",
            text: "순서가 저장되었습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: "순서 저장에 실패하였습니다.",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          type: "danger",
          text: "순서 저장에 실패하였습니다.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    setTimeout(() => {
      document
        .querySelectorAll(
          "#root > div.container div.dummy-sentence-root.mt-3 > div"
        )
        .forEach((el) => {
          if (
            el.getAttribute("data-sentence") ===
            selectedEl.getAttribute("data-sentence")
          ) {
            el.classList.add("border-5");
            // el로 이동
            el.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          } else {
            el.classList.remove("border-5");
            el.classList.add("border-1");
          }
        });
    }, 1000);
  };

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  return (
    <div>
      <BoardForm
        editing={true}
        moveTo={moveTo}
        selectSentence={selectSentence}
      />
    </div>
  );
};

export default BoardEditPage;

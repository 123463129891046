import { useLocation, useNavigate } from "react-router-dom";

const BoardSearchForm = ({
  searchText,
  setSearchText,
  setCurrentPage,
  getBoards,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onKeyupSearch = (e) => {
    e.preventDefault();
    if (e.key === "Enter") onSearch(e);
  };

  const onSearch = (e) => {
    e.preventDefault();
    navigate(`${location.pathname}?page=1`);
    setCurrentPage(1);
    getBoards(1, searchText);
  };

  return (
    <>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="검색어를 입력하세요."
          value={searchText || ""}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyUp={onKeyupSearch}
        />
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={onSearch}
        >
          검색
        </button>
      </div>
      <hr />
    </>
  );
};

export default BoardSearchForm;

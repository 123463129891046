import { useState } from "react";
import CodeForm from "../../../components/code/CodeForm";
import CodeSort from "../../../components/code/CodeSort";
import CodeTree from "../../../components/code/CodeTree";
import "./code.css";

const CodeManagePage = () => {
  const [code, setCode] = useState("");
  const [parentCode, setParentCode] = useState("");
  const [isCodeChanged, setIsCodeChanged] = useState(false);

  return (
    <div>
      {/* <h2 className="h2">코드 관리</h2> */}
      <div className="row">
        <div
          className="col py-3 border overflow-scroll text-truncate"
          style={{
            height: "75vh",
            maxHeight: "75vh",
            backgroundColor: "#ebe7e7",
          }}
        >
          <CodeTree
            code={code}
            setCode={setCode}
            parentCode={parentCode}
            setParentCode={setParentCode}
            isCodeChanged={isCodeChanged}
            setIsCodeChanged={setIsCodeChanged}
          />
        </div>
        <div
          className="col-lg-3 py-3 col-sm-12 border overflow-y-scroll"
          style={{
            height: "75vh",
            maxHeight: "75vh",
            backgroundColor: "rgb(219 208 218)",
          }}
        >
          <CodeSort
            code={code}
            isCodeChanged={isCodeChanged}
            setIsCodeChanged={setIsCodeChanged}
          />
        </div>
        <div
          className="col-lg-4 py-3 col-sm-12 border overflow-y-scroll"
          style={{
            height: "75vh",
            maxHeight: "75vh",
            backgroundColor: "#e6e5e0",
          }}
        >
          <CodeForm
            codeParam={code}
            setCodeParam={setCode}
            parentCodeParam={parentCode}
            setParentCodeParam={setParentCode}
            isCodeChanged={isCodeChanged}
            setIsCodeChanged={setIsCodeChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default CodeManagePage;

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import useToast from "../../hooks/useToast";

const StoryAuthPage = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [passErrMsg, setPassErrMsg] = useState("");
  const [pass, setPass] = useState("");
  const apiDomain = process.env.REACT_APP_API_DOAMIN;
  const navigate = useNavigate();
  const { storyOpenId } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    setPassErrMsg("");
    setIsLoading(true);
    // 세션 스토리지에 jwt 인증정보를 storyAuthToken 로 저장
    try {
      const data = {
        storyOpenId,
        pass,
      };
      const uri = `${apiDomain}/storyAuth`;
      axios.post(uri, data).then((res) => {
        if (res.data.ok) {
          sessionStorage.setItem("storyAuthToken", res.data.result);
          addToast({
            type: "success",
            text: "인증 되었습니다.",
          });
          navigate("/story/" + storyOpenId);
        } else {
          setPassErrMsg(res.data.result || "인증에 실패했습니다.");
          addToast({
            type: "danger",
            text: res.data.result || "인증에 실패했습니다.",
          });
        }
      });
    } catch (err) {
      setPassErrMsg(err.message || "인증에 실패했습니다.");
      addToast({
        type: "danger",
        text: err.message || "인증에 실패했습니다.",
      });
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="col-10 col-sm-6 col-md-5 col-lg-4 p-5 shadow-sm border rounded-5 border-primary">
        <form>
          <ul className={`text-danger`}>
            <li className={`${passErrMsg ? "" : "d-none"}`}>{passErrMsg}</li>
          </ul>
          <div className="mb-4">
            <input
              type="password"
              className={`form-control bg-info bg-opacity-10 border border-primary ${
                passErrMsg ? "border-danger" : ""
              }`}
              placeholder="비밀번호를 입력하세요."
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <div className="d-grid">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}
            >
              인 증
            </button>
          </div>
        </form>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default StoryAuthPage;

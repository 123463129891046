import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingPopSpinner from "../../../components/LoadingPopSpinner";
import MsgBox from "../../../components/MsgBox.js";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useWindowSize from "../../../hooks/useWindowSize";

const ShowPage = () => {
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [error, ] = useState("");
  const axiosHook = useAxiosPrivate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { _id } = useParams();
  const [post, setPost] = useState(null);

  const getPost = useCallback(() => {
    axiosHook
      .get(`${process.env.REACT_APP_API_DOAMIN}/post/${_id}`)
      .then((response) => {
        setPost(response.data);
        if (response.data?.title)
          window.document.title = response.data?.title + " | 두니넷";
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, _id]);

  const printDate = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return date?.toLocaleString();
  };

  useEffect(() => {
    getPost(_id);
  }, [_id, getPost]);

  if (!post) {
    return <div>Post not found</div>;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div className={`${width > 768 ? "d-flex" : ""}`}>
        <h2 className={`${width > 768 ? "h2 flex-grow-1" : ""}`}>
          {post?.title}
        </h2>
        {isLoggedIn && (
          <div className="d-flex justify-content-end">
            <Link
              to={`/manage/blog/edit/${post?._id}`}
              className="btn btn-primary"
            >
              Edit
            </Link>
          </div>
        )}
      </div>
      <small className="text-muted">
        Created At : {printDate(post?.createdAt)}
      </small>
      <hr />
      <p>{post?.body}</p>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default ShowPage;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import MsgBox from "../MsgBox";

const BoardCategories = ({
  fDirection,
  isManagePage,
  group,
  category,
  setIsLoading,
  setBoardName,
}) => {
  const axiosHook = useAxiosPrivate();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    const getCategories = async () => {
      await setCategories([]);
      if (!group) return;
      const uri = isManagePage
        ? `${apiServer}/manage/${group}/childCodes`
        : `${apiServer}/${group}/childCodes`;
      await setIsLoading(true);
      await axiosHook
        .get(uri)
        .then((res) => {
          setCategories(res.data || []);
        })
        .catch((e) => {
          console.error(e);
          setError("something went wrong in db");
          addToast({
            type: "danger",
            text: "something went wrong in db",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, isManagePage, axiosHook, apiServer]);

  useEffect(() => {
    if (!categories) {
      return;
    }
    if (!group && !category) {
      setBoardName(isManagePage ? "관리자용 전체 목록" : "전체 목록");
      return;
    }
    // 게시판 명 가져오기
    categories?.forEach((c) => {
      if (c.code === category) {
        setBoardName(c.codeName);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, categories, category, isManagePage]);

  useEffect(() => {
    setTimeout(() => {
      // active 클래스가 있는 링크로 스크롤 이동
      const activeLink = document.querySelector(
        "#ulBoardCategories .nav-link.active"
      );
      if (activeLink) {
        activeLink.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 1000);
  }, [category]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <ul
        id="ulBoardCategories"
        style={{
          flexDirection: "row",
          overflowX: "auto",
        }}
        className="navbar-nav navbar-nav-scroll one-line-sentence"
      >
        {!categories?.length ? (
          <div>No categories</div>
        ) : (
          categories?.map((category) => (
            <li className="me-4" key={category.code}>
              <NavLink
                className={({ isActive }) =>
                  "nav-link " + (isActive ? "active fw-bold" : "")
                }
                to={
                  isManagePage
                    ? `/manage/board/${group}/${category.code}`
                    : `/board/${group}/${category.code}`
                }
              >
                <span
                  className={
                    category.useStatus === "USN" || category.useStatus === "USD"
                      ? "text-decoration-line-through"
                      : ""
                  }
                >
                  {category.codeName}
                </span>
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </>
  );
};

export default BoardCategories;

import BoardForm from "../../../components/board/BoardForm";

const BoardCreatePage = () => {
  return (
    <div>
      <BoardForm />
    </div>
  );
};

export default BoardCreatePage;

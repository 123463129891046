import propTypes from "prop-types";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const ProjectList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [projects, setProjects] = useState([]);
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  let addCount = 0;

  useEffect(() => {
    setError("");
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOAMIN}/project`)
      .then((res) => {
        setNumberOfProjects(res.data.length);
        setProjects(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError("Something went wrong in database");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <article id="mainArticle">
      <div className="container">
        <h2>수행 프로젝트</h2>
        <p style={{ marginBottom: "1rem" }}>
          * 진행 중인 프로젝트는 프로젝트 종료 후에 추가됩니다.
        </p>
        <ul style={{ minHeight: "80vh", paddingLeft: 0 }}>
          {projects?.map((project) => {
            const idx = numberOfProjects - addCount;
            addCount++;

            return (
              <li
                key={idx}
                className="liArticle"
                style={{
                  border: "1px solid rgb(203 210 212)",
                  verticalAlign: "top",
                  height: "180px",
                  overflowY: "auto",
                  whiteSpace: "inherit",
                  margin: "1.2rem",
                }}
              >
                <p style={{ marginBottom: "0.7rem" }}>
                  <span className="pe-1">[{idx}]</span>
                  <span style={{ fontWeight: "bold", lineHeight: "140%" }}>
                    {project.work_company} - {project.name}
                  </span>
                </p>
                {/* backgroundImage 사용하면 한글이나 특수문자 등에 문제가 있어서 img 태그로 대체 */}
                <img
                  src={project.img}
                  alt={project.name}
                  style={{
                    marginBottom: "0.5rem",
                    border: "1px solid rgb(193 191 191)",
                    borderRadius: "5px",
                    width: "95%",
                    height: "auto",
                    maxHeight: "120px",
                    objectFit: "cover",
                    overflow: "hidden",
                  }}
                />

                {project.website && (
                  <div style={{ marginBottom: "0.7rem" }}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={project.website}
                      style={{
                        fontStyle: "italic",
                        overflowWrap: "break-word",
                        color: "rgb(16 114 238)",
                        fontSize: "80%",
                      }}
                    >
                      {project.website}
                    </a>
                  </div>
                )}
                {project.desc && (
                  <div
                    style={{
                      color: "gray",
                      lineHeight: "150%",
                      fontSize: "80%",
                    }}
                  >
                    {project.desc}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </article>
  );
};

ProjectList.propTypes = {
  isManagePage: propTypes.bool,
};

ProjectList.defaultProps = {
  isManagePage: false,
};

export default ProjectList;

import { faBlog, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { IsValidValue } from "../util/common";

const Card = ({
  isShowing,
  title,
  searchText,
  onClick,
  children,
  isManagedPage,
  isBlogPublish,
  isStoryPublish,
  isLineThroughTitle,
  isStrongTitle,
  titleColor,
  content,
}) => {
  const contentText = IsValidValue(content)
    ? content?.replace(/(<([^>]+)>)/gi, "").substring(0, 100)
    : "";
  const isShowingHtml = isShowing
    ? '<span class="text-bg-danger">[열람중]</span>&nbsp;'
    : "";
  const markedTitle = searchText
    ? isShowingHtml +
      title
        .toLowerCase()
        .replace(searchText?.toLowerCase(), `<mark>${searchText}</mark>`) +
      '<br /><small style="color:#111; font-weight: lighter">' +
      contentText +
      "</small>"
    : isShowingHtml +
      title +
      '<br /><small style="color:#111; font-weight: lighter">' +
      contentText +
      "</small>";
  const blogPublishIcon =
    isManagedPage && isBlogPublish ? (
      <FontAwesomeIcon
        icon={faBlog}
        className="me-2"
        style={{ color: "rgb(187 100 170)" }}
      />
    ) : (
      ""
    );
  const storyPublishIcon =
    isManagedPage && isStoryPublish ? (
      <FontAwesomeIcon
        icon={faBookOpen}
        className="me-2"
        style={{ color: "rgb(137 192 185)" }}
      />
    ) : (
      ""
    );
  return (
    <div
      className={`card mb-3 cursor-pointer ${
        isManagedPage && (isBlogPublish || isStoryPublish) ? "border-5" : ""
      }
      ${isShowing ? "text-bg-warning" : ""}`}
      onClick={onClick}
    >
      <div className="card-body py-2 d-flex align-items-center">
        {blogPublishIcon}
        {storyPublishIcon}
        <div
          className={`one-line-sentence-cut flex-grow-1 ${
            isLineThroughTitle === true ? "text-decoration-line-through" : ""
          } ${isStrongTitle === true ? "fw-bold" : ""}`}
          style={{ color: titleColor }}
          dangerouslySetInnerHTML={{ __html: markedTitle }}
        ></div>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  children: null,
  onClick: () => {},
};

export default Card;

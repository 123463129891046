import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authSlice";
import investCodeReducer from "./investCodeSlice";
import investReducer from "./investSlice";
import toastReducer from "./toastSlice";

// export const store = configureStore({
//   reducer: {
//     toast: toastReducer,
//     auth: authReducer,
//     invest: investReducer,
//     investCode: investCodeReducer,
//   },
// });

const reducers = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  invest: investReducer,
  investCode: investCodeReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth, toast, invest, investCode"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;

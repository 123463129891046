import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import {
  initCountryCode,
  initCountryCodes,
  initInvestTypeCode,
  initInvestTypeCodes,
} from "../../store/investCodeSlice";
import {
  addStocks,
  editStocks,
  initModalShow,
  initStock,
} from "../../store/investSlice";
import { HandleAutoHeight, IsValidValue } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";

const StockFormModal = ({ handleDeleteStockClick, isViewPage }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosToken = useAxiosPrivate();
  /** Redux 전역 변수 */
  const stock = useSelector((state) => state.invest.stock);
  const countryCodes = useSelector((state) => state.investCode.countryCodes);
  const investTypeCodes = useSelector(
    (state) => state.investCode.investTypeCodes
  );
  const countryCode = useSelector((state) => state.investCode.countryCode);
  const investTypeCode = useSelector(
    (state) => state.investCode.investTypeCode
  );
  const isHighEarning = useSelector((state) => state.invest.isHighEarning);
  const isDividend = useSelector((state) => state.invest.isDividend);
  const isSafe = useSelector((state) => state.invest.isSafe);

  const domainDevidend = useSelector(
    (state) => state.investCode.domainDevidend
  );
  const domainMarketWatch = useSelector(
    (state) => state.investCode.domainMarketWatch
  );
  const domainEtfCheck = useSelector(
    (state) => state.investCode.domainEtfCheck
  );
  const domainEtfCom = useSelector((state) => state.investCode.domainEtfCom);
  const domainEtfdbCom = useSelector(
    (state) => state.investCode.domainEtfdbCom
  );
  const domainInvestingCom = useSelector(
    (state) => state.investCode.domainInvestingCom
  );
  const domainWallmineCom = useSelector(
    (state) => state.investCode.domainWallmineCom
  );
  const domainNaverMobile = useSelector(
    (state) => state.investCode.domainNaverMobile
  );
  const domainTradingView = useSelector(
    (state) => state.investCode.domainTradingView
  );
  const domainYahooFinance = useSelector(
    (state) => state.investCode.domainYahooFinance
  );
  /** Modal States */
  const modalShow = useSelector((state) => state.invest.modalShow);
  const [isLoading, setIsLoading] = useState(false);
  /** Form States */
  const [newStock, setNewStock] = useState({});
  /** Error States */
  const [stockCountryError, setStockCountryError] = useState("");
  const [investTypeError, setInvestTypeError] = useState("");
  const [stockCodeError, setStockCodeError] = useState("");
  const [stockNameError, setStockNameError] = useState("");
  const [investingComPreivew, setInvestingComPreivew] = useState("");
  const [wallmineComPreivew, setWallmineComPreivew] = useState("");
  const [tradingViewPreivew, setTradingViewPreivew] = useState("");
  const [devidendPreivew, setDevidendPreivew] = useState("");
  const [naverPreview, setNaverPreview] = useState("");
  // marketWatch, yahoo-finance, etfcheck, etf-com, etfdb-com 추가
  const [marketWatchPreview, setMarketwatchPreview] = useState("");
  const [etfcheckPreview, setEtfCheckPreview] = useState("");
  const [, setYahooFinancePreview] = useState("");
  const [, setEtfComPreview] = useState("");
  const [, setEtfdbComPreview] = useState("");

  const tickerRef = useRef();

  /** 필요한 코드들 가져오기 */
  useEffect(() => {
    const getCountryCodes = async () => {
      try {
        const codes = encodeURIComponent("investType|country");
        const res = await axiosToken.get(
          `${apiServer}/multiChildCodes/${codes}`
        );
        if (res?.data) {
          const countryCodes = res?.data.filter(
            (d) => d.parentCode === "country"
          );
          const investTypeCodes = res?.data.filter(
            (d) => d.parentCode === "investType"
          );
          dispatch(initCountryCodes(countryCodes));
          dispatch(initInvestTypeCodes(investTypeCodes));
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "코드를 가져오지 못했습니다.",
          });
        }
      } catch (err) {
        console.error(err);
        addToast({
          type: "danger",
          text: err.message || "코드를 가져오지 못했습니다.",
        });
      }
    };
    getCountryCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** 종목이 변경되면 newStock에 깊은복사 한다. */
  useEffect(() => {
    if (!modalShow) {
      setNewStock({});
      return;
    }
    if (stock?._id) {
      setNewStock({
        _id: stock?._id || "",
        country: stock?.country || "",
        investType: stock?.investType || "",
        ticker: stock?.ticker || "",
        stockName: stock?.stockName || "",
        isImportant: stock?.isImportant || false,
        isLineThrough: stock?.isLineThrough || false,
        isMyInvest: stock?.isMyInvest || false,
        stockColor: stock?.stockColor || "#000000",

        dividendMainUri: stock?.dividendMainUri || "",
        marketWatchMainUri: stock?.marketWatchMainUri || "",
        etfcheckMainUri: stock?.etfcheckMainUri || "",
        etfcomMainUri: stock?.etfcomMainUri || "",
        etfdbMainUri: stock?.etfdbMainUri || "",
        investingMainUri: stock?.investingMainUri || "",
        wallmineMainUri: stock?.wallmineMainUri || "",
        naverMainUri: stock?.naverMainUri || "",
        tradingViewMainUri: stock?.tradingViewMainUri || "",
        yahooFinanceMainUri: stock?.yahooFinanceMainUri || "",

        isHighEarning: stock?.isHighEarning || false,
        isDividend: stock?.isDividend || false,
        isSafe: stock?.isSafe || false,

        memo: stock?.memo || "",
        tags: stock?.tags || "",
      });
      setDevidendPreivew(
        `${
          stock?.dividendMainUri
            ? domainDevidend + stock?.dividendMainUri
            : domainDevidend
        }`
      );
      setMarketwatchPreview(
        `${
          stock?.marketWatchMainUri
            ? domainMarketWatch + stock?.marketWatchMainUri
            : domainMarketWatch
        }`
      );
      setEtfCheckPreview(
        `${
          stock?.etfcheckMainUri
            ? domainEtfCheck + stock?.etfcheckMainUri
            : domainEtfCheck
        }`
      );
      setEtfComPreview(
        `${
          stock?.etfcomMainUri
            ? domainEtfCom + stock?.etfcomMainUri
            : domainEtfCom
        }`
      );
      setEtfdbComPreview(
        `${
          stock?.etfdbMainUri
            ? domainEtfdbCom + stock?.etfdbMainUri
            : domainEtfdbCom
        }`
      );
      setInvestingComPreivew(
        `${
          stock?.investingMainUri
            ? domainInvestingCom + stock?.investingMainUri
            : domainInvestingCom
        }`
      );
      setWallmineComPreivew(
        `${
          stock?.wallmineMainUri
            ? domainWallmineCom + stock?.wallmineMainUri
            : domainWallmineCom
        }`
      );
      setNaverPreview(
        `${
          stock?.naverMainUri
            ? domainNaverMobile + stock?.naverMainUri
            : domainNaverMobile
        }`
      );
      setTradingViewPreivew(
        `${
          stock?.tradingViewMainUri
            ? domainTradingView + stock?.tradingViewMainUri
            : domainTradingView
        }`
      );
      setYahooFinancePreview(
        `${
          stock?.yahooFinanceMainUri
            ? domainYahooFinance + stock?.yahooFinanceMainUri
            : domainYahooFinance
        }`
      );
    } else {
      setNewStock({
        country: countryCode || "",
        investType: investTypeCode || "",
        isHighEarning: isHighEarning || false,
        isDividend: isDividend || false,
        isSafe: isSafe || false,
      });

      setDevidendPreivew(domainDevidend);
      setMarketwatchPreview(domainMarketWatch);
      setEtfCheckPreview(domainEtfCheck);
      setEtfComPreview(domainEtfCom);
      setEtfdbComPreview(domainEtfdbCom);
      setInvestingComPreivew(domainInvestingCom);
      setWallmineComPreivew(domainWallmineCom);
      setNaverPreview(domainNaverMobile);
      setTradingViewPreivew(domainTradingView);
      setYahooFinancePreview(domainYahooFinance);
    }

    tickerRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock, modalShow]);

  const resetModalForm = () => {
    dispatch(initModalShow(false));
    setNewStock({});
    setStockCountryError("");
    setInvestTypeError("");
    setStockCodeError("");
    setStockNameError("");
  };

  /** validate */
  const validate = () => {
    setStockCountryError("");
    setInvestTypeError("");
    setStockCodeError("");
    setStockNameError("");
    let result = true;
    // country
    if (!IsValidValue(newStock?.country)) {
      setStockCountryError("국가가 선택되지 않았습니다.");
      result = false;
    }
    // invest type
    if (!IsValidValue(newStock?.investType)) {
      setInvestTypeError("투자 타입이 선택되지 않았습니다.");
      result = false;
    }
    // stockNo, stockTicker
    if (!IsValidValue(newStock?.ticker)) {
      setStockCodeError("코드번호나 티커 중 하나는 입력해야 합니다.");
      result = false;
    }
    // stockName
    if (!IsValidValue(newStock?.stockName)) {
      setStockNameError("종목명을 입력하세요.");
      result = false;
    }
    return result;
  };

  /** handleSubmit */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    let isEdit = false;
    let apiUrl = "";
    // newStock._id가 있으면 수정, 없으면 신규등록
    if (IsValidValue(newStock?._id)) {
      isEdit = true;
      apiUrl = `${apiServer}/investStock/edit`;
    } else {
      apiUrl = `${apiServer}/investStock/add`;
    }
    try {
      setIsLoading(true);
      const res = isEdit
        ? await axiosToken.put(apiUrl, newStock)
        : await axiosToken.post(apiUrl, newStock);
      if (res?.data?.ok) {
        if (isEdit) {
          dispatch(editStocks(res.data.result));
          dispatch(initStock(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "종목이 수정되었습니다.",
          });
        } else {
          dispatch(addStocks(res.data.result));
          dispatch(initStock(res.data.result));
          addToast({
            type: "success",
            text: res.data.message || "새 종목이 생성되었습니다.",
          });
        }
        setTimeout(() => {
          /** [중요!!] 시간차를 두지 않으면 resetModalForm()이 반영되지 않는다!
           * - 실행 순서가 변경될 수도 있어서 그런 것 같음.
           */
          resetModalForm();
        }, 500);
      } else {
        if (isEdit) {
          addToast({
            type: "danger",
            text: res.data.message || "종목이 수정되지 않았습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "종목이 생성되지 않았습니다.",
          });
        }
        if (res.data.result === "noAuth") {
          // 로그인 페이지로 이동
          window.location.href = "/login?from=/manage/invest";
        }
      }
    } catch (err) {
      console.error(err);
      addToast({
        type: "danger",
        text:
          err.message ||
          `종목 ${isEdit ? "수정" : "생성"} 중 오류가 발생하였습니다.`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/** 종목 등록폼
       * 도메인이 선택되지 않으면 저장, 추가 버튼 비활성화
       * 모든 폼을 Bootstrap 반응형으로 구성
       * 폼 간에 적당한 간격을 두고 구성
       */}
      <Modal
        animation={false}
        centered
        show={modalShow}
        onHide={resetModalForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>{stock?._id ? "종목 수정" : "새 종목 등록"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/** [주의!!] <form></form> 을 Modal.Body 이외의 위치에 두면 에러 발생! */}
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="container">
              <div className="row">
                {/** 국가 선택 셀렉트박스 */}
                <div className="col-3 mb-2">
                  <select
                    className={`form-select col-6 ${
                      stockCountryError ? "border border-danger" : ""
                    }`}
                    value={newStock?.country || ""}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        country: e.target.value,
                      });
                      initCountryCode(e.target.value);
                      if (IsValidValue(e.target.value))
                        setStockCountryError("");
                    }}
                  >
                    <option value="">국가</option>
                    {countryCodes?.map((item) => (
                      <option key={item._id} value={item.code}>
                        {item.codeName}
                      </option>
                    ))}
                  </select>
                  <div
                    className={`small text-danger ${
                      stockCountryError ? "" : "d-none"
                    }`}
                  >
                    * {stockCountryError}
                  </div>
                </div>
                {/** 투자타입 선택 셀렉트박스 */}
                <div className="col-4 mb-2">
                  <select
                    className={`form-select col-6 ${
                      investTypeError ? "border border-danger" : ""
                    }`}
                    value={newStock?.investType || ""}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        investType: e.target.value,
                      });
                      initInvestTypeCode(e.target.value);
                      if (IsValidValue(e.target.value)) setInvestTypeError("");
                    }}
                  >
                    <option value="">투자타입</option>
                    {investTypeCodes?.map((item) => (
                      <option key={item._id} value={item.code}>
                        {item.codeName}
                      </option>
                    ))}
                  </select>
                  <div
                    className={`small text-danger ${
                      investTypeError ? "" : "d-none"
                    }`}
                  >
                    * {investTypeError}
                  </div>
                </div>
                {/** 종목 번호, 티커 */}
                <div className="col-5">
                  <input
                    type="text"
                    ref={tickerRef}
                    className={`form-control w-100 ${
                      stockCodeError ? "border border-danger" : ""
                    }`}
                    style={{ width: "200px" }}
                    placeholder="코드번호, 티커"
                    value={newStock?.ticker || ""}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        ticker: e.target.value.toUpperCase(),
                        stockName: e.target.value.toUpperCase(),
                      });
                    }}
                    onBlur={(e) => {
                      if (IsValidValue(e.target.value)) setStockCodeError("");
                    }}
                  />
                </div>
                <div
                  className={`text-danger ${stockCodeError ? "" : "d-none"}`}
                >
                  * {stockCodeError}
                </div>
                {/** 종목명 */}
                <div className="col-12 mb-2">
                  <input
                    type="text"
                    className={`form-control ${
                      newStock?.isImportant ? "fw-bold" : ""
                    } ${
                      newStock?.isLineThrough
                        ? "text-decoration-line-through"
                        : ""
                    } ${stockNameError ? "border border-danger" : ""}`}
                    style={{ color: newStock?.stockColor }}
                    placeholder="종목명을 입력하세요."
                    value={newStock?.stockName || ""}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        stockName: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      if (IsValidValue(e.target.value)) setStockNameError("");
                    }}
                  />
                  <div
                    className={`text-danger ${stockNameError ? "" : "d-none"}`}
                  >
                    * {stockNameError}
                  </div>
                </div>
                {/* 강조 */}
                <div className={`col-auto mb-2`}>
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="ck-isImportant"
                    autoComplete="off"
                    checked={newStock?.isImportant || false}
                    // value={isImportant || false}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        isImportant: e.target.checked,
                      });
                    }}
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="ck-isImportant"
                  >
                    강조
                  </label>
                </div>
                {/* 취소선 */}
                <div className="col-auto mb-2 p-0">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="cb-isLineThrough"
                    autoComplete="off"
                    checked={newStock?.isLineThrough || false}
                    // value={isLineThrough || false}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        isLineThrough: e.target.checked,
                      });
                    }}
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="cb-isLineThrough"
                  >
                    취소선
                  </label>
                </div>
                {/** 색상 */}
                <div className="col-auto mb-2">
                  <input
                    type="color"
                    className="form-control form-control-color"
                    id="exampleColorInput"
                    title="Choose your color"
                    value={newStock?.stockColor || "#000000"}
                    onChange={(e) => {
                      setNewStock({
                        ...newStock,
                        stockColor: e.target.value,
                      });
                    }}
                  />
                </div>
                <hr />
                <div className="col-12 mb-2">
                  {/** 기타 조건들 */}
                  <div className="col-auto p-0 mb-2">
                    {/** 보유종목 */}
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isMyInvest"
                      autoComplete="off"
                      checked={newStock?.isMyInvest || false}
                      onChange={(e) => {
                        setNewStock({
                          ...newStock,
                          isMyInvest: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-success me-2"
                      htmlFor="cb-isMyInvest"
                    >
                      보유종목
                    </label>
                    {/** 보유종목 */}
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isHighEarning"
                      autoComplete="off"
                      checked={newStock?.isHighEarning || false}
                      onChange={(e) => {
                        setNewStock({
                          ...newStock,
                          isHighEarning: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-success me-2"
                      htmlFor="cb-isHighEarning"
                    >
                      고성장
                    </label>
                    {/** 고배당 */}
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isDividend"
                      autoComplete="off"
                      checked={newStock?.isDividend || false}
                      onChange={(e) => {
                        setNewStock({
                          ...newStock,
                          isDividend: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-success me-2"
                      htmlFor="cb-isDividend"
                    >
                      고배당
                    </label>
                    {/** 안전자산 */}
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cb-isSafe"
                      autoComplete="off"
                      checked={newStock?.isSafe || false}
                      onChange={(e) => {
                        setNewStock({
                          ...newStock,
                          isSafe: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="btn btn-outline-success me-2"
                      htmlFor="cb-isSafe"
                    >
                      안전자산
                    </label>
                  </div>
                </div>
                <hr />
                {/** naverMainUri */}
                <div
                  className={`mb-2 col-12 ${
                    newStock?.country === "KR" ? "d-none" : ""
                  }`}
                >
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={naverPreview} target="_blank">
                      {naverPreview}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className={`form-control `}
                    placeholder="해외종목 네이버 코드(ex: QQQ.O)"
                    value={newStock?.naverMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(
                        domainNaverMobile,
                        ""
                      );
                      setNewStock({
                        ...newStock,
                        naverMainUri: value,
                      });
                    }}
                  />
                </div>
                {/** wallmineMainUri */}
                <div className={`mb-2 col-12 ${
                      newStock?.country?.indexOf("KR") !== -1 ? "d-none" : ""
                    }`}>
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={wallmineComPreivew} target="_blank">
                      {wallmineComPreivew}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="월마인 주소(ex: /nasdaq/msft)"
                    value={newStock?.wallmineMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(
                        domainWallmineCom,
                        ""
                      );
                      setNewStock({
                        ...newStock,
                        wallmineMainUri: value,
                      });
                      setWallmineComPreivew(`${domainWallmineCom}${value}`);
                    }}
                  />
                </div>
                {/** investingMainUri */}
                <div className="mb-2 col-12">
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={investingComPreivew} target="_blank">
                      {investingComPreivew}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="인베스팅 주소(ex: /etfs/powershares-qqqq)"
                    value={newStock?.investingMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(
                        domainInvestingCom,
                        ""
                      );
                      setNewStock({
                        ...newStock,
                        investingMainUri: value,
                      });
                      setInvestingComPreivew(`${domainInvestingCom}${value}`);
                    }}
                  />
                </div>
                {/** tradingViewMainUri */}
                <div className="mb-2 col-12">
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={tradingViewPreivew} target="_blank">
                      {tradingViewPreivew}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="트레이딩뷰 주소(ex: /symbols/KRX-329670/)"
                    value={newStock?.tradingViewMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(
                        domainTradingView,
                        ""
                      );
                      setNewStock({
                        ...newStock,
                        tradingViewMainUri: value,
                      });
                      setTradingViewPreivew(`${domainTradingView}${value}`);
                    }}
                  />
                </div>
                {/** dividendMainUri */}
                <div
                  className={`mb-2 col-12 ${
                    newStock?.country === "KR" ? "d-none" : ""
                  }`}
                >
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={devidendPreivew} target="_blank">
                      {devidendPreivew}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="디비덴드 주소(ex: /etfs/schd-schwab-us-dividend-equity-etf/)"
                    value={newStock?.dividendMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(domainDevidend, "");
                      setNewStock({
                        ...newStock,
                        dividendMainUri: value,
                      });
                    }}
                  />
                </div>
                {/** marketWatchMainUri */}
                <div className="mb-2 col-12">
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={marketWatchPreview} target="_blank">
                      {marketWatchPreview}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="마켓와치 주소(ex: /investing/fund/qqq)"
                    value={newStock?.marketWatchMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(
                        domainMarketWatch,
                        ""
                      );
                      setNewStock({
                        ...newStock,
                        marketWatchMainUri: value,
                      });
                    }}
                  />
                </div>
                {/** etfcheckMainUri */}
                <div
                  className={`mb-2 col-12 ${
                    newStock?.country === "KR" ||
                    newStock?.investType !== "stock-etf"
                      ? "d-none"
                      : ""
                  }`}
                >
                  <div
                    className="mb-0 small text-muted fst-italic"
                    style={{ fontWeight: 200 }}
                  >
                    <Link to={etfcheckPreview} target="_blank">
                      {etfcheckPreview}
                    </Link>
                  </div>
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="ETF 체크 주소(ex: /mobile/global/etpitem/FOUSA08MU7/basic)"
                    value={newStock?.etfcheckMainUri || ""}
                    onChange={(e) => {
                      const value = e.target.value.replace(domainEtfCheck, "");
                      setNewStock({
                        ...newStock,
                        etfcheckMainUri: value,
                      });
                    }}
                  />
                </div>
                {/** memo */}
                <div className="mb-2 col-12">
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="메모를 입력하세요."
                    value={newStock?.memo || ""}
                    onChange={(e) => {
                      setNewStock({ ...newStock, memo: e.target.value });
                    }}
                    onFocus={HandleAutoHeight}
                    // onKeyUp={HandleAutoHeight}
                    // onKeyDown={HandleAutoHeight}
                    onCut={HandleAutoHeight}
                    // onPaste={HandleAutoHeight}
                  />
                </div>
                {/** tags */}
                <div className="mb-2 col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="# 구분자로 태그들을 입력하세요(ex: #태그1 #태그2)"
                    value={newStock?.tags || ""}
                    onChange={(e) => {
                      setNewStock({ ...newStock, tags: e.target.value });
                    }}
                  />
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-outline-primary w-100 mb-2"
                    onClick={handleSubmit}
                  >
                    {stock ? "저장" : "신규 등록"}
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className={`btn btn-outline-danger w-100 ${
                      stock ? "" : "d-none"
                    }`}
                    onClick={(e) =>
                      handleDeleteStockClick(e, stock?._id, isViewPage)
                    }
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StockFormModal;

import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const ProjectListDoc = () => {
  const axiosHook = useAxiosPrivate();
  const [projects, setProjects] = useState([]);
  const [renewProjects, setRenewProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [mySameCompanyIndex] = useState([]);
  const [mySameCompanyCount] = useState([]);
  const [workSameCompanyIndex] = useState([]);
  const [workSameCompanyCount] = useState([]);
  const [resultProjects, setResultProjects] = useState([]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    const arr = [];
    let rowSpanIdx = -1,
      workRowSpanIdx = -1;
    renewProjects?.map((row) => {
      let myrow, workrow;
      if (mySameCompanyIndex.includes(row.index)) {
        ++rowSpanIdx;
        myrow = mySameCompanyCount[rowSpanIdx];
      }
      if (workSameCompanyIndex.includes(row.index)) {
        ++workRowSpanIdx;
        workrow = workSameCompanyCount[workRowSpanIdx];
      }
      return arr.push({
        ...row,
        rowSpan: myrow,
        workRowSpan: workrow,
      });
    });
    setResultProjects(arr);
  }, [
    renewProjects,
    mySameCompanyIndex,
    mySameCompanyCount,
    workSameCompanyIndex,
    workSameCompanyCount,
  ]);

  useEffect(() => {
    // 관리자 여부도 조건에 넣기
    if (isLoggedIn) {
      setIsLoading(true);
      axiosHook
        .get(`${apiServer}/projectFull`)
        .then((res) => {
          setNumberOfProjects(res.data.length);
          setProjects(res.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
          setError("Something went wrong in database");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setNumberOfProjects(0);
      setProjects([]);
      setIsLoading(false);
      setError("Need to login");
    }
  }, [isLoggedIn, apiServer, axiosHook]);

  useEffect(() => {
    const nProjects = [];
    let prevMycompany = "",
      prevWorkcompany = "",
      mySameCompanyCnt = 0,
      workSameCompanyCnt = 0,
      isSameMycompany = false,
      isSameWorkcompany = false;
    projects?.map((row) => {
      const index = numberOfProjects - nProjects.length;
      return nProjects.push({
        name: row.name,
        period: `${new Date(
          row.start_date
        ).toLocaleDateString()}<br />~ ${new Date(
          row.end_date
        ).toLocaleDateString()}`,
        my_company: (() => {
          if (prevMycompany === row.my_company) {
            if (!isSameMycompany) {
              mySameCompanyIndex.push(index + 1);
            }
            prevMycompany = row.my_company;
            ++mySameCompanyCnt;
            isSameMycompany = true;
            return "";
          } else {
            prevMycompany = row.my_company;
            if (isSameMycompany) {
              mySameCompanyCount.push(mySameCompanyCnt + 1);
            }
            isSameMycompany = false;
            mySameCompanyCnt = 0;
            return row.my_company;
          }
        })(row.my_company),
        work_company: (() => {
          if (prevWorkcompany === row.work_company) {
            if (!isSameWorkcompany) {
              workSameCompanyIndex.push(index + 1);
            }
            prevWorkcompany = row.work_company;
            ++workSameCompanyCnt;
            isSameWorkcompany = true;
            return "";
          } else {
            prevWorkcompany = row.work_company;
            if (isSameWorkcompany) {
              workSameCompanyCount.push(workSameCompanyCnt + 1);
            }
            isSameWorkcompany = false;
            workSameCompanyCnt = 0;
            return row.work_company;
          }
        })(row.work_company),
        index,
      });
    });
    setRenewProjects(nProjects);
  }, [
    projects,
    numberOfProjects,
    mySameCompanyIndex,
    mySameCompanyCount,
    workSameCompanyIndex,
    workSameCompanyCount,
  ]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div className="container">
      <h2 className="h2">
        수행 프로젝트(테이블형, <Link to="/manage/project/doc">문서형</Link>)
      </h2>
      <table className="table table-bordered">
        <thead className="table-primary">
          <tr>
            <th className="text-center" style={{ minWidth: "50px" }}>
              순번
            </th>
            <th className="text-center">근무기간</th>
            <th className="text-center">소속사</th>
            <th className="text-center">고객사</th>
            <th className="text-center">프로젝트명</th>
          </tr>
        </thead>
        <tbody>
          {resultProjects?.map((row) => {
            return (
              <tr key={row.index}>
                <td className="text-center align-middle">{row.index}</td>
                <td
                  className="text-center align-middle"
                  dangerouslySetInnerHTML={{
                    __html: row.period,
                  }}
                />
                <td
                  rowSpan={row.rowSpan}
                  className={
                    row.my_company ? "text-center align-middle" : "d-none"
                  }
                >
                  {row.my_company}
                </td>
                <td
                  rowSpan={row.workRowSpan}
                  className={
                    row.work_company ? "text-center align-middle" : "d-none"
                  }
                >
                  {row.work_company}
                </td>
                <td style={{ verticalAlign: "middle" }}>{row.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

ProjectListDoc.propTypes = {
  isManagePage: propTypes.bool,
};

ProjectListDoc.defaultProps = {
  isManagePage: false,
};

export default ProjectListDoc;

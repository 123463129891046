import { useEffect, useState } from "react";
import useToast from "../../hooks/useToast";

const TopControls = ({ year, setYear, name, setName, names }) => {
  const { addToast } = useToast();
  const [years, setYears] = useState([]);

  useEffect(() => {
    const year = new Date().getFullYear();
    setYear(year);
    // 2016년부터 현재년도까지
    const years = [];
    for (let i = 2021; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    setYears(years);
  }, [setYear]);

  const resetPage = () => {
    // 연도 선택 초기화
    const year = new Date().getFullYear();
    setYear(year);
    // 이름 선택 초기화
    setName("");
  };

  return (
    <div className="row">
      <div className="mb-3 col-12 col-sm-6">
        <button
          className="btn btn-secondary col-12"
          type="button"
          id="button-addon1"
          onClick={resetPage}
        >
          초기화
        </button>
      </div>
      <div className="mb-3 col-12 col-sm-6">
        <select
          className="form-select col-12"
          aria-label="Default select example"
          value={year}
          onChange={(e) => {
            if (e.target.value === "") {
              addToast({
                text: "연도를 선택해주세요.",
                type: "danger",
              });
              return;
            }
            setYear(e.target.value);
          }}
        >
          <option value="">연도 선택</option>
          {years?.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {/* <div className="mb-3 col-12 col-sm-6 col-md-4">
        <select
          className="form-select col-12"
          aria-label="Default select example"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        >
          <option value="">이름 선택</option>
          {names?.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

export default TopControls;

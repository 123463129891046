import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import MsgBox from "../../components/MsgBox";
import "./story.css";

const StoryListPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [stories, setStories] = useState([]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  /**
   * 전체공개 그룹 + 전체공개 카테고리의 스토리 조회
   * /publicStories
   */
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${apiServer}/publicStories`)
      .then((res) => {
        setStories(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [apiServer]);

  if (error) {
    return <MsgBox type="danger" msg={error} />;
  }

  return (
    <>
      {/* storyGroup isPublic && story publicStatus 전체공개(PSP)인 스토리 목록 */}
      <h1>전체공개 스토리 목록</h1>
      <ol className={stories?.length ? "" : "d-none"}>
        {stories?.map((story, index) => (
          <li key={index}>
            <Link to={`/story/${story.openId}`} target="_blank">
              {story.name}
            </Link>
          </li>
        ))}
      </ol>
      <p className={stories?.length ? "d-none" : ""}>스토리가 없습니다.</p>
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StoryListPage;

import BlogList from "../../components/blog/BlogList";

const ListPage = () => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <h2 className="h2">Blogs</h2>
      </div>
      <BlogList />
    </>
  );
};

export default ListPage;

import { faArrowDownAZ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import LoadingSpinner from "../LoadingSpinner";
import MsgBox from "../MsgBox";

const StoryTable = ({ selGroupInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [stories, setStories] = useState([]);
  const [storyListMsg, setStoryListMsg] =
    useState("상단의 그룹명을 선택하세요.");
  const [selectedStoryId, setSelectedStoryId] = useState(null);
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const { addToast } = useToast();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const [publicStatuses, setPublicStatuses] = useState([]);

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/publicStatus/childCodes`)
      .then((res) => {
        setPublicStatuses(res.data);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer]);

  useEffect(() => {
    setTimeout(() => {
      const links = document.querySelectorAll(
        `#tblStory > tbody > tr > th > a`
      );
      links.forEach((link) => {
        link.style.backgroundColor = "transparent";
      });
      // 선택된 selectedStoryId 의 위치로 스크롤 이동
      const selGroupLink = document.querySelector(
        `#tblStory > tbody > tr[data-id="${selectedStoryId}"] > th > a`
      );
      if (selGroupLink) {
        selGroupLink.style.backgroundColor = "#d6ff00cf";
        selGroupLink.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 100);
  }, [selectedStoryId]);

  useEffect(() => {
    if (!selGroupInfo) {
      setStories([]);
      return;
    }
    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/${selGroupInfo?._id}/stories`)
      .then((res) => {
        setStories(res.data);
        if (res.data.length === 0)
          setStoryListMsg("선택 된 그룹에 스토리가 없습니다.");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer, selGroupInfo]);

  const handleDeleteStory = (e, id) => {
    e.preventDefault();
    setSelectedStoryId(id);
    if (!id) {
      addToast({
        type: "danger",
        text: "삭제할 스토리가 선택되지 않았습니다.\n잠시 후 다시 시도해주세요.",
      });
      return;
    }
    if (!window.confirm("선택한 스토리를 삭제하시겠습니까?")) return;
    axiosHook
      .delete(`${apiServer}/storyDelete/`, { data: { _id: id } })
      .then((res) => {
        if (res.data.ok) {
          addToast({
            type: "success",
            text: res.data.message || "스토리가 삭제되었습니다.",
          });
          setSelectedStoryId(null);
          setStories(stories.filter((story) => story._id !== id));
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "스토리 삭제에 실패했습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addToast({
          type: "danger",
          text: err.message || "스토리 삭제에 실패했습니다.",
        });
      });
  };

  const handleCloneStory = async (e, storyId) => {
    e.preventDefault();
    await setSelectedStoryId(storyId);
    if (!selectedStoryId && !storyId) {
      addToast({
        type: "danger",
        text: "복사할 스토리가 선택되지 않았습니다.\n잠시 후 다시 시도해주세요.",
      });
      return;
    }
    if (!window.confirm("선택한 스토리를 복사하시겠습니까?")) return;
    await axiosHook
      .post(`${apiServer}/storyClone/`, { _id: storyId })
      .then((res) => {
        if (res.data.ok) {
          addToast({
            type: "success",
            text: res.data.message || "스토리가 복사되었습니다.",
          });
          setStories([...stories, res.data.result]);
          setSelectedStoryId(res.data.result._id);
        } else {
          addToast({
            type: "danger",
            text: res.data.message || "스토리 복사에 실패했습니다.",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addToast({
          type: "danger",
          text: err.message || "스토리 복사에 실패했습니다.",
        });
      });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <table className="table table-responsive align-middle" id="tblStory">
        <thead className="table-danger sticky-top">
          <tr>
            <th scope="col" style={{ minWidth: "100px" }}>
              스토리명&nbsp;
              <FontAwesomeIcon icon={faArrowDownAZ} />
            </th>
            <th scope="col" style={{ minWidth: "50px" }}>
              메모
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              공개
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              복사
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              편집
            </th>
            <th
              scope="col"
              className="text-center"
              style={{ minWidth: "50px" }}
            >
              삭제
            </th>
          </tr>
        </thead>
        <tbody className="tablel-divider">
          <tr className={`${stories?.length ? "d-none" : ""}`}>
            <td colSpan="6" className="text-center">
              {storyListMsg}
            </td>
          </tr>
          {stories?.map((story, index) => (
            <tr
              key={index}
              data-id={story._id}
              onClick={() => {
                setSelectedStoryId(story._id);
              }}
            >
              <th scope="row" style={{ maxWidth: "1px" }}>
                <Link
                  to={`/story/${story.openId}`}
                  target="_blank"
                  className={`text-decoration-underline text-primary`}
                >
                  {story.name}
                </Link>
              </th>
              <td style={{ maxWidth: "1px" }}>
                <small>{story.memo}</small>
              </td>
              <td
                className={`text-center ${
                  story.publicStatus === "PSP" || story.publicStatus === "PSM"
                    ? "text-primary"
                    : "text-danger"
                }`}
              >
                {publicStatuses.find((item) => item.code === story.publicStatus)
                  ?.codeName || "알 수 없음"}
              </td>
              <td className="text-center">
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={(e) => handleCloneStory(e, story._id)}
                >
                  복사
                </button>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    navigate(`/manage/storyEdit/${story._id}`);
                  }}
                >
                  편집
                </button>
              </td>
              <td className="text-center">
                <button
                  className="btn btn-sm btn-outline-danger"
                  onClick={(e) => {
                    handleDeleteStory(e, story._id);
                  }}
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StoryTable;

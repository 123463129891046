import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { HandleAutoHeight } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const AddModal = ({
  showParam,
  setShowParam,
  names,
  name,
  setName,
  setAssets,
  assets,
  walletOpenId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error] = useState("");
  const axiosHook = useAxiosPrivate();
  const { addToast } = useToast();
  const [show, setShow] = useState(false);
  const [modalTitle] = useState("신규 등록");
  const [amount, setAmount] = useState("");
  const [amountErrMsg, setAmountErrMsg] = useState("");
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [assetDate, setAssetDate] = useState("");
  const [assetDateErrMsg, setAssetDateErrMsg] = useState("");
  const [memo, setMemo] = useState("");
  const apiDomain = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    setShow(showParam);
  }, [showParam]);

  const resetStates = () => {
    setShow(false);
    setShowParam(false);
    setAmount("");
    setAmountErrMsg("");
    setNameErrMsg("");
    setAssetDate("");
    setAssetDateErrMsg("");
    setMemo("");
  };

  const validate = () => {
    setNameErrMsg("");
    setAssetDateErrMsg("");
    setAmountErrMsg("");
    let result = true;
    if (name === "") {
      setNameErrMsg("이름을 선택하세요.");
      result = false;
    }
    if (assetDate === "") {
      setAssetDateErrMsg("일시를 선택하세요.");
      result = false;
    }
    if (amount === "") {
      setAmountErrMsg("금액을 입력하세요.");
      result = false;
    }
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    const data = {
      name,
      assetDate,
      amount: amount.replaceAll(",", ""),
      memo,
      walletOpenId,
    };
    setIsLoading(true);
    axiosHook
      .post(`${apiDomain}/walletAsset/add`, data)
      .then((res) => {
        if (res.data?.ok) {
          addToast({ text: "회비 내역이 등록되었습니다.", type: "success" });
          // resetStates();
          // setAssets([...assets, res.data?.result]);
          window.location.reload();
        } else {
          addToast({ text: "회비 내역 등록에 실패했습니다.", type: "danger" });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({ text: "회비 내역 등록에 실패했습니다.", type: "danger" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <Modal centered show={show} animation={false} onHide={resetStates}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="table-responsive">
            <ul className="text-danger">
              <li className={nameErrMsg ? "" : "d-none"}>{nameErrMsg}</li>
              <li className={assetDateErrMsg ? "" : "d-none"}>
                {assetDateErrMsg}
              </li>
              <li className={amountErrMsg ? "" : "d-none"}>{amountErrMsg}</li>
            </ul>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <td className="text-end">이름</td>
                  <td>
                    <select
                      className={`form-select ${
                        nameErrMsg ? "border-danger" : ""
                      }`}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    >
                      <option value="">이름을 선택하세요.</option>
                      {names?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="text-end">일시</td>
                  <td>
                    <input
                      type="datetime-local"
                      className={`form-control ${
                        assetDateErrMsg ? "border-danger" : ""
                      }`}
                      value={assetDate}
                      onChange={(e) => {
                        setAssetDate(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-end">금액</td>
                  <td>
                    <input
                      type="text"
                      className={`form-control ${
                        amountErrMsg ? "border-danger" : ""
                      }`}
                      placeholder="금액을 입력하세요."
                      value={amount}
                      onChange={(e) => {
                        /* 정수(-, 0, +)만 입력 가능, 1000단위 콤마(,) 자동 추가 */
                        e.target.value = e.target.value
                          .replace(/[^0-9-]/g, "")
                          .replace(/,/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        setAmount(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="text-end">메모</td>
                  <td>
                    <textarea
                      className="form-control w-100"
                      rows={1}
                      placeholder="메모를 입력하세요."
                      value={memo}
                      onChange={(e) => {
                        setMemo(e.target.value);
                      }}
                      onFocus={HandleAutoHeight}
                      // onKeyUp={HandleAutoHeight}
                      // onKeyDown={HandleAutoHeight}
                      onCut={HandleAutoHeight}
                      // onPaste={HandleAutoHeight}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success w-100" onClick={handleSubmit}>
            저장
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddModal;

const Footer = () => {
  return (
    <footer id="mainFooter" className="my-1">
      <p className="pt-3 text-center my-5">
        &copy;{" "}
        <a rel="noopener noreferrer" target="_blank" href="/">
          DooNee.Net
        </a>
      </p>
    </footer>
  );
};

export default Footer;

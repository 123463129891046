/**
 * https://developers.google.com/identity/gsi/web/reference/html-reference
 */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JsonLocalStorage } from "../../util/common";
// import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import useToast from "../../hooks/useToast";
import MsgBox from "../MsgBox";

export default function GoogleAuthPage() {
  const apiDomain = process.env.REACT_APP_API_DOAMIN;
  const { addToast } = useToast();
  const dispatch = useDispatch();
  // const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [msg, setMsg] = useState("인증 확인중...");

  useEffect(() => {
    async function init() {
      // googleLogin?.user 가 없을 경우 중단
      if (!JsonLocalStorage.getItem("googleDecodedResponse")) {
        setMsg("Error!");
        setMsg(
          "구글 소셜의 유저 정보가 없습니다. 구글 소셜 로그인을 다시 시도해 주세요."
        );
        navigate(-1);
        return;
      }
      try {
        const user = JsonLocalStorage.getItem("googleDecodedResponse");
        const googleAccount = await user?.email;
        // 자동 로그인 시도, 백단에 만들어야 함.
        await axios
          .post(`${apiDomain}/login`, {
            loginId: googleAccount,
            loginPass: "",
            isPersist: true,
            provider: "google",
          })
          .then((res) => {
            if (res?.data?.ok) {
              localStorage.setItem("dnnAt", res.data.result);
              dispatch({
                type: "auth/login",
                payload: {
                  accessToken: res.data.result,
                  userId: res.data.userId,
                  userRoles: res.data.userRoles,
                },
              });
              addToast({
                type: "success",
                text: "로그인 되었습니다.",
              });
              navigate("/youtube");
            } else {
              // 로그인 실패
              addToast({
                type: "danger",
                text: "아이디 또는 비밀번호가 일치하지 않습니다.",
              });
              navigate(-1);
            }
          });
      } catch (err) {
        setMsg("Error!");
        addToast({
          type: "danger",
          text: err.message || "로그인에 실패했습니다.",
        });
        navigate(-1);
      } finally {
        JsonLocalStorage.removeItem("googleDecodedResponse");
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch]);

  return <MsgBox msg={msg} />;
}

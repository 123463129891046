import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import MsgBox from "../MsgBox";
import ProgressBarLabel from "../ProgressBarLabel";

function BoardSentenceFileForm({
  isFile,
  setIsFile,
  isImage,
  setIsImage,
  fileUri,
  setFileUri,
  bFile,
  fileFolder /** blobName / 마지막부분 전까지 */,
  setFileFolder,
  fileName /** blobName / 마지막부분 이후 파일이름 */,
  setFileName,
  folderOrgName /** originalname */,
  setFolderOrgName,
  fileSize /** blobSize */,
  setFileSize,
  setPreDeletedFile,
  sentenceTitle,
  sentenceExplain,
}) {
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [fileOrgName, sefFileOrgName] = useState("");
  const { addToast } = useToast();
  const [progress, setProgress] = useState(0);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  const handlePreDeleteFile = (e) => {
    e.preventDefault();
    setPreDeletedFile(`${fileFolder}/${fileName}`);
    setIsFile(false);
    setIsImage(false);
    setFileName("");
    setFileFolder("");
    setFileUri("");
    setFolderOrgName("");
    setFileSize("");
    setIsLoading(false);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const uploadFile = e.target.files[0];
    if (!uploadFile) {
      addToast({
        text: "파일이 선택되지 않았습니다.",
        type: "danger",
      });
      return;
    }
    setIsLoading(false);
    setProgress(0);
    const formData = new FormData();
    formData.append("bFile", uploadFile);
    axiosHook({
      method: "post",
      url: `${process.env.REACT_APP_API_DOAMIN}/boardFileUpload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total) - 1;
        setProgress(percent);
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setIsFile(true);
          sefFileOrgName(res.data.originalname);
          setIsImage(res.data.mimetype.includes("image"));
          setFileUri(res.data.url);
          /** blobName / 마지막부분 전까지 */
          setFileFolder(
            "/boards/" + res.data.blobName.split("/").slice(0, -1).join("/") ||
              ""
          );
          /** blobName / 마지막부분 이후 파일이름 */
          setFileName(res.data.blobName.split("/").slice(-1)[0] || "");
          /** originalname */
          setFolderOrgName(res.data.originalname || "");
          /** blobSize */
          setFileSize(res.data.blobSize || "");
          addToast({
            text: "파일 업로드가 완료되었습니다.",
            type: "success",
          });
        } else {
          addToast({
            text: "파일 업로드에 실패하였습니다.",
            type: "danger",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        addToast({
          text: "파일 업로드에 실패하였습니다.",
          type: "danger",
        });
      });
  };

  if (isLoading) {
    return <ProgressBarLabel now={progress} />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      {isFile ? (
        <>
          {isImage ? (
            <div className="text-center">
              <img
                src={fileUri}
                alt={bFile?.folderOrgName || fileOrgName || bFile?._id}
                className="border border-1 border-light rounded shadow"
                style={{ maxWidth: "98%" }}
              />
              <p className="w-100 text-center fst-italic text-muted small">
                &lt;{bFile?.folderOrgName || fileOrgName || bFile?._id}&gt;
              </p>
            </div>
          ) : (
            <div className="fw-bold p-2" style={{ backgroundColor: "#faf9dc" }}>
              <a rel="noopener noreferrer" href={fileUri} target="_blank">
                {bFile?.folderOrgName || fileOrgName || bFile?._id}
              </a>
            </div>
          )}
          <div>
            <button
              tabIndex={-1}
              className="btn btn-outline-danger btn-sm col-12"
              onClick={handlePreDeleteFile}
            >
              파일 삭제
            </button>
          </div>
        </>
      ) : (
        <input
          type="file"
          className="form-control"
          name="bFile"
          onChange={handleFileChange}
        />
      )}
    </>
  );
}

export default BoardSentenceFileForm;

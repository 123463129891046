import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { HandleAutoHeight } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import { MsgBox } from "../MsgBox";
import BoardSentenceFileForm from "./BoardSentenceFileForm";

const BoardSentenceForm = ({
  bFile,
  boardNo,
  boardSentences,
  setBoardSentences,
  moveTo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const axiosHook = useAxiosPrivate();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const fileRootUri = process.env.REACT_APP_FILE_ROOT_URI;

  const { addToast } = useToast();

  const [isImage, setIsImage] = useState(false);
  const [fileUri, setFileUri] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [sentenceTitle, setSentenceTitle] = useState("");
  const [originalSentenceTitle, setOriginalSentenceTitle] = useState("");
  const [sentenceExplain, setSentenceExplain] = useState("");
  const [originalSentenceExplain, setOriginalSentenceExplain] = useState("");
  const [fileFolder, setFileFolder] =
    useState(""); /** blobName / 마지막부분 전까지 */
  const [fileName, setFileName] =
    useState(""); /** blobName / 마지막부분 이후 파일이름 */
  const [originalFileName, setOriginalFileName] = useState("");
  const [folderOrgName, setFolderOrgName] = useState(""); /** originalname */
  const [fileSize, setFileSize] = useState(0); /** blobSize */
  const [preDeletedFile, setPreDeletedFile] = useState("");
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (!isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    setIsFile(bFile ? true : false);
    setSentenceTitle(bFile?.fileTitle || "");
    setOriginalSentenceTitle(bFile?.fileTitle || "");
    setSentenceExplain(bFile?.fileExplain || "");
    setOriginalSentenceExplain(bFile?.fileExplain || "");
    setFileName(bFile?.fileName || "");
    setOriginalFileName(bFile?.fileName || "");
    setFileFolder(bFile?.fileFolder || "");
    setFolderOrgName(bFile?.folderOrgName || "");
    setFileSize(bFile?.fileSize || "");
    setPreDeletedFile("");
  }, [bFile]);

  useEffect(() => {
    if (
      sentenceTitle !== originalSentenceTitle ||
      sentenceExplain !== originalSentenceExplain ||
      fileName !== originalFileName
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [
    sentenceTitle,
    originalSentenceTitle,
    sentenceExplain,
    originalSentenceExplain,
    fileName,
    originalFileName,
  ]);

  const resetSentenceForm = () => {
    setIsFile(false);
    setIsImage(false);
    setSentenceTitle("");
    setOriginalSentenceTitle("");
    setSentenceExplain("");
    setOriginalSentenceExplain("");
    setFileName("");
    setOriginalFileName("");
    setFileFolder("");
    setFolderOrgName("");
    setFileSize(0);
    setPreDeletedFile("");
  };

  const handleSaveSentence = (e, sentenceId) => {
    e.preventDefault();
    const data = {
      _id: sentenceId,
      boardNo,
      fileTitle: sentenceTitle,
      fileExplain: sentenceExplain,
      fileStorageType: "B",
      fileFolder /** /boards + blobName / 마지막부분 전까지 */,
      fileName /** blobName / 마지막부분 이후 파일이름 */,
      folderOrgName /** originalname */,
      fileSize /** blobSize */,
    };
    let apiUri;
    if (sentenceId) {
      if (!sentenceTitle && !sentenceExplain && !fileName) {
        handleDeleteSentence(e, sentenceId);
        return;
      }
      apiUri = `${apiServer}/boardSentence/edit`;
      axiosHook
        .put(apiUri, data)
        .then((res) => {
          if (res.data.ok) {
            if (preDeletedFile) {
              handleDeleteFile();
            }
            document.querySelectorAll("input").forEach((input) => {
              input.blur();
            });
            document.querySelectorAll("button").forEach((input) => {
              input.blur();
            });
            addToast({
              text: res.data.message || "문단이 수정되었습니다.",
              type: "success",
            });
          } else {
            addToast({
              text: res.data.message || "문단 등록에 실패하였습니다.",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          addToast({
            text: err.message || "문단 등록에 실패하였습니다.",
            type: "danger",
          });
          console.error(err);
        });
    } else {
      if (!sentenceTitle && !sentenceExplain && !fileName) {
        addToast({
          text: "문단 제목, 내용, 파일 중 최소 하나는 입력되어야 합니다.",
          type: "danger",
        });
        return;
      }
      apiUri = `${apiServer}/boardSentence/add`;
      axiosHook
        .post(apiUri, data)
        .then((res) => {
          if (res.data.ok) {
            setBoardSentences([...boardSentences, res.data.result]);
            resetSentenceForm();
            document.querySelectorAll("input").forEach((input) => {
              input.blur();
            });
            document.querySelectorAll("button").forEach((input) => {
              input.blur();
            });
            addToast({
              text: res.data.message || "문단이 등록되었습니다.",
              type: "success",
            });
          } else {
            addToast({
              text: res.data.message || "문단 등록에 실패하였습니다.",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          addToast({
            text: err.message || "문단 등록에 실패하였습니다.",
            type: "danger",
          });
          console.error(err);
        });
    }
  };

  const handleDeleteFile = (delFile) => {
    if (!delFile && !preDeletedFile)
      return; /** 삭제할 파일이 없으면 무시한다. */
    setIsLoading(false);
    axiosHook({
      method: "delete",
      url: `${process.env.REACT_APP_API_DOAMIN}/boardFileDelete`,
      data: { data: delFile || preDeletedFile },
    })
      .then((res) => {
        if (res.data.ok) {
          setIsFile(false);
          setIsImage(false);
          setFileName("");
          setFileFolder("");
          setFileUri("");
          setFolderOrgName("");
          setFileSize("");
          setPreDeletedFile("");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleDeleteSentence = (e, sentenceId) => {
    e.preventDefault();
    if (!sentenceId) {
      addToast({
        text: "문단 아이디가 선택되지 않았습니다.",
        type: "danger",
      });
      return;
    }
    if (!window.confirm("문단을 삭제하시겠습니까?")) return;
    setIsLoading(false);
    const apiUri = `${apiServer}/boardSentence/delete`;
    const data = {
      _id: sentenceId,
    };
    axiosHook
      .delete(apiUri, { data })
      .then((res) => {
        if (res.data.ok) {
          handleDeleteFile(
            `${bFile?.fileFolder}/${bFile?.fileName}`
          ); /** 파일삭제가 실패해도 무시하고 진행한다. */
          const newBoardSentences = boardSentences.filter(
            (sentence) => sentence._id !== sentenceId
          );
          setBoardSentences(newBoardSentences);
          addToast({
            text: res.data.message || "문단이 삭제되었습니다.",
            type: "success",
          });
        } else {
          addToast({
            text: res.data.message || "문단 삭제에 실패하였습니다.",
            type: "danger",
          });
        }
      })
      .catch((err) => {
        addToast({
          text: err.message || "문단삭제에 실패하였습니다.",
          type: "danger",
        });
        console.error(err);
      });
  };

  useEffect(() => {
    if (!bFile?.fileName) {
      setIsFile(false);
      setIsImage(false);
      setFileUri("");
    } else {
      setIsFile(true);
      bFile?.fileStorageType === "B"
        ? setFileUri(`${fileRootUri}${bFile?.fileFolder}/${bFile?.fileName}`)
        : setFileUri(
            `${fileRootUri}/upload/board2016/${bFile?.fileFolder}/${bFile?.fileName}`
          );
      const fileExt = bFile?.folderOrgName.split(".").pop();
      if (
        ["jpg", "jpeg", "png", "gif", "ico"].includes(fileExt.toLowerCase())
      ) {
        setIsImage(true);
      }
    }
  }, [bFile, fileRootUri]);

  if (isLoading) {
    return <LoadingPopSpinner />;
  }

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div
      className="mb-3 border border-dark-subtle rounded p-2 dummy-sentence-class"
      data-sentence={bFile?._id}
    >
      <form>
        <div className="mb-3">
          <input
            className="form-control fw-bold"
            style={{ fontSize: "1.15rem" }}
            type="text"
            placeholder="문단 제목"
            value={
              sentenceTitle
                ?.replaceAll("&lt;h3&gt;", "")
                ?.replaceAll("&lt;/h3&gt;", "") || ""
            }
            onChange={(e) => {
              setSentenceTitle(e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <BoardSentenceFileForm
            isFile={isFile}
            setIsFile={setIsFile}
            isImage={isImage}
            setIsImage={setIsImage}
            fileUri={fileUri}
            setFileUri={setFileUri}
            bFile={bFile}
            fileFolder={fileFolder}
            setFileFolder={setFileFolder}
            fileName={fileName}
            setFileName={setFileName}
            folderOrgName={folderOrgName}
            setFolderOrgName={setFolderOrgName}
            fileSize={fileSize}
            setFileSize={setFileSize}
            setPreDeletedFile={setPreDeletedFile}
            handleDeleteFile={handleDeleteFile}
            sentenceTitle={sentenceTitle}
            sentenceExplain={sentenceExplain}
          />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            rows="2"
            placeholder="문단 내용"
            // value={decodeHTMLEntities(sentenceExplain) || ""}
            value={sentenceExplain || ""}
            onChange={(e) => {
              setSentenceExplain(e.target.value);
            }}
            onFocus={HandleAutoHeight}
            onKeyUp={HandleAutoHeight}
            onKeyDown={HandleAutoHeight}
            onCut={HandleAutoHeight}
            onPaste={HandleAutoHeight}
          ></textarea>
        </div>
        <div className="mb-3 text-center">
          <div className="btn-group col-12" role="group">
            {bFile ? (
              <>
                <button
                  className="btn btn-outline-primary col-5"
                  disabled={!isEdited}
                  onClick={(e) => handleSaveSentence(e, bFile._id)}
                >
                  저장
                </button>
                <button
                  className="btn btn-outline-danger col-5"
                  onClick={(e) => handleDeleteSentence(e, bFile._id)}
                >
                  삭제
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-primary col-12"
                onClick={handleSaveSentence}
              >
                추가
              </button>
            )}
          </div>
        </div>
        <div className={`mb-1 text-center ${bFile ? "" : "d-none"}`}>
          <div className="btn-group col-12" role="group">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-direction="top"
              onClick={moveTo}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  ></path>
                </svg>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-direction="up"
              onClick={moveTo}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                  ></path>
                </svg>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-direction="down"
              onClick={moveTo}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-direction="bottom"
              onClick={moveTo}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BoardSentenceForm;

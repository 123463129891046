import BlogForm from "../../../components/blog/BlogForm";

const CreatePage = () => {
  return (
    <div>
      <BlogForm />
    </div>
  );
};

export default CreatePage;

import propTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import Card from "../Card";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import Pagination from "../Pagination";

const BlogList = ({ isManagePage }) => {
  const axiosHook = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pageParam = params.get("page");
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPosts, setNumberOfPosts] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const { addToast } = useToast();
  // 페이징은 스터디 방식과 다르기에 추후에 필요할 때 커스텀 하기
  // 검색도 함께 연동되므로 추후에 필요할 때 커스텀 하기
  const limit = 3;
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    setError("");
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(numberOfPosts / limit));
  }, [numberOfPosts]);

  const onClickPageButton = (page) => {
    navigate(`${location.pathname}/?page=${page || ""}`);
    setCurrentPage(page);
    getPosts(page);
  };

  const getPosts = useCallback(
    () => {
      const uri = isManagePage
        ? `${apiServer}/${limit}/posts`
        : `${apiServer}/${limit}/publish/posts`;
      setIsLoading(true);
      axiosHook
        .get(uri)
        .then((res) => {
          setNumberOfPosts(res.data.length);
          setPosts(res.data || []);
        })
        .catch(() => {
          setError("Something went wrong in database");
          addToast({
            text: "Something went wrong",
            type: "danger",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, // , addToast: 무한루프?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isManagePage, searchText, apiServer]
  );

  useEffect(() => {
    setCurrentPage(parseInt(pageParam) || 1);
    getPosts(parseInt(pageParam) || 1);
  }, [pageParam, getPosts]);

  const deleteBlog = (e, _id) => {
    e.stopPropagation();
    if (!window.confirm("Are you sure you want to delete this blog?")) return;
    axiosHook
      .delete(`${apiServer}/post/delete`, { data: { _id } })
      .then((res) => {
        // setPosts(prevPosts => prevPosts.filter(post => post?._id !== _id));
        //getPosts(1);
        if (res.data.ok) {
          getPosts(currentPage);
          addToast({
            text: "Successfully deleted",
            type: "success",
          });
        } else {
          addToast({
            text: "The blog could not be deleted.",
            type: "danger",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          text: "The blog could not be deleted.",
          type: "danger",
        });
      });
  };

  const renderBlogList = () => {
    return posts?.map((post) => {
      return (
        <Card
          key={post?._id}
          title={post.title}
          onClick={() => navigate(`/blog/view/${post._id}`)}
        >
          <>
            <div className="text-muted" style={{ fontSize: "90%" }}>
              {new Date(post.createdAt).toLocaleDateString("ko-kr", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
            </div>
            {isManagePage ? (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deleteBlog(e, post?._id)}
                >
                  Delete
                </button>
              </div>
            ) : null}
          </>
        </Card>
      );
    });
  };

  const onSearch = (e) => {
    if (e.key === "Enter") {
      navigate(`${location.pathname}/?page=1`);
      setCurrentPage(1);
      getPosts(1);
    }
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <input
        type="text"
        placeholder="Search.."
        className="form-control"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={onSearch}
      />
      <hr />
      {posts?.length === 0 ? (
        <div>No blog posts found</div>
      ) : (
        <>
          {renderBlogList()}
          {numberOfPages > 1 && (
            <Pagination
              currentPage={currentPage}
              numberOfPages={numberOfPages}
              onClick={onClickPageButton}
            />
          )}
        </>
      )}
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

BlogList.propTypes = {
  isManagePage: propTypes.bool,
};

BlogList.defaultProps = {
  isManagePage: false,
};

export default BlogList;

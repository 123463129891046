const BoardLink = ({ title, link }) => {
  return (
    // isLoggedIn을 is관리자 레벨로 바꿔야함!!
    <li
      style={{ listStyleType: "decimal", lineHeight: "2" }}
    >
      <a rel="noopener noreferrer" target="_blank" href={link}>
        {title}
      </a>
    </li>
  );
};

export default BoardLink;

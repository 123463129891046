import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { IsValidValue } from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const MemberButtons = ({ year, names, setName, name, walletOpenId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [members, setMembers] = useState([]);
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  useEffect(() => {
    if (!IsValidValue(year)) return;
    const uri = `${apiServer}/membersSum/${walletOpenId}`;
    const params = {
      year,
      name,
    };
    setIsLoading(true);
    axios
      .get(uri, { params })
      .then((res) => {
        // res.data 에 없는 names 추출
        let namesNotInRes = [];
        if (!name) {
          namesNotInRes = names.filter(
            (name) => !res.data.map((member) => member._id).includes(name)
          );
        }
        // res.data 에 없는 names 를 members 에 추가
        // id는 name, totalAmount 는 0 으로 설정
        // totalAmount 올림차순 + _id 올림차순 으로 정렬
        setMembers(
          res.data
            .concat(
              namesNotInRes?.map((name) => {
                return { _id: name, totalAmount: 0 };
              })
            )
            .sort((a, b) => {
              if (a.totalAmount === b.totalAmount) {
                return a._id > b._id ? 1 : -1;
              }
              return a.totalAmount - b.totalAmount;
            })
        );
      })
      .catch((e) => {
        setError(e.message);
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [year, name, names, apiServer, walletOpenId]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div className="row justify-content-center">
      {members?.map((member) => {
        return (
          <button
            key={member._id}
            className={`btn btn-outline-${
              // member.totalAmount 이 0 보다 크면 primary, 0 보다 작으면 danger, 0 이면 secondary
              member.totalAmount > 0
                ? "primary"
                : member.totalAmount < 0
                ? "danger"
                : "secondary"
            } col-5 col-sm-5 col-md-3 col-lg-2 m-2 ${
              // member._id 가 선택된 이름이면 active, 아니면 light
              member._id === name ? "active" : "light"
            }`}
            type="button"
            onClick={() => {
              // member._id 를 선택된 이름으로 설정
              setName(member._id);
            }}
          >
            {member._id}{" "}
            {
              // member.totalAmount 세자리마다 콤마(,) 추가
              new Intl.NumberFormat().format(member.totalAmount)
            }
          </button>
        );
      })}
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default MemberButtons;

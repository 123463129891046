import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import useWindowSize from "../../hooks/useWindowSize";
import {
  DeepCopy,
  GetDateFormat,
  GetDaysDiff,
  GetStandardAge,
} from "../../util/common";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";

const Anniversary = () => {
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [anniversaries, setAnniversaries] = useState([]);
  const [groupType] = useState("familyM");
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const axiosHook = useAxiosPrivate();

  const updateSolarDate = async (e, anni, yearPlus) => {
    e.preventDefault();
    const data = DeepCopy(anni);
    let today = new Date();
    if (yearPlus === true) {
      today = new Date(`${today.getFullYear() + 1}-${data.birth.substring(5)}`);
    }
    if (!data.isLunar) {
      // 양력이면
      if (data.birthToSolar) {
        // 양력으로 변경된 생일이 있으면
        if (GetDaysDiff(data.birthToSolar) < 0) {
          // 날짜가 지났으면 내년으로 변경
          data.birthToSolar = `${
            today.getFullYear() + 1
          }-${data.birthToSolar.substring(5)}`;
        }
      } else {
        // 양력으로 변경된 생일이 없으면 오늘 이후 날짜로 birth.month, birth.day 변경
        const dtBirth = new Date(data.birth);
        // MM-DD 형식으로 비교
        // 10보다 작으면 0 붙이기
        const birthMonthDayText = `${
          dtBirth.getMonth() < 10
            ? "0" + parseInt(dtBirth.getMonth() + 1)
            : parseInt(dtBirth.getMonth() + 1)
        }-${
          dtBirth.getDate() < 10 ? "0" + dtBirth.getDate() : dtBirth.getDate()
        }`;
        const todayMonthDayText = `${
          today.getMonth()
            ? "0" + parseInt(today.getMonth() + 1)
            : parseInt(today.getMonth() + 1)
        }-${today.getDate() < 10 ? "0" + today.getDate() : today.getDate()}`;
        if (birthMonthDayText < todayMonthDayText) {
          // 오늘보다 이전이면 내년으로 변경
          data.birthToSolar = `${today.getFullYear() + 1}-${birthMonthDayText}`;
        } else {
          data.birthToSolar = `${today.getFullYear()}-${birthMonthDayText}`;
        }
      }
    } else {
      const birth = new Date(data.birth);
      let month = birth.getMonth() + 1;
      // month 0 으로 채우기
      if (month < 10) {
        month = "0" + month;
      }
      let day = birth.getDate();
      // day 0 으로 채우기
      if (day < 10) {
        day = "0" + day;
      }
      const params = {
        month,
        day,
      };
      await setIsLoading(true);
      const solDate = await axiosHook
        .get(`${apiServer}/getLunarToSolar`, { params })
        .then((res) => {
          let resData = res.data?.response?.body?.items?.item;
          if (resData?.length) resData = resData[0];
          if (!resData?.solYear || !resData?.solMonth || !resData?.solDay) {
            addToast({
              type: "danger",
              text: "api 에 반환값에 문제가 있습니다.\n나중에 다시 시도해주세요.",
            });
            return "error";
          }
          const result = `${resData?.solYear}-${resData?.solMonth}-${resData?.solDay}`;
          if (new Date(result) < today) {
            // 오늘보다 이전이면 내년으로 변경
            updateSolarDate(e, data, true);
          } else {
            return `${resData?.solYear}-${resData?.solMonth}-${resData?.solDay}`;
          }
        })
        .catch((err) => {
          addToast({
            type: "danger",
            text: "api 에 문제가 있습니다.\n나중에 다시 시도해주세요.",
          });
          console.error(err);
          return "error";
        })
        .finally(() => {
          setIsLoading(false);
        });
      data.birthToSolar = solDate;
    }
    if (data.birthToSolar === "error") return;
    // db 에 저장
    setIsLoading(true);
    await axios
      .put(`${apiServer}/anniversary/edit`, data)
      .then((res) => {
        if (res.data?.ok) {
          anni.birthToSolar = data.birthToSolar;
          addToast({
            type: "success",
            text: "수정되었습니다.",
          });
        } else {
          addToast({
            type: "danger",
            text: "수정에 실패했습니다.",
          });
        }
      })
      .catch((e) => {
        addToast({
          type: "danger",
          text: "something went wrong in db",
        });
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // groupType 에 해당하는 생일 및 모임 목록 가져와서 anniversaries 에 할당
    setAnniversaries([]);
    setIsLoading(true);
    axios
      .get(`${apiServer}/anniversaries/${groupType}`)
      .then((res) => {
        if (res.data?.length) {
          setAnniversaries(res.data);
        }
      })
      .catch((e) => {
        setError(e.message);
        addToast({
          type: "danger",
          text: "something went wrong in db",
        });
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiServer, groupType]);

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div>
      <h3 className="text-start">
        <FontAwesomeIcon icon={faCakeCandles} />
        &nbsp; 생일 및 모임
      </h3>
      <p className="text-muted text-start">
        * 잘못된 날짜가 있으면 알려주세요.
      </p>
      <div className="row justify-content-center px-2 table-responsive">
        <table className="table table-striped table-hover">
          <thead className="bg-light">
            <tr>
              <th scope="col">이름</th>
              <th scope="col">일정</th>
              <th scope="col" style={{ minWidth: "80px" }}>
                양력으로
              </th>
              <th scope="col" style={{ minWidth: "70px" }}>
                디데이
              </th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {anniversaries?.map((anni, index) => {
              return (
                <tr key={index} style={{ lineHeight: "200%" }}>
                  <th>{anni.name}</th>
                  <td className={width > 420 ? "" : "small"}>
                    {width > 420
                      ? `${GetDateFormat(anni.birth, 1)} (${anni.isLunar ? "음" : "양"}${
                          anni.birth 
                            ? ", " + GetStandardAge(anni.birth) + ")"
                            : ")"
                        }`
                      : GetDateFormat(anni.birth, 3)}
                  </td>
                  <td>
                    {!anni.birthToSolar ||
                    GetDaysDiff(anni.birthToSolar) < 0 ? (
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={(e) => updateSolarDate(e, anni)}
                      >
                        양력으로
                      </button>
                    ) : (
                      GetDateFormat(anni.birthToSolar, 3)
                    )}
                  </td>
                  <td>
                    {!anni.birthToSolar || GetDaysDiff(anni.birthToSolar) < 0
                      ? ""
                      : GetDaysDiff(anni.birthToSolar)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

export default Anniversary;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userId: null,
  userRoles: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      //localStorage.setItem("isLoggedIn", "yes");
      localStorage.setItem("dnnAt", action?.payload?.accessToken);
      state.userId = action?.payload?.userId;
      state.userRoles = action?.payload?.userRoles;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      // localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("dnnAt");
      state.userId = null;
      state.userRoles = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

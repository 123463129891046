import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/Footer";
import LoadingPopSpinner from "../../components/LoadingPopSpinner";
import MsgBox from "../../components/MsgBox";
import BoardShowComponent from "../../components/board/BoardShowComponent";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { PassDateEasyText } from "../../util/common";
import GotoScrollIcon from '../../components/GotoScrollIcon';

const StoryViewPage = () => {
  const { storyOpenId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const axiosHook = useAxiosPrivate();
  const apiServer = process.env.REACT_APP_API_DOAMIN;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [boardId, setBoardId] = useState("");
  const [storyInfo, setStoryInfo] = useState(null);
  const [boards, setBoards] = useState([]);
  useEffect(() => {
    /**
     * 유효한 storyAuthToken 세션스토리지가 없거나
     * 유효한 storyAuthToken 세션스토리지가 있지만 토큰이 유효하지 않을 경우
     */
    let token = window.sessionStorage.getItem("storyAuthToken");
    if (!token) {
      window.location.href = "/storyAuth/" + storyOpenId;
      return;
    }

    try {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        addToast({
          type: "danger",
          text: "인증이 만료되었습니다.",
        });
      } else {
        console.log("Valid token");
      }
    } catch (err) {
      addToast({
        type: "danger",
        text: err.message || "인증에 실패했습니다.",
      });
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // searchParams가 변경될 때마다 boardId 재할당
  useEffect(() => {
    setBoardId(() => searchParams.get("boardId"));
  }, [searchParams]);

  // storyOpenId 로 스토리 정보 가져오기
  useEffect(() => {
    /**
     * 유효한 storyAuthToken 세션스토리지가 없거나
     * 유효한 storyAuthToken 세션스토리지가 있지만 토큰이 유효하지 않을 경우
     */
    let token = window.sessionStorage.getItem("storyAuthToken");
    if (!token) {
      window.location.href = "/storyAuth/" + storyOpenId;
      return;
    }

    setIsLoading(true);
    axiosHook
      .get(`${apiServer}/storyOpenInfo/${storyOpenId}`)
      .then((res) => {
        setStoryInfo(res.data.story || null);
        setBoards(res.data.boards || []);
        if (!res.data.story)
          setError("스토리가 존재하지 않거나 비공개 상태 입니다.");
      })
      .catch((err) => {
        setError(err.message);
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [axiosHook, apiServer, storyOpenId]);

  const onTilteClick = (board) => {
    navigate(`/story/${storyOpenId}?boardId=${board._id}`);
    /** #root > div.container > div > div.col-12.col-md-8.border.p-3 으로 스크롤 이동 */
    document
      .querySelector(
        "#root > div.container > div > div.col-12.col-md-8.border.p-3"
      )
      .scrollIntoView({ behavior: "smooth" });
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <>
      <div
        className={`container ${
          !boards?.length || boardId === "" ? "d-none" : ""
        }
      `}
      >
        <h1 className="h1 text-center m-4">{storyInfo?.name}</h1>
        <div className="row">
          <div
            className="col-12 col-md-4 border p-3"
            // style={{ height: "75vh", overflowY: "auto" }}
          >
            <table className="table caption-top">
              <caption>목록</caption>
              <thead className="table-light">
                <tr>
                  <th scope="col" className="col-1">
                    #
                  </th>
                  <th scope="col" className="col-auto">
                    제목
                  </th>
                  <th
                    scope="col"
                    className="col-3 text-end"
                    style={{ minWidth: "80px" }}
                  >
                    최종수정
                  </th>
                </tr>
              </thead>
              <tbody>
                {boards?.map((board, index) => (
                  <tr
                    key={index}
                    className={boardId === board._id ? "bg-warning" : ""}
                  >
                    <th scope="row">{++index}</th>
                    <td
                      className={`text-decoration-underline cursor-pointer text-start ${
                        board.isLineThroughTitle
                          ? "text-decoration-line-through"
                          : ""
                      } ${board.isStrongTitle ? "fw-bold" : ""}`}
                      style={{
                        maxWidth: "1px",
                        color: board.titleColor ?? "",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.fontWeight = "bold";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.fontWeight = "";
                      }}
                      onClick={() => onTilteClick(board)}
                      // boardId 와 board._id 가 같으면 active
                      dangerouslySetInnerHTML={{
                        __html: board.title,
                      }}
                    />
                    <td className="text-end small" style={{ maxWidth: "1px" }}>
                      {PassDateEasyText(new Date(board.updatedAt))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className={`col-12 col-md-8 border p-3 ${
              !boards?.length || boardId === "" ? "d-none" : ""
            }`}
            // style={{ height: "75vh", overflowY: "auto" }}
          >
            <BoardShowComponent
              isStoryPage={true}
              firstContent={boards[0]?._id}
              setIsBoardDelete={() => {}}
              setBoardId={setBoardId}
              boardId={boardId}
            />
          </div>
        </div>
      </div>
      <Footer />
      <GotoScrollIcon />
      <LoadingPopSpinner isLoading={isLoading} />
    </>
  );
};

export default StoryViewPage;

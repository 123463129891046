import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useToast from "../../hooks/useToast";
import { GoBoardPage, PassDateEasyText } from "../../util/common";
import Card from "../Card";
import LoadingPopSpinner from "../LoadingPopSpinner";
import MsgBox from "../MsgBox";
import Pagination from "../Pagination";

const BoardList = ({
  boardId,
  setBoardId,
  isManagePage,
  group,
  category,
  getBoards,
  currentPage,
  setCurrentPage,
  limit,
  numberOfBoards,
  boards,
  setBoards,
  searchText,
  setIsBoardDelete,
}) => {
  const axiosHook = useAxiosPrivate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [error, setError] = useState("");
  const { addToast } = useToast();
  const apiServer = process.env.REACT_APP_API_DOAMIN;
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const [noBoardMsg, setNoBoardMsg] = useState("");

  useEffect(() => {
    if (boards === null) {
      setNoBoardMsg("Loading...");
      setBoardId(null);
    } else {
      if (boards?.length) setNoBoardMsg("");
      else setNoBoardMsg("No boards were found.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boards]);

  useEffect(() => {
    setError("");
    // 관리자 페이지인데 로그인이 안되어 있으면 에러
    if (isManagePage && !isLoggedIn) {
      setError("Need to login");
    }
  }, [isLoggedIn, dispatch, isManagePage]);

  useEffect(() => {
    setNumberOfPages(Math.ceil(numberOfBoards / limit));
  }, [numberOfBoards, limit]);

  const onClickPageButton = async (page) => {
    await setBoardId(null);
    await setBoards(null);
    // navigate(`${location.pathname}?page=${page || ""}`);
    await setCurrentPage(page);
    await getBoards(page);
    await GoBoardPage(
      navigate,
      isManagePage,
      group,
      category,
      searchText,
      page
    );
  };

  useEffect(() => {
    setBoards(null);
    setBoardId(null);
    setCurrentPage(parseInt(pageParam) || 1);
    getBoards(parseInt(pageParam) || 1, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, category]);

  const handleDeleteFiles = (delFiles) => {
    if (!delFiles) return; /** 삭제할 파일이 없으면 무시한다. */
    delFiles.forEach((delFile) => {
      if (delFile?.fileFolder && delFile?.fileName) {
        setIsLoading(true);
        axiosHook({
          method: "delete",
          url: `${process.env.REACT_APP_API_DOAMIN}/boardFileDelete`,
          data: { data: `${delFile?.fileFolder}/${delFile?.fileName}` },
        })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const deleteBoard = (e, _id, boardNo) => {
    e.stopPropagation();
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    setIsLoading(true);
    axiosHook
      .delete(`${apiServer}/board/delete`, { data: { _id, boardNo } })
      .then((res) => {
        if (res.data.ok) {
          setBoards(null);
          setBoardId(null);
          // 게시물의 파일 삭제
          handleDeleteFiles(
            res.data.result
          ); /** 파일삭제가 실패해도 무시하고 진행한다. */
          setIsBoardDelete(true);
          getBoards(currentPage, searchText);
          addToast({
            text: "Successfully deleted",
            type: "success",
          });
        } else {
          addToast({
            text: res.data.message || "The board could not be deleted.",
            type: "danger",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast({
          text: e.message || "The board could not be deleted.",
          type: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderBoardList = () => {
    return boards?.map((board) => {
      const dateText = isManagePage
        ? PassDateEasyText(new Date(board?.updatedAt))
        : PassDateEasyText(new Date(board?.publishedAt));
      return (
        <Card
          isShowing={board?._id === boardId}
          key={board?._id}
          title={board.title}
          searchText={searchText}
          isManagedPage={isManagePage}
          isBlogPublish={board.isBlogPublish}
          isStoryPublish={board.isStoryPublish}
          isLineThroughTitle={board.isLineThroughTitle}
          isStrongTitle={board.isStrongTitle}
          titleColor={board.titleColor}
          content={board.content}
          onClick={() => {
            setBoardId(board._id);
            // navigate(
            //   isManagePage
            //     ? `/manage/board?boardId=${board._id}&group=${
            //         group || ""
            //       }&category=${category || ""}

            //       &searchText=${encodeURIComponent(searchText || "")}&page=${
            //         currentPage || 1
            //       }`
            //     : `/board?boardId=${board._id}&group=${group || ""}&category=${
            //         category || ""
            //       }

            //       &searchText=${encodeURIComponent(searchText || "")}&page=${
            //         currentPage || 1
            //       }`
            // );
            GoBoardPage(
              navigate,
              isManagePage,
              group,
              category,
              searchText || "",
              currentPage || 1
            );
          }}
        >
          <div className="ms-2">
            <div
              className="text-muted w-max-content"
              style={{ fontSize: "90%" }}
            >
              {dateText}{" "}
            </div>
            {isManagePage ? (
              <div className="d-flex justify-content-end mt-1">
                <button
                  className="btn btn-success btn-sm me-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `/manage/board/edit/${
                        board?._id
                      }?group=${group}&category=${category}&searchText=${
                        searchText || ""
                      }&page=${currentPage || 1}`
                    );
                  }}
                >
                  수정
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => deleteBoard(e, board?._id, board?.boardNo)}
                >
                  삭제
                </button>
              </div>
            ) : null}
          </div>
        </Card>
      );
    });
  };

  if (error) {
    return <MsgBox msg={error} bg="danger" />;
  }

  return (
    <div className={isLoading ? "d-none" : ""}>
      {!boards?.length ? (
        <div className="p-2">{noBoardMsg}</div>
      ) : (
        <>
          <div className="mt-4">
            {numberOfPages > 1 && (
              <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                onClick={onClickPageButton}
                limit={limit}
              />
            )}
          </div>
          {renderBoardList()}
          <div className="mt-4">
            {numberOfPages > 1 && (
              <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                onClick={onClickPageButton}
                limit={limit}
              />
            )}
          </div>
        </>
      )}
      <LoadingPopSpinner isLoading={isLoading} />
    </div>
  );
};

BoardList.propTypes = {
  isManagePage: propTypes.bool,
};

BoardList.defaultProps = {
  isManagePage: false,
};

export default BoardList;
